import * as React from 'react';
import { IonButton, IonButtons, IonContent, IonIcon, IonInput, IonItem, IonLabel, IonList, IonListHeader, IonModal, IonSelect, IonSelectOption, IonSpinner, IonTextarea, IonTitle, IonToggle, IonToolbar } from '@ionic/react';
import { imageOutline } from 'ionicons/icons';
import { createToast, handleError } from '../helpers';
import { Vehicle, VehicleDetails } from '../models/database/user-created/vehicle';
import { AppPhoto } from '../components/app-photo';
import { alertController } from '@ionic/core';
import { useDevicePlatform } from '../hooks/use-device-platform';

export const AddEditVehicleModal: React.FC<{
    isOpen: boolean,
    existingVehicle?: Vehicle,
    onCanceled: () => any,
    onSaved: () => any
}> = ({ isOpen, existingVehicle, onCanceled, onSaved }) => {
    const devicePlatform = useDevicePlatform();
    const [vehicle, setVehicle] = React.useState<Vehicle | VehicleDetails>();
    const [isUploadingPhotos, setIsUploadingPhotos] = React.useState<boolean>(false);
    const [madeChanges, setMadeChanges] = React.useState<boolean>(false);
    const [years] = React.useState<number[]>(() => {
        console.log('Setting vehicle years')
        let yearsList = [];
        for (var i = 1900; i <= (new Date().getFullYear() + 2); i++) {
            yearsList.unshift(i);
        }
        return yearsList;
    });
    React.useEffect(() => {
        if (!existingVehicle) {
            setVehicle({
                chassisCode: null,
                currentlyOwn: true,
                description: '',
                engineCode: null,
                engineDisplacement: null,
                engineMaker: null,
                engineNumCylinders: null,
                exteriorColorApplicationType: 'Paint',
                exteriorColorBasic: '',
                exteriorColorName: '',
                exteriorColorPaintCode: '',
                forcedInduction: null,
                horsepower: null,
                rpmPeakHorsepower: null,
                interiorColorBasic: '',
                interiorColorName: '',
                make: '',
                model: '',
                numFavorites: 0,
                octane: null,
                photoIds: [],
                rpmPeakTorque: null,
                torque: null,
                transmissionType: null,
                trim: '',
                year: new Date().getFullYear()
            });
        } else {
            // TODO get vehicle
            setVehicle(existingVehicle);
        }
    }, [isOpen]);

    async function save() {
        try {
            if (!vehicle) {
                throw new Error();
            }
            if (existingVehicle && 'id' in vehicle) {
                // await vehiclesService.updateVehicle(vehicle);
                setVehicle(undefined);
            } else {
                // await vehiclesService.addVehicle(vehicle);
                setVehicle(undefined);
            }
            onSaved();
        } catch (error) {
            if (error instanceof Error)
                handleError(error);
        }
    }

    async function cancel() {
        try {
            if (madeChanges) {
                const alert = await alertController.create({
                    message: 'You have unsaved changes, are you sure you want to cancel?', buttons: ['No', {
                        text: 'Yes',
                        handler: () => {
                            setMadeChanges(false);
                            setVehicle(undefined);
                            onCanceled();
                        }
                    }]
                });
                await alert.present();
            } else {
                setVehicle(undefined);
                onCanceled();
            }
        } catch (error) {
            if (error instanceof Error)
                handleError(error);
        }
    }

    if (!vehicle) {
        return null;
    }

    return <IonModal isOpen={isOpen}>
        <IonToolbar color='light'>
            <IonButtons slot='start'>
                <IonButton color='dark' onClick={() => cancel()}>Cancel</IonButton>
            </IonButtons>
            <IonTitle>{existingVehicle ? 'Edit Vehicle' : 'Add Vehicle'}</IonTitle>
            <IonButtons slot='end'>
                <IonButton disabled={isUploadingPhotos} color='primary' onClick={() => save()}>Save</IonButton>
            </IonButtons>
        </IonToolbar>
        <IonContent color='white'>
            <IonList>
                {'id' in vehicle && <div className="ion-padding"> <IonButton className="ion-margin-start ion-margin-end" color="primary" fill="outline" expand="block" routerLink={`/vehicle/${vehicle.id}/mods/add`}>+ Add Mod</IonButton></div>}
                <IonListHeader mode="md" color="dark">Details</IonListHeader>
                <IonItem>
                    <IonLabel>Year*</IonLabel>
                    <IonSelect value={vehicle.year} onIonChange={evt => {
                        setVehicle({ ...vehicle, year: Number(evt.detail.value) || new Date().getFullYear() })
                    }}>
                        {years.map((year) => <IonSelectOption value={year} key={year}>{year}</IonSelectOption>)}
                    </IonSelect>
                </IonItem>

                <IonItem>
                    <IonLabel>Make*</IonLabel>
                    <IonInput
                        type="text"
                        autoCapitalize="words"
                        value={vehicle.make}
                        onIonChange={evt => {
                            setVehicle({
                                ...vehicle,
                                make: evt.detail.value ? evt.detail.value.replace(/\s\s+/g, ' ').replace(/\b\w/g, word => {
                                    return word.charAt(0).toUpperCase() + word.slice(1);
                                }).trim() : ''
                            });
                        }} />
                </IonItem>

                <IonItem>
                    <IonLabel>Model*</IonLabel>
                    <IonInput
                        type="text"
                        value={vehicle.model}
                        onIonChange={evt => {
                            setVehicle({
                                ...vehicle,
                                model: evt.detail.value ? evt.detail.value.replace(/\s\s+/g, ' ').replace(/\b\w/g, word => {
                                    return word.charAt(0).toUpperCase() + word.slice(1);
                                }).trim() : ''
                            });
                        }} />
                </IonItem>

                <IonItem>
                    <IonLabel>Trim</IonLabel>
                    <IonInput
                        type="text"
                        value={vehicle.trim}
                        onIonChange={evt => {
                            setVehicle({
                                ...vehicle,
                                trim: evt.detail.value ? evt.detail.value.replace(/\s\s+/g, ' ').replace(/\b\w/g, word => {
                                    return word.charAt(0).toUpperCase() + word.slice(1);
                                }).trim() : ''
                            });
                        }} />
                </IonItem>

                <IonItem>
                    <IonLabel>Chassis Code</IonLabel>
                    <IonInput
                        type="text"
                        maxlength={15}
                        value={vehicle.chassisCode}
                        onIonChange={evt => {
                            setVehicle({
                                ...vehicle,
                                chassisCode: evt.detail.value ? evt.detail.value.replace(/\s\s+/g, ' ').replace(/\b\w/g, word => {
                                    return word.charAt(0).toUpperCase() + word.slice(1);
                                }).trim() : null
                            });
                        }} />
                </IonItem>

                <IonItem>
                    <IonLabel>Currently Own*</IonLabel>
                    <IonToggle checked={vehicle.currentlyOwn} onIonChange={evt => {
                        setVehicle({ ...vehicle, currentlyOwn: evt.detail.checked });
                    }} />
                </IonItem>

                <IonItem>
                    <IonTextarea value={vehicle.description} placeholder="Description" maxlength={5000} onIonChange={evt => {
                        setVehicle({ ...vehicle, description: evt.detail.value || '' });
                    }} />
                </IonItem>
                <div style={{ textAlign: 'center', fontSize: '0.6em' }}>{vehicle.description.length}/5000</div>

                <IonListHeader mode="md" color="dark">Exterior Color</IonListHeader>

                <IonItem>
                    <IonLabel>Color Name</IonLabel>
                    <IonInput
                        type="text"
                        maxlength={30}
                        value={vehicle.exteriorColorName}
                        onIonChange={evt => {
                            setVehicle({ ...vehicle, exteriorColorName: evt.detail.value || '' });
                        }} />
                </IonItem>
                <div style={{ textAlign: 'center', fontSize: '0.6em' }}>{vehicle.exteriorColorName.length}/30</div>

                <IonItem>
                    <IonLabel>Basic Color</IonLabel>
                    <IonSelect value={vehicle.exteriorColorBasic} slot="end" onIonChange={evt => {
                        setVehicle({ ...vehicle, exteriorColorBasic: evt.detail.value || '' });
                    }}>
                        <IonSelectOption value="White">White</IonSelectOption>
                        <IonSelectOption value="Black">Black</IonSelectOption>
                        <IonSelectOption value="Silver">Silver</IonSelectOption>
                        <IonSelectOption value="Gray">Gray</IonSelectOption>
                        <IonSelectOption value="Blue">Blue</IonSelectOption>
                        <IonSelectOption value="Red">Red</IonSelectOption>
                        <IonSelectOption value="Tan">Tan</IonSelectOption>
                        <IonSelectOption value="Gold">Gold</IonSelectOption>
                        <IonSelectOption value="Yellow">Yellow</IonSelectOption>
                        <IonSelectOption value="Green">Green</IonSelectOption>
                        <IonSelectOption value="Orange">Orange</IonSelectOption>
                        <IonSelectOption value="Purple">Purple</IonSelectOption>
                        <IonSelectOption value="Pink">Pink</IonSelectOption>
                        <IonSelectOption value="Other">Other</IonSelectOption>
                    </IonSelect>
                </IonItem>

                <IonItem>
                    <IonLabel>Paint Code</IonLabel>
                    <IonInput
                        type="text"
                        maxlength={10}
                        value={vehicle.exteriorColorPaintCode}
                        onIonChange={evt => {
                            setVehicle({ ...vehicle, exteriorColorPaintCode: evt.detail.value ? evt.detail.value.trim().toUpperCase() : '' });
                        }} />
                </IonItem>
                <div style={{ textAlign: 'center', fontSize: '0.6em' }}>{vehicle.exteriorColorPaintCode.length}/10</div>

                <IonItem>
                    <IonLabel>Application Type</IonLabel>
                    <IonSelect value={vehicle.exteriorColorApplicationType} onIonChange={evt => {
                        if (evt.detail.value) {
                            setVehicle({ ...vehicle, exteriorColorApplicationType: evt.detail.value || 'Paint' });
                        }
                    }}>
                        <IonSelectOption>Paint</IonSelectOption>
                        <IonSelectOption>Wrap</IonSelectOption>
                        <IonSelectOption>Dip</IonSelectOption>
                        <IonSelectOption>Other</IonSelectOption>
                    </IonSelect>
                </IonItem>

                <IonListHeader mode="md" color="dark">Interior Color</IonListHeader>

                <IonItem>
                    <IonLabel>Color Name</IonLabel>
                    <IonInput
                        type="text"
                        maxlength={25}
                        value={vehicle.interiorColorName}
                        onIonChange={evt => {
                            setVehicle({ ...vehicle, interiorColorName: evt.detail.value || '' });
                        }} />
                </IonItem>
                <div style={{ textAlign: 'center', fontSize: '0.6em' }}>{vehicle.interiorColorName.length}/25</div>

                <IonItem>
                    <IonLabel>Basic Color</IonLabel>
                    <IonSelect value={vehicle.interiorColorBasic} slot="end" onIonChange={evt => {
                        setVehicle({ ...vehicle, interiorColorBasic: evt.detail.value || '' });
                    }}>
                        <IonSelectOption value="White">White</IonSelectOption>
                        <IonSelectOption value="Cream">Cream</IonSelectOption>
                        <IonSelectOption value="Black">Black</IonSelectOption>
                        <IonSelectOption value="Gray">Gray</IonSelectOption>
                        <IonSelectOption value="Tan">Tan</IonSelectOption>
                        <IonSelectOption value="Blue">Blue</IonSelectOption>
                        <IonSelectOption value="Red">Red</IonSelectOption>
                        <IonSelectOption value="Yellow">Yellow</IonSelectOption>
                        <IonSelectOption value="Green">Green</IonSelectOption>
                        <IonSelectOption value="Orange">Orange</IonSelectOption>
                        <IonSelectOption value="Purple">Purple</IonSelectOption>
                        <IonSelectOption value="Pink">Pink</IonSelectOption>
                    </IonSelect>
                </IonItem>

                <IonListHeader mode="md" color="dark">Engine and Transmission</IonListHeader>

                <IonItem>
                    <IonLabel>Transmission Type</IonLabel>
                    <IonSelect value={vehicle.transmissionType} slot="end" onIonChange={evt => {
                        setVehicle({ ...vehicle, transmissionType: evt.detail.value || null });
                    }}>
                        <IonSelectOption value={null}></IonSelectOption>
                        <IonSelectOption value="Manual">Manual</IonSelectOption>
                        <IonSelectOption value="Automatic">Automatic</IonSelectOption>
                        <IonSelectOption value="Sequential">Sequential</IonSelectOption>
                        <IonSelectOption value="Other">Other</IonSelectOption>
                    </IonSelect>
                </IonItem>

                <IonItem>
                    <IonLabel>Engine # Cylinders</IonLabel>
                    <IonSelect value={vehicle.engineNumCylinders} slot="end" onIonChange={evt => {
                        setVehicle({ ...vehicle, engineNumCylinders: Number(evt.detail.value) as VehicleDetails['engineNumCylinders'] || null });
                    }}>
                        <IonSelectOption value={null}></IonSelectOption>
                        <IonSelectOption value={0}>0</IonSelectOption>
                        <IonSelectOption value={1}>1</IonSelectOption>
                        <IonSelectOption value={2}>2</IonSelectOption>
                        <IonSelectOption value={3}>3</IonSelectOption>
                        <IonSelectOption value={4}>4</IonSelectOption>
                        <IonSelectOption value={5}>5</IonSelectOption>
                        <IonSelectOption value={6}>6</IonSelectOption>
                        <IonSelectOption value={8}>8</IonSelectOption>
                        <IonSelectOption value={10}>10</IonSelectOption>
                        <IonSelectOption value={12}>12</IonSelectOption>
                        <IonSelectOption value={16}>16</IonSelectOption>
                    </IonSelect>
                </IonItem>

                <IonItem>
                    <IonLabel>Engine Displacement (L)</IonLabel>
                    <IonInput
                        type="text"
                        placeholder="ex. 2.2, 3.5, or 5.0"
                        maxlength={5}
                        value={vehicle.engineDisplacement}
                        onIonChange={evt => {
                            setVehicle({ ...vehicle, engineDisplacement: Number(evt.detail.value) || null });
                        }} />
                </IonItem>

                <IonItem>
                    <IonLabel>Forced Induction Type</IonLabel>
                    <IonSelect value={vehicle.forcedInduction} onIonChange={evt => {
                        setVehicle({ ...vehicle, forcedInduction: evt.detail.value || null });
                    }}>
                        <IonSelectOption value={null}></IonSelectOption>
                        <IonSelectOption>Turbocharged</IonSelectOption>
                        <IonSelectOption>Supercharged</IonSelectOption>
                        <IonSelectOption>Twin-charged</IonSelectOption>
                        <IonSelectOption>Other</IonSelectOption>
                    </IonSelect>
                </IonItem>

                <IonItem>
                    <IonLabel>Engine Maker</IonLabel>
                    <IonInput
                        type="text"
                        placeholder="ex. Honda, Spoon, etc."
                        maxlength={15}
                        value={vehicle.engineMaker}
                        onIonChange={evt => {
                            setVehicle({ ...vehicle, engineMaker: evt.detail.value || null });
                        }} />
                </IonItem>

                <IonItem>
                    <IonLabel>Engine Code</IonLabel>
                    <IonInput
                        type="text"
                        placeholder="ex. F20C"
                        maxlength={15}
                        value={vehicle.engineCode}
                        onIonChange={evt => {
                            setVehicle({ ...vehicle, engineCode: evt.detail.value ? evt.detail.value.trim().toUpperCase() : null });
                        }} />
                </IonItem>

                <IonItem>
                    <IonLabel>Horsepower</IonLabel>
                    <IonInput
                        type={(devicePlatform === 'android' || devicePlatform === 'ios') ? 'tel' : 'number'}
                        maxlength={5}
                        placeholder="ex. 420"
                        value={vehicle.horsepower}
                        onIonChange={evt => {
                            setVehicle({ ...vehicle, horsepower: Number(evt.detail.value) || null });
                        }} />
                </IonItem>

                <IonItem>
                    <IonLabel>Peak Horsepower RPM</IonLabel>
                    <IonInput
                        type={(devicePlatform === 'android' || devicePlatform === 'ios') ? 'tel' : 'number'}
                        placeholder="ex. 7400"
                        maxlength={5}
                        value={vehicle.rpmPeakHorsepower}
                        onIonChange={evt => {
                            setVehicle({ ...vehicle, rpmPeakHorsepower: Number(evt.detail.value) || null });
                        }} />
                </IonItem>

                <IonItem>
                    <IonLabel>Torque</IonLabel>
                    <IonInput
                        type={(devicePlatform === 'android' || devicePlatform === 'ios') ? 'tel' : 'number'}
                        maxlength={5}
                        placeholder="ex. 320"
                        value={vehicle.torque}
                        onIonChange={evt => {
                            setVehicle({ ...vehicle, torque: Number(evt.detail.value) || null });
                        }} />
                </IonItem>

                <IonItem>
                    <IonLabel>Peak Torque RPM</IonLabel>
                    <IonInput
                        type={(devicePlatform === 'android' || devicePlatform === 'ios') ? 'tel' : 'number'}
                        placeholder="ex. 2200"
                        maxlength={5}
                        value={vehicle.rpmPeakTorque}
                        onIonChange={evt => {
                            setVehicle({ ...vehicle, rpmPeakTorque: Number(evt.detail.value) || null });
                        }} />
                </IonItem>

                <IonItem>
                    <IonLabel>Octane</IonLabel>
                    <IonInput
                        type={(devicePlatform === 'android' || devicePlatform === 'ios') ? 'tel' : 'number'}
                        maxlength={5}
                        placeholder="ex. 91"
                        value={vehicle.octane}
                        onIonChange={evt => {
                            setVehicle({ ...vehicle, octane: evt.detail.value || null });
                        }} />
                </IonItem>

                <IonListHeader mode="md" color="dark">Photos</IonListHeader>
                {/* <div className="ion-padding"> */}
                {/* <IonButton fill="outline" expand="block" className="ion-margin-start ion-margin-end" onClick={async () => {
            try {
                // const newPhoto = await photosService.choosePhoto({ appState.currentUser: appState.appState.currentUser });
                // setPhotos([...photos, newPhoto]);
            } catch (error) {
                console.log(error);
                createToast('There was an error adding a photo.');
            }
        }}>
            <IonIcon slot="start" icon={imageOutline}></IonIcon> Add Photo
    </IonButton>
    </div>
    <EditablePhotosGrid photos={photos} onRemovingPhoto={id => {
        setPhotos(photos.filter(p => p.id !== id));
    }} /> */}

                <div className="ion-padding">
                    <IonButton
                        expand="block"
                        className="ion-margin-start ion-margin-end"
                        color="primary"
                        fill="outline"
                        onClick={async () => {
                            try {
                                //   const newPhoto = await choosePhoto({ authenticatedUser: appState.global.appState.currentUser });
                                //   store.setPost({ ...store.post, photos: [...store.post.photos, newPhoto] });
                                //    photosService.choosePhotos();
                                //const newPhoto = await photosService.choosePhoto({ appState.currentUser: appState.appState.currentUser });
                                //setPhotos([...photos, newPhoto]);
                                // const files = await photosService.choosePhotos();
                                // state.togglePhotosLoading();
                                // setVehicle({ photos: [...vehicle.photos, ...await photosService.uploadAndReturnPhotos({ files })] });
                                // state.togglePhotosLoading();
                                // needsUserConfirmation = (true);
                            } catch (error) {
                                console.log(error);
                                createToast('There was an error adding a photo.');
                            }
                        }}
                    >
                        <IonIcon slot="start" icon={imageOutline} /> Add Photos
                    </IonButton>

                    {/* <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', overflow: 'scroll' }}>
                        {vehicle.photos.map((photo) => <div key={photo.id} style={{ margin: '0 6px' }}>
                            <AppPhoto maxWidth={120} photo={photo} isRemovable={true} onRemoveClicked={() => {

                            }} />
                        </div>)}
                    </div> */}
                </div>

            </IonList>
        </IonContent>
    </IonModal>
}