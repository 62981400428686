import * as React from 'react';
import { AuthContext } from '../models/app/auth-context';

export function useAuth() {
    const [authContext, setAuthContext] = React.useState<AuthContext | null>(null);
    React.useEffect(() => {

    }, []);
    return {
        context: authContext,
        set: React.useCallback((context: AuthContext | null) => {
            setAuthContext(context);
        }, [])
    };
}