import * as React from 'react';
import { IonIcon } from '@ionic/react';
import { checkmarkOutline, closeOutline } from 'ionicons/icons';

export const MembershipComparison: React.FC = () => {
    return (
        <div>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', backgroundColor: 'var(--ion-color-light)' }}>
                <div style={{ width: '66%' }}></div>
                <div className="ion-text-center" style={{ fontSize: '100%', width: '16%' }}>Basic</div>
                <div className="ion-text-center" style={{ fontSize: '100%', width: '16%' }}>Pro</div>
                {/* <IonCol size="2" className="ion-text-center" style={{ fontSize: '100%' }}>Vendor</IonCol> */}
            </div>
            <div style={{ display: 'flex', marginBottom: '5px', backgroundColor: 'var(--ion-color-light)' }}>
                <div style={{ width: '66%' }}></div>
                <div className="ion-text-center" style={{ fontSize: '11px', width: '16%' }}>Free</div>
                <div className="ion-text-center" style={{ fontSize: '11px', width: '16%' }}>$4.99/mo.</div>
                {/* <IonCol size="2" className="ion-text-center" style={{ fontSize: '100%' }}>Vendor</IonCol> */}
            </div>
            <div className="ion-padding-start">
                <div style={{ display: 'flex', marginBottom: '5px' }}>
                    <div style={{ width: '66%' }}>Add Posts</div>
                    <div className="ion-text-center" style={{ fontSize: '100%', width: '16%' }}><IonIcon style={{ fontWeight: 'bold' }} color="success" icon={checkmarkOutline}></IonIcon></div>
                    <div className="ion-text-center" style={{ fontSize: '100%', width: '16%' }}><IonIcon style={{ fontWeight: 'bold' }} color="success" icon={checkmarkOutline}></IonIcon></div>
                    {/* <IonCol size="2" className="ion-text-center" style={{ fontSize: '100%' }}>Vendor</IonCol> */}
                </div>
                <div style={{ display: 'flex', marginBottom: '5px' }}>
                    <div style={{ width: '66%' }}>Comment On Posts</div>
                    <div className="ion-text-center" style={{ fontSize: '100%', width: '16%' }}><IonIcon style={{ fontWeight: 'bold' }} color="success" icon={checkmarkOutline}></IonIcon></div>
                    <div className="ion-text-center" style={{ fontSize: '100%', width: '16%' }}><IonIcon style={{ fontWeight: 'bold' }} color="success" icon={checkmarkOutline}></IonIcon></div>
                    {/* <IonCol size="2" className="ion-text-center" style={{ fontSize: '100%' }}>Vendor</IonCol> */}
                </div>
                <div style={{ display: 'flex', marginBottom: '5px' }}>
                    <div style={{ width: '66%' }}>Add Vehicles and Mods</div>
                    <div className="ion-text-center" style={{ fontSize: '100%', width: '16%' }}><IonIcon style={{ fontWeight: 'bold' }} color="success" icon={checkmarkOutline}></IonIcon></div>
                    <div className="ion-text-center" style={{ fontSize: '100%', width: '16%' }}><IonIcon style={{ fontWeight: 'bold' }} color="success" icon={checkmarkOutline}></IonIcon></div>
                    {/* <IonCol size="2" className="ion-text-center" style={{ fontSize: '100%' }}>Vendor</IonCol> */}
                </div>
                <div style={{ display: 'flex', marginBottom: '5px' }}>
                    <div style={{ width: '66%' }}>Save Favorites</div>
                    <div className="ion-text-center" style={{ fontSize: '100%', width: '16%' }}><IonIcon style={{ fontWeight: 'bold' }} color="success" icon={checkmarkOutline}></IonIcon></div>
                    <div className="ion-text-center" style={{ fontSize: '100%', width: '16%' }}><IonIcon style={{ fontWeight: 'bold' }} color="success" icon={checkmarkOutline}></IonIcon></div>
                    {/* <IonCol size="2" className="ion-text-center" style={{ fontSize: '100%' }}>Vendor</IonCol> */}
                </div>
                <div style={{ display: 'flex', marginBottom: '5px' }}>
                    <div style={{ width: '66%' }}>Add Items/Vehicles For Sale</div>
                    <div className="ion-text-center" style={{ fontSize: '100%', width: '16%' }}><IonIcon style={{ fontWeight: 'bold' }} color="danger" icon={closeOutline}></IonIcon></div>
                    <div className="ion-text-center" style={{ fontSize: '100%', width: '16%' }}><IonIcon style={{ fontWeight: 'bold' }} color="success" icon={checkmarkOutline}></IonIcon></div>
                    {/* <IonCol size="2" className="ion-text-center" style={{ fontSize: '100%' }}>Vendor</IonCol> */}
                </div>
                <div style={{ display: 'flex', marginBottom: '5px' }}>
                    <div style={{ width: '66%' }}>Show Higher In Search Results</div>
                    <div className="ion-text-center" style={{ fontSize: '100%', width: '16%' }}><IonIcon style={{ fontWeight: 'bold' }} color="danger" icon={closeOutline}></IonIcon></div>
                    <div className="ion-text-center" style={{ fontSize: '100%', width: '16%' }}><IonIcon style={{ fontWeight: 'bold' }} color="success" icon={checkmarkOutline}></IonIcon></div>
                    {/* <IonCol size="2" className="ion-text-center" style={{ fontSize: '100%' }}>Vendor</IonCol> */}
                </div>
                <div style={{ display: 'flex', marginBottom: '5px' }}>
                    <div style={{ width: '66%' }}>Highlighted Posts</div>
                    <div className="ion-text-center" style={{ fontSize: '100%', width: '16%' }}><IonIcon style={{ fontWeight: 'bold' }} color="danger" icon={closeOutline}></IonIcon></div>
                    <div className="ion-text-center" style={{ fontSize: '100%', width: '16%' }}><IonIcon style={{ fontWeight: 'bold' }} color="success" icon={checkmarkOutline}></IonIcon></div>
                    {/* <IonCol size="2" className="ion-text-center" style={{ fontSize: '100%' }}>Vendor</IonCol> */}
                </div>
                <div style={{ display: 'flex', marginBottom: '5px' }}>
                    <div style={{ width: '66%' }}>10 GB Photos</div>
                    <div className="ion-text-center" style={{ fontSize: '100%', width: '16%' }}><IonIcon style={{ fontWeight: 'bold' }} color="success" icon={checkmarkOutline}></IonIcon></div>
                    <div className="ion-text-center" style={{ fontSize: '100%', width: '16%' }}><IonIcon style={{ fontWeight: 'bold' }} color="danger" icon={closeOutline}></IonIcon></div>
                    {/* <IonCol size="2" className="ion-text-center" style={{ fontSize: '100%' }}>Vendor</IonCol> */}
                </div>
                <div style={{ display: 'flex', marginBottom: '5px' }}>
                    <div style={{ width: '66%' }}>200 GB Photos</div>
                    <div className="ion-text-center" style={{ fontSize: '100%', width: '16%' }}><IonIcon style={{ fontWeight: 'bold' }} color="danger" icon={closeOutline}></IonIcon></div>
                    <div className="ion-text-center" style={{ fontSize: '100%', width: '16%' }}><IonIcon style={{ fontWeight: 'bold' }} color="success" icon={checkmarkOutline}></IonIcon></div>
                    {/* <IonCol size="2" className="ion-text-center" style={{ fontSize: '100%' }}>Vendor</IonCol> */}
                </div>
                {/* <IonRow>
                    <IonCol size="8">Let Users Shop Your Website Right In The App</IonCol>
                    <IonCol size="2" className="ion-text-center">
                        <IonIcon style={{ fontWeight: 'bold' }} color="danger" icon={closeOutline}></IonIcon>
                    </IonCol>
                    <IonCol size="2" className="ion-text-center">
                        <IonIcon style={{ fontWeight: 'bold' }} color="danger" icon={closeOutline}></IonIcon>
                    </IonCol>
                    <IonCol size="2" className="ion-text-center">
                        <IonIcon style={{ fontWeight: 'bold' }} color="success" icon={checkmarkOutline}></IonIcon>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="8">Get Notified When Users Are Interested In Buying Parts</IonCol>
                    <IonCol size="2" className="ion-text-center">
                        <IonIcon style={{ fontWeight: 'bold' }} color="danger" icon={closeOutline}></IonIcon>
                    </IonCol>
                    <IonCol size="2" className="ion-text-center">
                        <IonIcon style={{ fontWeight: 'bold' }} color="danger" icon={closeOutline}></IonIcon>
                    </IonCol>
                    <IonCol size="2" className="ion-text-center">
                        <IonIcon style={{ fontWeight: 'bold' }} color="success" icon={checkmarkOutline}></IonIcon>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="8">Let Users Know You Sell Certain Mods</IonCol>
                    <IonCol size="2" className="ion-text-center">
                        <IonIcon style={{ fontWeight: 'bold' }} color="danger" icon={closeOutline}></IonIcon>
                    </IonCol>
                    <IonCol size="2" className="ion-text-center">
                        <IonIcon style={{ fontWeight: 'bold' }} color="danger" icon={closeOutline}></IonIcon>
                    </IonCol>
                    <IonCol size="2" className="ion-text-center">
                        <IonIcon style={{ fontWeight: 'bold' }} color="success" icon={checkmarkOutline}></IonIcon>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="8">Link Directly To Your Website From A Mod</IonCol>
                    <IonCol size="2" className="ion-text-center">
                        <IonIcon style={{ fontWeight: 'bold' }} color="danger" icon={closeOutline}></IonIcon>
                    </IonCol>
                    <IonCol size="2" className="ion-text-center">
                        <IonIcon style={{ fontWeight: 'bold' }} color="danger" icon={closeOutline}></IonIcon>
                    </IonCol>
                    <IonCol size="2" className="ion-text-center">
                        <IonIcon style={{ fontWeight: 'bold' }} color="success" icon={checkmarkOutline}></IonIcon>
                    </IonCol>
                </IonRow> */}
            </div>
            <div className="ion-padding-start ion-padding-end ion-text-center" style={{ fontSize: '70%' }}>
                <div>* Basic members can post photos of their vehicle, track days, cruises, etc.  For sale posts are not allowed.</div>
            </div>
        </div>
    );
};
