import * as React from 'react';
import { IonPage, IonButtons, IonBackButton, IonHeader, IonContent, IonTitle, useIonViewWillEnter, IonPopover, IonList, IonItem } from '@ionic/react';
import { AppPhoto } from '../components/app-photo';
import { appConfig } from '../constants';
import { inAppBrowserService } from '../services/in-app-browser-service';
import { useParams } from 'react-router';
import { Photo } from '../models/database/supporting/photo';

export const ViewPhotoPage: React.FC = () => {
    const { photoId } = useParams<{ photoId: string }>();
    const [photo, setPhoto] = React.useState<Photo>();

    useIonViewWillEnter(() => {

    });

    function viewFullSize() {
        inAppBrowserService.open(`${appConfig.storageBaseUrl}/photos/${photoId}-original`);
    }

    return <IonPage>
        <IonHeader>
            <IonButtons slot='start'>
                <IonBackButton />
            </IonButtons>
            <IonTitle>View Photo</IonTitle>
        </IonHeader>
        <IonContent>
            {photo && <AppPhoto photo={photo} /> }
            <IonPopover isOpen={true}>
                <IonList>
                    <IonItem onClick={() => viewFullSize()}>View full size</IonItem>
                </IonList>
            </IonPopover>
        </IonContent>
    </IonPage>
}