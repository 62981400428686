

export class ValidationException extends Error {
    message = 'There were validation errors';
    errors: string[] = [];
    constructor(errors: string[]) {
        super();
        if (errors) {
            this.errors = errors;
        }
    }
}

export class AuthenticationException extends Error {
    message = 'There was an error authenticating.';
}

export class AuthorizationException extends Error {
    message = 'You do not have authorization to perform this action.';
}

export class UnexpectedException extends Error {
    message = 'There was an unexpected error.';
}

export class EntityNotFoundException extends Error {
    message = 'A record could not be located.';
}

export class NetworkException extends Error {
    message = 'You need a network connection to perform this action, or need to enable offline use in your settings.';
    constructor(doesntWorkOfflineEnabled?: boolean) {
        super();
        if (doesntWorkOfflineEnabled === true) {
            this.message = 'You need a network connection to perform this action.';
        }
    }
}

export class PhotoUploadException extends Error {
    message = 'There was an error uploading your photo(s).  Please try again.';
}