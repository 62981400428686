import * as React from 'react';
import { IonPage, IonContent, IonToolbar, IonTitle, IonButtons, IonBackButton, IonHeader, useIonViewWillEnter } from '@ionic/react';
import { AppHeader } from '../components/app-header';

export const TermsOfUsePage: React.FC = () => {

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="light">
          <IonButtons slot="start">
            <IonBackButton color="primary" defaultHref="/more" />
          </IonButtons>
          <IonTitle>Terms Of Use</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <p>
          <em> Your usage of this app is voluntary and is subject to the following terms.  Any violation of these terms may lead to termination of your user account, and may be punishable to the fullest extent allowed by law.</em>
        </p>
        <br />
        <strong> Member:</strong> One who has joined and created an account, whether Basic, Pro, or Vendor.
                      <br />
        <strong>Non-member:</strong> One who has not joined and created an account, but has downloaded the app or visited the website.  They can <strong>only view</strong> the app.
                      <ul>
          <li>Members may not post offensive things.  This is subject to our discretion but includes gory content, nudity, racial slurs, or extreme foul language.</li>
          <li>Members may not harrass other members.  This includes excessive comments on member's posts, speaking in an offensive manner, or creating posts that defame another user unnecessarily.</li>
          <li>Non-members are subject to appropriate usage of the app and may be blocked from further use until joining as a member by creating an account.</li>
          <li>Members may only list items for sale in the For Sale area with a paid membership.  Posts in the Dashboard area are not allowed to contain items for sale, and will be promptly removed.</li>
          <li>Non-members will not be permitted to see items in the For Sale area without creating an account. </li>
          <li>Members who list items for sale in the For Sale area agree that the sales transaction takes place outside of the app, and that Mod Club and its owners will not be liable for any failed transactions. </li>
        </ul>
      </IonContent>
    </IonPage>
  );

};