import * as React from 'react';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonSegment, IonSegmentButton, IonTitle, IonToolbar, useIonViewDidEnter, useIonViewDidLeave } from '@ionic/react';
import { Redirect, useParams } from 'react-router';
import { UserCard } from '../components/user-card';
import { VehiclesList } from '../components/vehicles-list';
import { PostsList } from '../components/posts-list';
import { Vehicle } from '../models/database/user-created/vehicle';
import { useAuth } from '../hooks/use-auth';

export const ProfilePage: React.FC = () => {
  const auth = useAuth();
  const { username } = useParams<{ username: string }>();
  const [state, setState] = React.useState<{
    visibleSection: 'garage' | 'posts',
    setVisibleSection(section: 'garage' | 'posts'): void;
  }>(() => {
    return {
      visibleSection: 'garage',
      setVisibleSection(section) {
        this.visibleSection = section;
      }
    }
  })

  useIonViewDidEnter(() => {
    // TODO load profile
  }, []);

  useIonViewDidLeave(() => {
    //profilePageStore.clear();
  }, []);

  function getUsersDetails() {
    // if (appState.appState.currentUser && appState.appState.currentUser.username === username) {
    //   return appState.appState.currentUser;
    // }
    // if (profilePageStore.profile) {
    //   return profilePageStore.profile;
    // }
    return undefined;
  }


  function getUsersVehicles(): Vehicle[] {
    return [];
  }

  function getUsersPosts() {
    // if (appState.appState.currentUser && appState.appState.currentUser.username === username) {
    //   return Object.values(appState.appState.currentUser.posts);
    // }
    // if (profilePageStore.profile) {
    //   return profilePageStore.profile.posts;
    // }
    return undefined;
  }

  if (!auth.context) {
    return <Redirect to="/dashboard" />
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="light">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/more" />
          </IonButtons>
          <IonTitle>{username}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color="ion-content-bg">
        {/* <IonLoading isOpen={isLoading} message="Loading profile..." /> */}

        {/* <UserCard authenticatedUser={authenticatedUser}  user={getUsersDetails()} /> */}
        <IonToolbar color="white">
          <IonSegment color="primary" value={state.visibleSection}>
            <IonSegmentButton value='garage' onClick={() => state.setVisibleSection('garage')}>Garage</IonSegmentButton>
            <IonSegmentButton value='posts' onClick={() => state.setVisibleSection('posts')}>Posts</IonSegmentButton>
          </IonSegment>
        </IonToolbar>
        <span hidden={state.visibleSection !== 'garage'}>
          {/* <VehiclesList authenticatedUser={authenticatedUser}  view='list-item' vehicles={getUsersVehicles()} /> */}
        </span>

        <span hidden={state.visibleSection !== 'posts'}>
          {/* <PostsList authenticatedUser={authenticatedUser}  posts={getUsersPosts()} /> */}
        </span>

      </IonContent>
    </IonPage>
  );

};
