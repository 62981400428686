import {
    IonBadge,
    IonButton,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
} from '@ionic/react';

import { useLocation } from 'react-router-dom';
import { archiveOutline, archiveSharp, bookmarkOutline, cashOutline, cashSharp, eyeOutline, heartOutline, heartSharp, homeOutline, homeSharp, logInOutline, logOutOutline, mailOutline, mailSharp, newspaperOutline, notificationsOutline, paperPlaneOutline, paperPlaneSharp, peopleOutline, personOutline, settingsOutline, starOutline, thumbsUpOutline, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';
import './side-menu.css';
import { useDevicePlatform } from '../hooks/use-device-platform';
import { useEffect, useState } from 'react';
import { AuthContext } from '../models/app/auth-context';

export const SideMenu: React.FC<{ auth: Readonly<AuthContext | null> }> = ({ auth }) => {
    const location = useLocation();
    const [isLargeViewport, setIsLargeViewport] = useState(window.innerWidth > 768 ? true : false);
    useEffect(() => {
        const resizeListener: (this: Window, ev: UIEvent) => any = () => {
            if (window.innerWidth > 768) {
                // setSideMenuIsVisible(true);
                setIsLargeViewport(true);
            } else {
                // setSideMenuIsVisible(false);
                setIsLargeViewport(false);
            }
        }
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        };
    }, []);
    return (
        <IonMenu swipeGesture={false} contentId="main" type="overlay">
            <IonContent>
                <IonList id="inbox-list" lines='full'>
                    {isLargeViewport && <IonMenuToggle autoHide={false}>
                        <IonItem className={location.pathname === '/dashboard' ? 'selected' : ''} routerLink='/dashboard' routerDirection="none" lines="full" detail={false}>
                            <IonIcon style={{ fontSize: '24px' }} src={location.pathname === '/dashboard' ? '/assets/img/dashboard-color.svg' : '/assets/img/dashboard-bw.svg'} />
                            <IonLabel>Dashboard</IonLabel>
                        </IonItem>
                    </IonMenuToggle>}

                    {isLargeViewport && <IonMenuToggle autoHide={false}>
                        <IonItem className={location.pathname === '/garage' ? 'selected' : ''} routerLink='/garage' routerDirection="none" lines='full' detail={false}>
                            <IonIcon style={{ fontSize: '24px' }} src={location.pathname === '/garage' ? '/assets/img/garage-color.svg' : '/assets/img/garage-bw.svg'} />
                            <IonLabel>Garage</IonLabel>
                        </IonItem>
                    </IonMenuToggle>}



                    <IonListHeader color="header-separator" mode="md">You</IonListHeader>
                    {(!auth || !auth.user) && <>
                        <IonMenuToggle autoHide={false}>
                            <IonItem button={true} detail={true} routerLink="/log-in">
                                <IonIcon color="primary" slot="start" icon={logInOutline}></IonIcon>
                                <IonLabel>Log In</IonLabel>
                            </IonItem>
                        </IonMenuToggle>
                    </>}
                    {auth && auth.user &&
                        <>
                            <IonItem button={true} detail={true} routerLink='/notifications'>
                                <IonIcon color="primary" slot="start" icon={notificationsOutline} />
                                <IonLabel>Notifications</IonLabel>
                                <IonBadge slot="end">1K+</IonBadge>
                            </IonItem>

                            <IonItem button={true} detail={true} routerLink={`/profile/${auth.user.username}`}>
                                <IonIcon color="primary" slot="start" icon={personOutline} />
                                <IonLabel>Profile</IonLabel>
                            </IonItem>

                            <IonItem button={true} detail={true} routerLink='/user-favorites'>
                                <IonIcon color="primary" slot="start" icon={heartOutline} />
                                <IonLabel>Favorites</IonLabel>
                            </IonItem>


                            <IonItem button={true} detail={true} routerLink="/settings">
                                <IonIcon color="primary" slot="start" icon={settingsOutline}></IonIcon>
                                <IonLabel>Settings</IonLabel>
                            </IonItem>

                            <IonItem button={true} detail={true} onClick={() => {

                            }}>
                                <IonIcon color="primary" slot="start" icon={logOutOutline}></IonIcon>
                                <IonLabel>Log Out</IonLabel>
                            </IonItem>


                            <IonItem button={true} detail={true}>
                                <IonIcon color="primary" slot="start" icon={trashOutline}></IonIcon>
                                <IonLabel color="danger">Delete Account</IonLabel>
                            </IonItem>
                        </>
                    }

                    <IonListHeader color="header-separator" mode="md">Feedback and Ratings</IonListHeader>
                    <IonItem button={true} detail={true}>
                        <IonIcon color="primary" icon={peopleOutline}></IonIcon>
                        <IonLabel>Share App With Your Friends</IonLabel>
                    </IonItem>
                    <IonItem button={true} detail={true}>
                        <IonIcon color="primary" icon={starOutline}></IonIcon>
                        <IonLabel>Rate App On Store</IonLabel>
                    </IonItem>

                    <IonItem button={true} detail={false} routerLink="/add-suggestion">
                        <IonIcon color="primary" icon={thumbsUpOutline}></IonIcon>
                        <IonLabel>Suggest Improvements or Features</IonLabel>
                    </IonItem>

                    <IonListHeader color="header-separator" className="ion-margin-top" mode="md">Advertising</IonListHeader>

                    <IonItem>
                        {/* <IonLabel className="ion-text-wrap">The app is currently membership-based and ad-free. If user memberships fail to provide necessary revenue, this could change in the future and will be highlighted here.</IonLabel> */}
                        <IonLabel className="ion-text-wrap">If you are interested in advertising, please email: <a href="mailto:kevin.clark.adstech@gmail.com">kevin.clark.adstech@gmail.com</a>.</IonLabel>
                    </IonItem>

                    <IonListHeader color="header-separator" className="ion-margin-top" mode="md">Legalese</IonListHeader>

                    <IonMenuToggle autoHide={false}>
                        <IonItem button={true} detail={true} routerLink="/terms-of-use">
                            <IonIcon color="primary" icon={newspaperOutline}></IonIcon>
                            <IonLabel >Terms Of Use</IonLabel>
                        </IonItem>
                    </IonMenuToggle>

                    <IonMenuToggle autoHide={false}>
                        <IonItem lines='none' button={true} detail={true} routerLink="/privacy-policy">
                            <IonIcon color="primary" icon={eyeOutline}></IonIcon>
                            <IonLabel>Privacy Policy</IonLabel>
                        </IonItem>
                    </IonMenuToggle>

                    {/* <IonList id="labels-list">
                    <IonListHeader>Labels</IonListHeader>
                    {labels.map((label, index) => (
                        <IonItem lines="none" key={index}>
                            <IonIcon slot="start" icon={bookmarkOutline} />
                            <IonLabel>{label}</IonLabel>
                        </IonItem>
                    ))}
                </IonList> */}

                </IonList>
            </IonContent>
        </IonMenu>
    );
}