import * as React from 'react';
import { IonHeader, IonToolbar, IonImg, IonTitle, IonButtons, IonButton, IonIcon, NavContext } from '@ionic/react';
import { searchOutline, personOutline, notificationsOutline } from 'ionicons/icons';
import './app-header.css';
import { User } from '../models/database/system-created/user';

interface AppHeaderProps {
  hideActionButtons?: boolean;
  currentUser: User | undefined;
}

export const AppHeader: React.FC<AppHeaderProps> = ({ hideActionButtons, currentUser }) => {
  // const navContext = React.useContext(NavContext);
  // function handleNotificationsClicked() {
  //   navContext.navigate('/notifications');
  // }
  return (
    <IonHeader color="primary">
      <IonToolbar mode="md" color="primary">
        <IonImg className="logo-image" slot="start" src="/assets/img/silverwheeltires.png" style={{ height: "75px", margin: '0 10px', border: '0px solid blue' }} />
        <IonTitle style={{ fontSize: '1.0em', fontFamily: 'Orbitron', paddingLeft: '0px' }}><span style={{ color: '#ff002f' }}>Mod</span>Club</IonTitle>
        {hideActionButtons !== true &&
          <IonButtons slot="end">
            <IonButton slot="icon-only" fill="clear" routerDirection='forward' routerLink={currentUser ? '/search' : '/log-in'}>
              <IonIcon slot="icon-only" icon={searchOutline} />
            </IonButton>
            <IonButton fill="clear" routerLink={currentUser ? `/profile/${currentUser.username}` : '/log-in'}>
              {/* <UserAvatar user={{ ...currentUserStore.currentUser }} /> */}
              <IonIcon icon={personOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
        }
      </IonToolbar>
    </IonHeader>
  );
};
