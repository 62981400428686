import localForage from 'localforage';

export class ClientStorageService {

    public async storeItem<T>({ name = 'modclub', storeName, key, value }: { name?: string, storeName: string, key: string, value: T }) {
        const store = localForage.createInstance({ name, storeName });
        await store.ready();
        console.log(key);
        console.log(value);
        if (value instanceof Blob || value instanceof File) {
            await store.setItem<T>(key, value);
        } else {
            await store.setItem<T>(key, JSON.parse(JSON.stringify(value)));
        }
        
        console.log('finished storing ' + key);
    }

    public async fetchItem<T>({ name = 'modclub', storeName, key }: { name?: string, storeName: string, key: string }): Promise<T | null> {
        const store = localForage.createInstance({ name, storeName });
        await store.ready();
        return await store.getItem<T>(key);
    }

    public async removeItem({ name = 'modclub', storeName, key }: { name?: string, storeName: string, key: string }): Promise<void> {
        const store = localForage.createInstance({ name, storeName });
        await store.ready();
        await store.removeItem(key);
    }

    public async storeArray<T>({ name = 'modclub', storeName, uniqueKey, values }: { name?: string, storeName: string, uniqueKey: keyof T, values: T[] }) {
        const store = localForage.createInstance({ name, storeName });
        await store.ready();
        for (let value of values) {
            const key = value[uniqueKey];
            if (typeof key === 'string') {
                await store.setItem(key, value);
            }
        }
    }

    public async fetchArray<T>({ name = 'modclub', startAt, storeName, limit, filter }: { name?: string, storeName: string, startAt: number, limit: number, filter?: (value: T) => boolean }): Promise<T[]> {
        return new Promise<T[]>(async (resolve, reject) => {
            const db = localForage.createInstance({ name, storeName });
            let values: T[] = [];
            await db.iterate<T, void>((current, key, iterationNumber) => {
                console.log(iterationNumber);
                console.log(current);
                if (iterationNumber >= limit + startAt) {
                    return;
                } else {
                    if (iterationNumber >= startAt) {
                        if (!filter || filter(current)) {
                            values.push(current);
                        }
                    }
                }
            });
            resolve(values);
            values = [];
        });
    }

    public async removeDatabase(databaseName: string = 'modclub') {
        await localForage.dropInstance({
            name: databaseName
        });
    }

    public async removeStore(databaseName: string = 'modclub', storeName: string) {
        await localForage.dropInstance({
            name: databaseName,
            storeName
        });
    }

    public async addArrayItem<T extends { [key: string]: any }>({ name, storeName, value, key }: { name?: string, storeName: string, key: string, value: T }) {
        const store = localForage.createInstance({ name, storeName });
        await store.ready();
        await store.setItem(key, value);
    }

    public async updateArrayItem<T extends { [key: string]: any }>({ name = 'modclub', storeName, key, value }: { name?: string, storeName: string, key: string, value: T }) {
        const store = localForage.createInstance({ name, storeName });
        await store.ready();
        await store.setItem(value[key], value);
    }

    public async removeArrayItem({ name = 'modclub', storeName, key }: { name?: string, storeName: string, key: string }) {
        const store = localForage.createInstance({ name, storeName });
        await store.ready();
        await store.removeItem(key)
    }

    public async getArrayItem<T extends { [key: string]: any }>({ name = 'modclub', storeName, key }: { name?: string, storeName: string, key: string }): Promise<T | null> {
        const store = localForage.createInstance({ name, storeName });
        await store.ready();
        return await store.getItem<T>(key);
    }
}

export const clientStorageService = new ClientStorageService();