import * as React from 'react';
import { IonIcon, IonSpinner, NavContext } from '@ionic/react';
import { appConfig } from '../constants';
import { Photo } from '../models/database/supporting/photo';
import { createToast } from '../helpers';
import { photoFilesStore } from '../stores/photo-files-store';

interface AppPhotoProps {
  // networkStatus?: 'wifi' | 'cellular' | '4g' | '3g' | '2g' | 'unknown' | 'none';
  slot?: string;
  isRemovable?: boolean;
  onRemoveClicked?: () => any;
  onClicked?: () => any;
  isUploading?: boolean;
  photo: Photo;
  showOriginal?: boolean;
  showThumbnail?: boolean;
  maxWidth?: number;
  maxWidthUnits?: 'px' | 'vw' | '%';
  width?: number;
  widthUnits?: 'px' | 'vw' | '%';
  height?: number;
  heightUnits?: 'px' | 'vh' | '%';
}

export const AppPhoto: React.FC<AppPhotoProps> = ({ showOriginal, showThumbnail, onClicked, onRemoveClicked, isRemovable, isUploading, width, widthUnits, maxWidth, maxWidthUnits, height, heightUnits, photo, slot }) => {

  const [src, setSrc] = React.useState<string>();
  const [errored, setErrored] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (errored === true) {
      createToast('There was an unexpected error loading your photo.');
    }
  }, [errored]);

  React.useEffect(() => {
    function createImage(imgSrc: string) {
      const img = new Image();
      img.src = imgSrc;
      img.decode().then(() => {
        setSrc(imgSrc);
      }).catch(() => setErrored(true));
    }
    console.log('first try to load it locally');
    if (photo.localUri) {
      createImage(photo.localUri);
    } else {
      photoFilesStore.getPhotoFileAsUrl(photo.id).then(uri => {
        if (uri) {
          createImage(uri);
        } else {
          // Can't get locally so get server version
          if (showOriginal) {
            createImage(`${appConfig.storageBaseUrl}/photos/${photo.createdBy}/${photo.id}-original`);
          } else if (showThumbnail) {
            createImage(`${appConfig.storageBaseUrl}/photos/${photo.createdBy}/${photo.id}-xs`);
          } else {
            let browserWidth = window.innerWidth;
            console.log('Browser width is ' + browserWidth);
            if (browserWidth < 450) {
              createImage(`${appConfig.storageBaseUrl}/photos/${photo.createdBy}/${photo.id}-sm`);
            } else if (browserWidth < 1000) {
              createImage(`${appConfig.storageBaseUrl}/photos/${photo.createdBy}/${photo.id}-md`);
            } else {
              createImage(`${appConfig.storageBaseUrl}/photos/${photo.createdBy}/${photo.id}-lg`);
            }
          }
        }
      }).catch(() => setErrored(true)); // should never reach this catch
    }
  }, []);

  if (errored === true) {
    return null;
  }

  {!errored && !src && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><IonSpinner name='lines-small' /></div>}

  if (isRemovable && onRemoveClicked) {
    console.log('is removable');
    return <div style={{
      position: 'relative',
      width: !width ? 'auto' : widthUnits ? `${width}${widthUnits}` : `${width}px`,
      maxWidth: !maxWidth ? '100%' : maxWidthUnits ? `${maxWidth}${maxWidthUnits}` : `${maxWidth}px`,
      height: !height ? 'auto' : heightUnits ? `${height}${heightUnits}` : `${height}px`,
      opacity: (isUploading === true) ? '0.5' : '1.0'
    }}>
      {(!isUploading) && <IonIcon name="close" color="danger" style={{ backgroundColor: 'lightgray', color: 'red', position: 'absolute', top: '0', right: '0', zIndex: '10000' }} onClick={() => onRemoveClicked()} />}
      {(isUploading === true) && <IonSpinner style={{ position: 'absolute', zIndex: 10000, top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} name='lines-small' />}
        <img src={src} alt={photo.caption} onError={(evt) => {
          console.log('Error in image element');
          console.log(evt.target)
          setErrored(true);
        }} />
    </div>
  }

  return <div style={{
    width: !width ? 'auto' : widthUnits ? `${width}${widthUnits}` : `${width}px`,
    maxWidth: !maxWidth ? '100%' : maxWidthUnits ? `${maxWidth}${maxWidthUnits}` : `${maxWidth}px`,
    height: !height ? 'auto' : heightUnits ? `${height}${heightUnits}` : `${height}px`,
    opacity: (isUploading === true) ? '0.5' : '1.0'
  }}>
      <img src={src} alt={photo.caption} onError={() => {
        console.log('Error in image element');
        setErrored(true);
      }} />
  </div>
};