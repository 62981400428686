import { IonFooter, IonToolbar, IonButtons, IonButton, IonIcon } from '@ionic/react';
import * as React from 'react';

export const TabsBar: React.FC<{ selectedTab: 'dashboard' | 'garage' }> = ({ selectedTab }) => {
  const [isLargeViewport, setIsLargeViewport] = React.useState(window.innerWidth > 768 ? true : false);
  React.useEffect(() => {
    const resizeListener: (this: Window, ev: UIEvent) => any = () => {
      if (window.innerWidth > 768) {
        // setSideMenuIsVisible(true);
        setIsLargeViewport(true);
      } else {
        // setSideMenuIsVisible(false);
        setIsLargeViewport(false);
      }
    }
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);
  return isLargeViewport ? null : <IonFooter style={{ position: 'fixed', bottom: '0' }}>
    <IonToolbar color="light">
      <IonButtons style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', height: '55px' }}>
        <IonButton routerDirection='none' routerLink='/dashboard' fill="clear" color={selectedTab === 'dashboard' ? 'primary' : 'dark'} style={{ width: '33%', height: '54px' }}>
          <div>
            <IonIcon style={{ fontSize: '36px' }} src={selectedTab === 'dashboard' ? '/assets/img/dashboard-color.svg' : '/assets/img/dashboard-bw.svg'} />
            <br />
            <div style={{ fontSize: '12px' }}>Dashboard</div>
          </div>
        </IonButton>
        <IonButton routerDirection='none' routerLink='/garage' size="small" fill="clear" color={selectedTab === 'garage' ? 'primary' : 'dark'} style={{ width: '33%', height: '54px' }}>
          <div>
            <IonIcon style={{ fontSize: '36px' }} src={selectedTab === 'garage' ? '/assets/img/garage-color.svg' : '/assets/img/garage-bw.svg'} />
            <br />
            <div style={{ fontSize: '12px' }}>Garage</div>
          </div>
        </IonButton>
        {/* <IonButton routerDirection='none' routerLink='/more' size="small" fill="clear" color={selectedTab === 'more' ? 'primary' : 'dark'} style={{ width: '33%', height: '54px' }}>
          <div style={{ position: 'relative' }}>
            <IonBadge hidden={moreTabBadgeCount < 1} style={{ position: 'absolute', top: '0', left: '25px', zIndex: '1000' }}>{moreTabBadgeCount}</IonBadge>
            <IonIcon style={{ fontSize: '36px' }} src={selectedTab === 'more' ? '/assets/img/more-color.svg' : '/assets/img/more-bw.svg'} />
            <br />
            <div style={{ fontSize: '12px' }}>More</div>
          </div>
        </IonButton> */}
      </IonButtons>
    </IonToolbar>
  </IonFooter>;
};
