import * as React from 'react';
import {deviceService} from '../services/device-service';

export function useDevicePlatform() {
    const [platform, setPlatform] = React.useState<"web" | "ios" | "android" | "electron">('web');
    React.useEffect(() => {
        if (deviceService.deviceInfo) {
            setPlatform(deviceService.deviceInfo.platform);
        }
    }, []);

    return platform;
}