import * as React from 'react';

type ContainerProps = {
    id?: string;
    color?: string;
    border?: {
        thickness?: number;
        type?: 'solid' | 'dashed' | 'dotted';
        color?: string;
        radius?: number;
    }
    dropShadow?: boolean;
    padding?: string | number | {
        top?: number,
        bottom?: number,
        start?: number,
        end?: number
    };
    margin?: string | number | {
        top?: number,
        bottom?: number,
        start?: number,
        end?: number
    };
    opacity?: number;
    cssClasses?: string;
    onClick?: () => any;
    position?: 'relative' | 'absolute' | 'fixed';
    roundedCorners?: boolean;
    round?: boolean;
    center?: boolean;
    height?: { value: number, units?: 'px' | 'vh' | '%'};
    width?: { value: number, units?: 'px' | 'vh' | '%'};
    maxHeight?: { value: number, units?: 'px' | 'vh' | '%'};
    maxWidth?: number | { value: number, units?: 'px' | 'vw' | '%'};
    display?: 'flex';
}

export const Container: React.FC<ContainerProps> = (props) => {

    function getPadding(): string | undefined {
        if (!props.padding) {
            return undefined;
        } else {
            if (typeof props.padding === 'string') {
                return props.padding;
            }
            else if (typeof props.padding === 'number') {
                return `${props.padding}px`;
            }
            else {
                return `${props.padding.top || 0}px ${props.padding.end || 0}px ${props.padding.bottom || 0}px ${props.padding.start || 0}px`;
            }
        }
    }

    function getMargin(): string | undefined {
        if (!props.margin) {
            return undefined;
        } else {
            if (typeof props.margin === 'string') {
                return props.margin;
            }
            else if (typeof props.margin === 'number') {
                return `${props.margin}px`;
            }
            else {
                return `${props.margin.top || 0}px ${props.margin.end || 0}px ${props.margin.bottom || 0}px ${props.margin.start || 0}px`;
            }
        }
    }

    function getBorderRadius() {
        if (props.round === true) {
            return '50%';
        }
        if (props.border && props.border.radius) {
            return `${props.border.radius}px`;
        } else if (props.roundedCorners) {
            return '8px';
        } else {
            return 'none';
        }
    }

    function getHeight() {
        if (!props.height) {
            return '';
        } else {
            if (!props.height.units) {
                return `${props.height.value}px`;
            } else {
                return `${props.height.value}${props.height.units}`;
            }
        }
    }

    function getMaxHeight() {
        if (!props.maxHeight) {
            return '';
        } else {
            if (!props.maxHeight.units) {
                return `${props.maxHeight.value}px`;
            } else {
                return `${props.maxHeight.value}${props.maxHeight.units}`;
            }
        }
    }

    function getWidth() {
        if (!props.width) {
            return 'auto';
        } else {
            if (!props.width.units) {
                return `${props.width.value}px`;
            } else {
                return `${props.width.value}${props.width.units}`;
            }
        }
    }

    function getMaxWidth() {
        if (!props.maxWidth) {
            return 'auto';
        } else {
            if (typeof props.maxWidth === 'number') {
                return `${props.maxWidth}px`;
            } else {
                if (!props.maxWidth.units) {
                    return `${props.maxWidth.value}px`;
                } else {
                    return `${props.maxWidth.value}${props.maxWidth.units}`;
                }
            }
        }
    }

    function getCssClasses() {
        let classes = props.cssClasses;
        if (props.center) {
            classes = classes + ' ion-justify-content-center ion-align-items-center';
        }
        return classes;
    }

    function getBorder() {
        if (!props.border) {
            return 'none';
        }
        let borderThickness = props.border.thickness || 1;
        let borderType = props.border.type || 'solid';
        let borderColor = `var(--ion-color-${props.border.color})` || '#EDEDED';

        return `${borderThickness}px ${borderType} ${borderColor}`;
    }

    function getOpacity() {
        if (!props.opacity) {
            return '1.0';
        }
        return `${props.opacity}`;
    }

    return <div id={props.id} onClick={() => {
        if (props.onClick) {
            props.onClick();
        }
    }} className={getCssClasses()} style={{
        backgroundColor: props.color ? `var(--ion-color-${props.color})` : 'none',
        color: props.color ? `var(--ion-color-${props.color}-contrast)` : 'none',
        border: getBorder(),
        borderRadius: getBorderRadius(),
        padding: getPadding(),
        margin: getMargin(),
        boxShadow: props.dropShadow ? 'rgba(0, 0, 0, 0.12) 0px 4px 16px' : 'none',
        position: props.position,
        textAlign: props.center ? 'center' : 'unset',
        height: getHeight(),
        width: getWidth(),
        maxHeight: getMaxHeight(),
        maxWidth: getMaxWidth(),
        display: props.center ? 'flex' : props.display,
        opacity: getOpacity()
    }}>
        {props.children}
    </div>
}