import { DeviceInfo, Device } from '@capacitor/device';

export class DeviceService {
    public deviceInfo: Readonly<DeviceInfo> | undefined;
    
    public async initialize() {
        this.deviceInfo = await Device.getInfo();
    }
}

export const deviceService = new DeviceService();