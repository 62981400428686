import * as React from 'react';
import { IonButton, IonButtons, IonContent, IonIcon, IonItem, IonModal, IonTextarea, IonTitle, IonToolbar, useIonViewDidLeave } from '@ionic/react';
import { Post } from '../models/database/user-created/post';
import { EditableTagsBar } from '../components/editable-tags-bar';
import { TagsFormInput } from '../components/tags-form-input';
import { handleError } from '../helpers';
// import { postsService } from '../services/posts-service';
import { alertController } from '@ionic/core';
import { Container } from '../components/container';
import { imageOutline } from 'ionicons/icons';
import { photosService } from '../services/photos-service';
import { AppPhoto } from '../components/app-photo';
import { UnexpectedException } from '../models/supporting/exceptions';
import { Photo } from '../models/database/supporting/photo';
import { useAuth } from '../hooks/use-auth';

export const AddEditPostModal: React.FC<{
    isOpen: boolean,
    existingPost?: Post,
    onCanceled: () => any,
    onSaved: () => any
}> = ({ isOpen, existingPost, onCanceled, onSaved }) => {
    const auth = useAuth();
    const [post, setPost] = React.useState<Pick<Post, 'content' | 'photos' | 'tags' | 'privacy'>>();
    const [isUploadingPhotos, setIsUploadingPhotos] = React.useState<boolean>(false);
    const [pendingPhotoUploads, setPendingPhotoUploads] = React.useState<(Photo & { file: File })[]>([]);
    const [madeChanges, setMadeChanges] = React.useState<boolean>(false);
    React.useEffect(() => {
        if (!existingPost) {
            setPost({
                content: '',
                photos: [],
                tags: {},
                privacy: 'public'
            });
        } else {
            setPost({ content: existingPost.content, photos: existingPost.photos, tags: existingPost.tags, privacy: existingPost.privacy });
        }
    }, [isOpen]);

    async function save() {
        try {
            if (!post) {
                throw new Error();
            }
            photosService.uploadPhotos(pendingPhotoUploads);
            post.photos = [...post.photos, ...pendingPhotoUploads];
            if (existingPost) {
             //   await updatePost({ post: { ...existingPost, ...post }, authContext: auth.context })
                setPost(undefined);
            } else {
              //  await addPost({ post, authContext: auth.context });
                setPost(undefined);
            }
            onSaved();
        } catch (error) {
            if (error instanceof Error)
                handleError(error);
        }
    }

    async function cancel() {
        try {
            if (madeChanges) {
                const alert = await alertController.create({
                    message: 'You have unsaved changes, are you sure you want to cancel?', buttons: ['No', {
                        text: 'Yes',
                        handler: () => {
                            setMadeChanges(false);
                            setPost(undefined);
                            onCanceled();
                        }
                    }]
                });
                await alert.present();
            } else {
                setPost(undefined);
                onCanceled();
            }
        } catch (error) {
            if (error instanceof Error)
                handleError(error);
        }
    }

    if (!post) {
        return null;
    }

    return <IonModal isOpen={isOpen}>
        <IonToolbar color='light'>
            <IonButtons slot='start'>
                <IonButton color='dark' onClick={() => cancel()}>Cancel</IonButton>
            </IonButtons>
            <IonTitle>{existingPost ? 'Edit Post' : 'Add Post'}</IonTitle>
            <IonButtons slot='end'>
                <IonButton disabled={isUploadingPhotos} color='primary' onClick={() => save()}>Save</IonButton>
            </IonButtons>
        </IonToolbar>
        <IonContent color='white'>
            <Container padding='10px' color='white'>

                {/* Start Post Content */}
                <div className="ion-padding ion-margin-top">
                    <IonItem>
                        <IonTextarea
                            style={{ minHeight: '80px' }}
                            autoCapitalize="true"
                            autoCorrect="true"
                            onIonChange={evt => {
                                if (evt.detail.value) {
                                    setPost({ ...post, content: evt.detail.value || '' });
                                    setMadeChanges(true);
                                }
                            }}
                            value={post.content}
                            placeholder="What's going on?"
                            maxlength={10000}
                        />
                    </IonItem>
                    <div style={{ textAlign: 'center', fontSize: '0.6em' }}>{post.content.length}/10000</div>
                </div>
                {/* End Post Content */}

                {/* Start Post Photos */}
                <IonButton
                    expand='block'
                    color='primary'
                    fill='solid'
                    className="ion-margin-start ion-margin-end"
                    onClick={async () => {
                        try {
                            const result = await photosService.choosePhotos();
                            setPendingPhotoUploads(current => [...current, ...result]);
                            setMadeChanges(true);
                        } catch (error) {
                            if (error instanceof Error)
                                handleError(error || new UnexpectedException('There was an error trying to choose and upload photos.'));
                        }
                    }}>
                    <IonIcon slot='start' icon={imageOutline}></IonIcon> Add Photos
                </IonButton>

                {/* Photos grid */}
                <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', flexWrap: 'wrap' }}>
                    {/* This is existing photos if editing */}
                    {post.photos.length > 0 && post.photos.map((photo) => <div key={photo.id} style={{ margin: '5px 3px 0 0', width: '120px' }}>
                        <AppPhoto photo={photo} isRemovable={true} onRemoveClicked={() => {
                            photosService.deletePhoto(photo.id);
                            setMadeChanges(true);
                        }} />
                    </div>)}
                    {/* These are new photos that will be saved after clicking Save */}
                    {pendingPhotoUploads.length > 0 && pendingPhotoUploads.map((photo) => <div key={photo.id}
                        style={{
                            margin: '5px 3px 0 0',
                            width: '120px'
                        }}>
                        <AppPhoto photo={photo} isRemovable={true} onRemoveClicked={() => {
                            setPendingPhotoUploads(current => current.filter(p => p.id !== photo.id));
                            setMadeChanges(true);
                        }} />
                    </div>)}
                </div>
                {/* End Photos grid */}
                {/* End Post Photos */}

                {/* Start of Post Tags */}
                <TagsFormInput maxNumberTags={50} tags={post.tags} onTagAdded={newTag => {
                    setPost({ ...post, tags: { ...post.tags, [newTag]: true } });
                    setMadeChanges(true);
                }} />
                <EditableTagsBar tags={post.tags} onTagRemoved={tag => {
                    delete post.tags[tag];
                    setPost({ ...post, tags: post.tags });
                    setMadeChanges(true);
                }} />
                {/* End Post Tags */}

            </Container>
        </IonContent>
    </IonModal>
}