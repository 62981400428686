import * as React from 'react';
import { PostComment } from '../models/database/user-created/post-comment';
import { PostCommentCard } from './post-comment-card';

type PostCommentsListProps = {
    comments: PostComment[];
    onEditClicked: (comment: PostComment) => any;
    onDeleteClicked: (comment: PostComment) => any;
    onReplyClicked: (comment: PostComment) => any;
}

export const PostCommentsList: React.FC<PostCommentsListProps> = ({ comments, onEditClicked, onDeleteClicked, onReplyClicked }) => {
    return <> {comments.map(comment => {
        console.log('Rendering ' + comment.id);
        return <PostCommentCard
            key={comment.id}
            comment={comment}
            onAvatarClicked={() => {
                //navContext.navigate(`/profile/${comment.replyingToComment!.creator.username}`)

            }}
            onEditCommentClicked={() => onEditClicked(comment)}
            onReplyToCommentClicked={() => onReplyClicked(comment)}
            onDeleteCommentClicked={() => onDeleteClicked(comment)}
        />
    })}</>
};