import { toastController, alertController, modalController, ToastButton } from "@ionic/core";
import { v4 } from 'uuid';

import { ValidationException, NetworkException, AuthenticationException, AuthorizationException, EntityNotFoundException } from "./models/supporting/exceptions";
import { User } from "./models/database/system-created/user";
import { UniqueIdType } from "./models/app/unique-id-type";
import { UserCreatedBaseEntity } from "./models/database/user-created/user-created-base-entity";

export function entityArrayToKeyedObject<T extends { id: string }>(arr: T[]): { [key: string]: T } {
  let obj: { [key: string]: T } = {};
  for (let item of arr) {
    obj[item.id] = item;
  }
  return obj;
}

export function showLoginModal() {
  modalController.create({ component: 'login-page' }).then(modal => modal.present());
}

export function isValidEmail(email?: string) {
  if (!email) {
    return false;
  }
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
}

export function isValidPhoneNumber(phoneNumber?: string) {
  if (!phoneNumber) {
    return false;
  }
  if (phoneNumber.trim().length === 10 && !isNaN(Number(phoneNumber))) {
    return true;
  }
  return false;
}

export function getBaseServerUrl(): string {
  //window.location.hostname.includes('localhost'))
  return 'https://social-modclub-api.kevinclarkadstech.now.sh';
  //  return 'https://api.modclub.social';

}

export function scrollToBottom(behavior: 'auto' | 'smooth' = 'smooth', id: string = 'bottom') {
  const bottom = document.getElementById(id);
  if (bottom) {
    setTimeout(() => {
      bottom.scrollIntoView({ behavior: behavior });
    }, 300);
  }
}

export function createNumberId(): number {
  return Math.floor(Math.random() * 10) + 1;
}

export function UniqueId(): UniqueIdType {
  return v4();
}



/**
 * Deprecated
 */
export function createId(): number {
  const d = new Date();
  let year = d.getUTCFullYear().toString();
  let month = (d.getUTCMonth() + 1).toString();
  let day = d.getUTCDate().toString();
  let hours = d.getUTCHours().toString();
  let mins = d.getUTCMinutes().toString();
  let seconds = d.getUTCSeconds().toString();

  if (month.length === 1) {
    month = '0' + month;
  }
  if (day.length === 1) {
    day = '0' + day;
  }

  if (hours.length === 1) {
    hours = '0' + hours;
  }

  if (mins.length === 1) {
    mins = '0' + mins;
  }

  if (seconds.length === 1) {
    seconds = '0' + seconds;
  }
  const utc = Number(
    year + month + day + hours + mins + seconds
  );
  return parseInt(utc + '' + Math.floor(Math.random() * 10) + 1);
}

export const DateHelpers = {
  fromNow(date: Date): string {
    return date.toString();

  },
  formatFriendlyDateTime(d: string | Date): string {
    if (!d) {
      return '';
    }
    if (typeof d === 'string') {
      d = new Date(d);
    } else if (d instanceof Date) {

    } else {
      // const timestamp = new firebase.firestore.Timestamp(d.seconds, d.nanoseconds);
      // d = timestamp.toDate();
      return '';
    }
    return `${d.toLocaleDateString()} ${d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
  },
  formatFriendlyDat(d: Date): string {
    return `${d.toLocaleDateString()}`;
  },
  formatFriendlyTime(d: Date): string {
    return `${d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
  },
  timeAgo(d: Date): string {
    console.log(d.toDateString());
    //const ms = d.getTime();
    //  console.log(ms);
    var ago = d.getTime();// Math.floor(ms / 1000);
    console.log(ago);
    var part = 0;

    if (ago < 2) { return "just now"; }
    if (ago < 5) { return "just now"; }
    if (ago < 60) { return ago + " seconds ago"; }

    if (ago < 120) { return "a minute ago"; }
    if (ago < 3600) {
      while (ago >= 60) { ago -= 60; part += 1; }
      return part + " minutes ago";
    }

    if (ago < 7200) { return "an hour ago"; }
    if (ago < 86400) {
      while (ago >= 3600) { ago -= 3600; part += 1; }
      return part + " hours ago";
    }

    if (ago < 172800) { return "a day ago"; }
    if (ago < 604800) {
      while (ago >= 172800) { ago -= 172800; part += 1; }
      return part + " days ago";
    }

    if (ago < 1209600) { return "a week ago"; }
    if (ago < 2592000) {
      console.log('ehere')
      while (ago >= 604800) { ago -= 604800; part += 1; }
      return part + " weeks ago";
    }
    //1,600,338,240
    if (ago < 5184000) { return "a month ago"; }
    if (ago < 31536000) {
      while (ago >= 2592000) { ago -= 2592000; part += 1; }
      return part + " months ago";
    }

    if (ago < 1419120000) { // 45 years, approximately the epoch
      return "more than year ago";
    }

    // TODO pass in Date.now() and ms to check for 0 as never
    return "";
  }

}

export function wait(ms: number): Promise<void> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (ms >= 6000) {
        reject();
      }
      resolve();
    }, ms);
  })
}

export function formatCount(count: number): string {
  if (typeof count !== 'number') {
    return '0';
  }
  const countAsString = count.toString();
  switch (countAsString.length) {
    case 0:
    case 1:
    case 2:
    case 3:
      return countAsString;
    case 4:
      return `${countAsString.slice(0, 1)}.${countAsString.slice(1, 2)}K`;
    case 5:
      return `${count.toString().slice(0, 2)}.${countAsString.slice(1, 2)}K`;
    case 6:
      return `${count.toString().slice(0, 3)}.${countAsString.slice(1, 2)}K`;
    case 7:
      return `${count.toString().slice(0, 1)}.${countAsString.slice(1, 2)}M`;
    case 8:
      return `${count.toString().slice(0, 2)}.${countAsString.slice(1, 2)}M`;
    case 9:
      return `${count.toString().slice(0, 3)}.${countAsString.slice(1, 2)}M`;
    case 10:
      return `${count.toString().slice(0, 1)}.${countAsString.slice(1, 2)}B`;
  }
  return String(count);
}

export async function createToastForValidationErrors(errors: string[]) {
  const toast = await toastController.create({
    header: 'Validation Errors',
    buttons: ['Close'],
    duration: 3000,
    message: errors.join('<br />')
  });
  await toast.present();
}

export async function createToast(message: string, duration?: number, header?: string, buttons?: (string | ToastButton)[]) {
  const toast = await toastController.create({ message, duration: duration || 2500, header, buttons });
  await toast.present();
}

export async function presentErrorToast({ message, header, duration }: { message?: string, header?: string, duration?: number }): Promise<void> {
  const toast = await toastController.create({
    header: header || '',
    message: message || 'There was an unexpected error.',
    duration: duration || 2000
  });

  const toastShadowRoot = toast.shadowRoot;
  if (toastShadowRoot) {
    const toastHeader = toastShadowRoot.querySelector('div.toast-header') as HTMLDivElement;
    if (toastHeader) {
      toastHeader.style.fontWeight = 'bolder';
      toastHeader.style.marginBottom = '6px';
    }
  }

  await toast.present();
  await wait(duration || 1500);
}

export async function presentErrorAlert({ message, header }: { message: string, header?: string }) {
  const alert = await alertController.create({ message, header, buttons: ['Ok'] });
  await alert.present();
}

export async function handleError<E extends Error>(error?: E, type?: 'toast' | 'alert'): Promise<void> {
  console.log('handleError');
  console.error(error);
  if (error instanceof ValidationException) {
    console.log(error.errors);
    const validationErrors = error.errors;
    let validationErrorsString = '';

    validationErrors.forEach((validationError, index) => {
      if (index === validationErrors.length - 1) {
        validationErrorsString += `${validationError}`
      } else {
        validationErrorsString += `${validationError} <br /><br />`
      }
    });
    console.log(validationErrorsString);
    // This line should never get called but better than displaying nothing at all
    if (validationErrorsString.length === 0) {
      validationErrorsString = 'There were validation errors.  Please try again.';
    }
    if (!type || type === 'toast') {
      await presentErrorToast({ header: 'Validation Errors', message: validationErrorsString });
    } else {
      await presentErrorAlert({ header: 'Validation Errors', message: validationErrorsString });
    }

  } else if (
    error instanceof NetworkException) {
    if (!type || type === 'toast') {
      await presentErrorToast({ header: 'Network Error', message: error.message });
    } else {
      await presentErrorAlert({ header: 'Network Error', message: error.message });
    }
  }
  else if (
    error instanceof AuthenticationException) {
    if (!type || type === 'toast') {
      await presentErrorToast({ header: 'Authentication Error', message: error.message });
    } else {
      await presentErrorAlert({ header: 'Authentication Error', message: error.message });
    }
  } else if (error instanceof AuthorizationException) {
    if (!type || type === 'toast') {
      await presentErrorToast({ header: '', message: error.message });
    } else {
      await presentErrorAlert({ header: '', message: error.message });
    }
  } else if (error instanceof EntityNotFoundException) {
    if (!type || type === 'toast') {
      await presentErrorToast({ header: 'Not Found Error', message: error.message });
    } else {
      await presentErrorAlert({ header: 'Not Found Error', message: error.message });
    }
  }
  else if (error && error.message && error.message.includes('Network Error')) {
    if (!type || type === 'toast') {
      await presentErrorToast({ header: 'Network Error', message: 'There was an unexpected error connecting to the server.  Please wait a few minutes and try again.' });
    } else {
      await presentErrorAlert({ header: 'Network Error', message: 'There was an unexpected error connecting to the server.  Please wait a few minutes and try again.' });
    }
  }
  else {
    if (!type || type === 'toast') {
      await presentErrorToast({ header: 'App Error', message: 'There was an unexpected error.' });
    } else {
      await presentErrorAlert({ header: 'App Error', message: 'There was an unexpected error.' });
    }
  }
}

export async function mapRecordsWithCreators<T extends { createdBy: string }>(records: T[]): Promise<(T & { creator: User })[]> {
  const recordsWithCreators: (T & { creator: User })[] = [];
  for (let record of records) {
    // const creator = await getCreator(record.createdBy);
    // if (record && creator) {
    //   recordsWithCreators.push({ ...record, creator: creator });
    // }
  }
  return recordsWithCreators;
}

export function arrayToObjectOfObjects<T extends UserCreatedBaseEntity>(arr: T[]): { [id: string]: T } {
  let obj: { [id: string]: T } = {};
  arr.forEach(a => {
    obj[a.id] = a;
  });
  return obj;
}

export function mergeRecordsById<T extends { id: string }>(existingRecords: T[], newRecords: T[], prependNewRecords?: boolean): T[] {
  let mergedRecords: T[] = [];
  if (existingRecords.length > 0) {
    existingRecords.forEach(existingRecord => {
      const newRecordIndex = newRecords.findIndex(n => n.id === existingRecord.id);
      if (newRecordIndex >= 0) {
        existingRecord = newRecords[newRecordIndex];
      } else {
        prependNewRecords === true ? mergedRecords.unshift(existingRecord) : mergedRecords.push(existingRecord);
      }
    });
    newRecords.forEach(newRecord => mergedRecords.push(newRecord));
  } else {
    mergedRecords = newRecords;
  }
  return mergedRecords;
}

export function orderByCreated<T extends { created: Date }>(records: T[], direction?: 'asc' | 'desc'): T[] {
  return records.sort((a, b) => {
    if (a.created < b.created) {
      if (direction === 'desc') {
        return 1;
      }
      return -1;
    }

    if (a.created > b.created) {
      if (direction === 'desc') {
        return -1;
      }
      return 1;
    }

    return 0;
  });
}

export function orderByLastModified<T extends { lastModified: Date }>(records: T[], direction?: 'asc' | 'desc'): T[] {
  return records.sort((a, b) => {
    if (a.lastModified < b.lastModified) {
      if (direction === 'desc') {
        return 1;
      }
      return -1;
    }

    if (a.lastModified > b.lastModified) {
      if (direction === 'desc') {
        return -1;
      }
      return 1;
    }

    return 0;
  });
}

export function isDefined({ obj, key }: { obj: { [key: string]: any; }; key: string; }): boolean {
  if (!obj || !key) {
    return false;
    const p = typeof obj[key]
  }
  if (Object.keys(obj).includes(key) && obj[key] !== null) {
    return true;
  }
  return false;
}

export function hasPropAndValueOfType<T extends { [key: string]: any }>(obj: T, key: keyof T, valueType: "string" | "number" | "bigint" | "boolean" | "symbol" | "undefined" | "object" | "function"): boolean {
  if (Object.keys(obj).includes(key as string) === true) {
    if (typeof obj[key] === valueType) {
      return true;
    }
  }
  return false;
}

export function hasPropAndValueOfDate<T extends { [key: string]: any }>(obj: T, key: keyof T): boolean {
  if (Object.keys(obj).includes(key as string) === true) {
    if ((obj[key] as any) instanceof Date) {
      return true;
    }
  }
  return false;
}

export function hasInvalidPropertyNames<T extends { [key: string]: any }>(obj: T, properties: (keyof T)[]) {
  if (Object.keys(obj).length !== properties.length) {
    return true;
  }

  for (let property of properties) {
    if (obj[property] === undefined) {
      return true;
    }
  }
  return false;
}

export function isPastDate(value?: unknown): boolean {
  console.log('Checking for past date')
  if (!value) {
    return false;
  }
  if (typeof value === 'string') {
    value = new Date(value);
  }
  if (!(value instanceof Date)) {
    return false;
  }
  if (value > new Date()) {
    return false;
  }
  return true;
}

export function isFutureDate(value?: unknown): boolean {
  if (!value) {
    return false;
  }
  if (!(value instanceof Date)) {
    return false;
  }
  if (value < new Date()) {
    return false;
  }
  return true;
}

export function validateUserCreatedEntityProps<T extends UserCreatedBaseEntity>(input: T) {
  if (!input) {
    throw new Error();
  }
  if (!input.id || !input.created || !input.createdBy || !input.lastModified) {
    throw new Error();
  }
  if (!(isPastDate(input.created))) {
    throw new Error();
  }

  if (!(isPastDate(input.lastModified))) {
    throw new Error();
  }

  if (input.created < input.lastModified) {
    throw new Error();
  }

  if (typeof input.createdBy !== 'string') {
    throw new Error();
  }
}

export function resizeWebP(photoUri: string) {
  return new Promise<Blob>((resolve, reject) => {
    const img = new Image();
    img.src = photoUri;
    /* eslint-disable */
    img.onload = async () => {
      console.log('NOW READING AS IMAGE')
      const elem = document.createElement('canvas');
      let imgWidth = img.width;
      let imgHeight = img.height;

      if (imgWidth > imgHeight) {
        if (imgWidth > 150) {
          imgHeight *= 150 / imgWidth;
          imgWidth = 150;
        }
      } else {
        if (imgHeight > 150) {
          imgWidth *= 150 / imgHeight;
          imgHeight = 150;
        }
      }
      elem.width = imgWidth;
      elem.height = imgHeight;
      const ctx = elem.getContext('2d');
      if (!ctx) {
        return reject();
      }
      // img.width and img.height will contain the original dimensions
      ctx.drawImage(img, 0, 0, imgWidth, imgHeight);
      ctx.canvas.toBlob((blob) => {
        if (!blob) {
          return reject();
        }
        resolve(blob);
      });
    }
  });
}

export function hideTabs() {
  const tabsEl = document.querySelector('ion-tab-bar');
  if (tabsEl) {
    tabsEl.hidden = true;
  }
}

export function showTabs() {
  const tabsEl = document.querySelector('ion-tab-bar');
  if (tabsEl) {
    tabsEl.hidden = false;
  }
}