import * as React from 'react';
import { IonAlert, IonActionSheet, NavContext, ActionSheetButton, IonAvatar, IonItem, IonRouterLink, IonText, IonToolbar, IonButtons, IonButton, IonIcon, IonLabel } from '@ionic/react';
import { createOutline, documentTextOutline, shareOutline, trashOutline, close, ellipsisVerticalOutline, chatboxOutline, heart, heartOutline } from 'ionicons/icons';
import { DateHelpers, handleError } from '../helpers';
import { Post } from '../models/database/user-created/post';
import { Container } from './container';
import { InteractionsCountsBar } from './interactions-counts-bar';
import { PresentationalPhotosGrid } from './presentational-photos-grid';
import { TagsBar } from './tags-bar';
import { UserAvatar } from './user-avatar';
import { ClientToServerWebSocketEvents, Emojis, ServerToClientWebSocketEvents } from '../constants';
import { ReactionsButton } from './reactions-button';
import { AppPhoto } from './app-photo';
import { AddEditPostModal } from '../modals/add-edit-post-modal';
import { useAuth } from '../hooks/use-auth';
import { WebSocketProvider } from '../providers/web-socket-provider';
// import { removePost } from '../functions/remove-post';

export const PostCard = React.memo<{
  post: Post,
  viewFullDetail: boolean,
  ws: WebSocketProvider
}>(({
  post,
  viewFullDetail,
  ws
}) => {
  const auth = useAuth();
  const navContext = React.useContext(NavContext);
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const [actionSheetShowing, setActionSheetShowing] = React.useState<boolean>(false);
  const [deleteAlertShowing, setDeleteAlertShowing] = React.useState<boolean>(false);
  console.log('Rendering PostDetail for ' + post.id);

  React.useEffect(() => {
    ws.on<Post>(ServerToClientWebSocketEvents.UpdatedPost, updatedPost => {
      console.log('Got event that post updated');
    });
    return () => {
      console.log('Post card effect cleanup');
      ws.off(ServerToClientWebSocketEvents.UpdatedPost);
    }
  }, []);
  function getActionSheetButtons(): ActionSheetButton[] {
    if (auth.context?.user.id === post?.createdBy) {
      return [
        {
          text: 'Edit Post',
          icon: createOutline,
          handler: () => {
            setActionSheetShowing(false);
            setIsEditing(true);
          }
        },
        {
          text: 'Delete Post',
          role: 'destructive',
          icon: trashOutline,
          handler: () => {
            console.log('Delete clicked');
            console.log(post?.id);
            setDeleteAlertShowing(true);
          }
        },
        {
          text: 'Cancel',
          icon: close,
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ];
    } else {
      return [
        {
          text: 'Share',
          icon: shareOutline,
          handler: () => {
            console.log('Share clicked');
            //share({ appState.currentUser: appState.global.appState.currentUser, url: 'http://localhost:3000/garage' }).then(() => console.log('Shared')).catch(() => createToast('There was an error sharing.'));
          }
        },
        {
          text: 'Report',
          icon: documentTextOutline,
          handler: () => {
            console.log('Rpeort clicked');
          }
        },
        {
          text: 'Cancel',
          icon: close,
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ];
    }
  }

  return !post ? null : <Container margin='20px 8px' padding="0" roundedCorners dropShadow>
    <IonToolbar color="light" style={{ borderRadius: '8px' }}>
      <IonLabel className="ion-text-wrap">
        <UserAvatar user={post.creator} />

      </IonLabel>
      <IonButtons slot="end">
        <IonButton slot="icon-only" onClick={() => {

        }}>
          <IonIcon slot="icon-only" icon={shareOutline} />
        </IonButton>
        <IonButton slot="icon-only" onClick={() => {
          setActionSheetShowing(true);
        }}>
          <IonIcon slot="icon-only" icon={ellipsisVerticalOutline} />
        </IonButton>
      </IonButtons>
    </IonToolbar>

    <Container>
      <div className="ion-text-center" style={{ padding: '6px 0 8px 0', color: 'gray', fontSize: '12px' }} onClick={() => {
        navContext.navigate(`/post/${post.id}`);
      }}>
        {DateHelpers.formatFriendlyDateTime(post.created)}
      </div>
      <Container cssClasses="ion-padding-start ion-padding-end post-card-content" onClick={() => {
        navContext.navigate(`/post/${post.id}`);
      }}>
        <div style={{ whiteSpace: 'pre-wrap' }} className={viewFullDetail ? '' : 'max-lines-6'}>{post.content}</div>
      </Container>

      <Container padding={10}>
        <TagsBar tags={post.tags} />
      </Container>

      {viewFullDetail ? <>
        {post.photos.map(photo => <AppPhoto photo={photo} />)}
      </> : <>
        {post.photos[0] && <AppPhoto photo={post.photos[0]} />}
        {post.photos.length === 2 && <AppPhoto photo={post.photos[1]} />}
        {post.photos.length > 2 && <div>
          <AppPhoto photo={post.photos[1]} />
          <AppPhoto photo={post.photos[2]} />
        </div>}
      </>}
      {/* <PresentationalPhotosGrid photos={post.photos} /> */}

      <InteractionsCountsBar
        commentsCount={post.numComments}
        savesCount={post.numSaves}
        reactionsCount={post.numReactions}
        onCommentsClicked={() => {

        }}
        onSavesClicked={() => {

        }}
        onReactionsClicked={() => {

        }}
      />

      <div style={{ paddingBottom: '6px' }}></div>

      {post.recentComments.length > 0 && <div style={{ display: 'flex', margin: '0px 5px' }}>
        <div style={{ width: '24px', height: '24px', marginRight: '10px' }}>
          <IonAvatar slot="start" style={{ width: '48px', height: '48px' }} onClick={() => navContext.navigate(`/profile/${post.recentComments[0].creator.username}`)}>
            <img src="https://storage.modclub.social/images/879950a2-bfbe-4879-9aa9-9fb8881c2807/055d4d93-9bcb-4e84-a59a-6a3d034bc076-sm" />
          </IonAvatar>
        </div>
        <Container onClick={() => navContext.navigate(`/post/${post.id}/comments`)} margin='10px 0' padding='8px' dropShadow roundedCorners width={{ units: '%', value: 100 }} maxWidth={{ units: 'vw', value: 80 }} cssClasses="ion-text-wrap" color='light'>
          <IonText><strong>{post.recentComments[0].creator.username}</strong></IonText>
          <div
            style={{ padding: '10px 20px', width: '100%', height: '100%' }}>
            {post.recentComments[0].content && <span className='ion-text-wrap max-lines-3'>{post.recentComments[0].content}</span>}
            {/* {post.mostRecentComment.photoIds.length > 0 && <AppPhoto photo={post.mostRecentComment.photos[0]} size='xs' />} */}
            {/* {post.mostRecentComment.content.slice(0, 200)}
              {post.mostRecentComment.content.length > 200 && <span>...</span>} */}
          </div>
        </Container>
      </div>}

      <div style={{ display: 'flex', height: '100%', justifyContent: 'space-evenly' }}>

        <ReactionsButton width="33%" authenticatedUserReaction={undefined} onReactionClicked={(reaction) => {

        }} />


        <IonButton fill="clear" size="large" color="primary"
          onClick={() => {

          }}
          style={{ width: '33%', height: '58px' }}
        >
          <div style={{ textAlign: 'center' }}>
            <IonIcon icon={chatboxOutline} style={{ fontSize: '25px', paddingBottom: '4px' }}></IonIcon>
            {/* <br /> */}
            <div style={{ fontSize: '14px' }}>Comment</div>
          </div>
        </IonButton>

        <IonButton fill="clear" color="primary" size="large"
          onClick={() => {

          }}
          style={{ width: '33%', height: '58px' }}
        >
          <div style={{ textAlign: 'center' }}>
            <IonIcon color={post ? 'danger' : 'primary'} icon={post ? heart : heartOutline} style={{ fontSize: '25px', paddingBottom: '4px' }}></IonIcon>
            <div style={{ fontSize: '14px' }}>Favorite</div>
          </div>
        </IonButton>

      </div>
      {/* 
        {post.comments.length > 0 &&
          <Container cssClasses="ion-padding-start ion-padding-end">
            <RecentCommentCard comment={{ ...post.comments[0] }} />
          </Container>
        } */}

    </Container>

    {deleteAlertShowing === true &&
      <IonAlert
        isOpen={true}
        message="Are you sure you want to delete this post and all its comments?  This cannot be undone."
        buttons={[{
          text: 'No',
          handler: () => setDeleteAlertShowing(false)
        }, {
          text: 'Yes',
          handler: async () => {
            try {
              // await removePost({ postId: post.id, authContext: auth.context });
            } catch (error) {
              if (error instanceof Error)
                handleError(error);
            }
          }
        }]}
      />
    }

    {actionSheetShowing && <IonActionSheet
      isOpen={true}
      onDidDismiss={() => {
        if (actionSheetShowing === true) {
          setActionSheetShowing(false);
        }
      }}
      buttons={[...getActionSheetButtons()]}
    >
    </IonActionSheet>}

    {isEditing && <AddEditPostModal existingPost={post} isOpen onSaved={() => setIsEditing(false)} onCanceled={() => setIsEditing(false)} />}

  </Container>
});