import * as React from 'react';
import { IonItem, IonLabel, IonIcon, IonListHeader, NavContext, IonNote, IonText } from '@ionic/react';
import { createOutline, heartOutline, trashOutline } from 'ionicons/icons';
import { VehicleMod } from '../models/database/user-created/vehicle-mod';
import { ScrollableTagsBar } from './scrollable-tags-bar';
import { useAuth } from '../hooks/use-auth';

type VehicleModsListProps = {
  isEditable?: boolean;
  mods: VehicleMod[];
  onDeleteClicked: (modId: string) => any;
  onEditClicked: (mod: VehicleMod) => any;
}
export const VehicleModsList: React.FC<VehicleModsListProps> = ({ isEditable, mods, onDeleteClicked, onEditClicked }) => {
  const auth = useAuth();
  const navContext = React.useContext(NavContext);
  function VehicleModListItemButtons(mod: VehicleMod) {
    if (auth.context?.user.id === mod.createdBy) {
      if (!isEditable) {
        return null;
      }
      return <>
        <IonIcon slot="end" icon={createOutline} onClick={() => {
          onEditClicked(mod);
        }} />
        <IonIcon slot="end" icon={trashOutline} onClick={() => {
          onDeleteClicked(mod.id)
        }} />
      </>
    } else {
      // Logged in so can favorite
      return <>
        <IonIcon slot="end" icon={heartOutline} onClick={() => {
          console.log('Favorite');
        }} />
      </>
    }
  }

  return <div style={{ borderBottom: '1px solid #ececec' }}>
    <div style={{ minHeight: '60px', paddingBottom: '4px' }}>
      <IonListHeader mode="md" color="light" style={{ fontSize: '100%', paddingBottom: '5px' }}>
        Exterior
      </IonListHeader>
      {mods.filter(m => m.type === 'Exterior').length === 0 && <div className="ion-padding"><IonNote>None</IonNote></div>}

      {mods.filter(m => m.type === 'Exterior').map((mod) => <IonItem key={mod.id} detail={isEditable === true ? false : true} >
        {/* {mod.photoIds.length > 0 && <AppPhoto size='xs' photoId={mod.photoIds[0]} />} */}
        <IonLabel className='ion-text-wrap' style={{ marginLeft: '8px' }} class="ion-text-wrap"><strong>{mod.brand}</strong> <em>{mod.partName}</em>
          <br />
          {!mod.isCurrent && <IonText color="medium"><em>No longer own</em></IonText>}
          <ScrollableTagsBar tags={mod.tags} />
        </IonLabel>
        {VehicleModListItemButtons(mod)}
      </IonItem>)}
    </div>

    <div style={{ minHeight: '60px', paddingBottom: '4px' }}>
      <IonListHeader mode="md" color="light" style={{ fontSize: '100%' }}>
        Interior
      </IonListHeader>
      {mods.filter(m => m.type === 'Interior').length === 0 && <div className="ion-padding"><IonNote>None</IonNote></div>}
      {mods.filter(m => m.type === 'Interior').map((mod) => <IonItem key={mod.id} detail={isEditable === true ? false : true}  >
        {/* {mod.photoIds.length > 0 && <AppPhoto size='xs' photoId={mod.photoIds[0]} />} */}
        <IonLabel style={{ marginLeft: '8px' }} class="ion-text-wrap"><strong>{mod.brand}</strong> <em>{mod.partName}</em>
          <br />
          {!mod.isCurrent && <IonText color="medium"><em>No longer own</em></IonText>}
          <ScrollableTagsBar tags={mod.tags} />
        </IonLabel>
        <IonIcon slot="end" icon={createOutline} onClick={() => {
          onEditClicked(mod);
        }} />
        <IonIcon slot="end" icon={trashOutline} onClick={() => {
          onDeleteClicked(mod.id)
        }} />
      </IonItem>)}
    </div>

    <div style={{ minHeight: '60px', paddingBottom: '4px' }}>
      <IonListHeader mode="md" color="light" style={{ fontSize: '100%' }}>
        Wheels and Tires
      </IonListHeader>
      {mods.filter(m => m.type === 'Wheels and Tires').length === 0 && <div className="ion-padding"><IonNote>None</IonNote></div>}
      {mods.filter(m => m.type === 'Wheels and Tires').map((mod) => <IonItem key={mod.id} detail={isEditable === true ? false : true}  >
        {/* {mod.photoIds.length > 0 && <AppPhoto size='xs' photoId={mod.photoIds[0]} />} */}
        <IonLabel style={{ marginLeft: '8px' }} class="ion-text-wrap"><strong>{mod.brand}</strong> <em>{mod.partName}</em>
          <br />
          {!mod.isCurrent && <IonText color="medium"><em>No longer own</em></IonText>}
          <ScrollableTagsBar tags={mod.tags} />
        </IonLabel>
        <IonIcon slot="end" icon={createOutline} onClick={() => {
          onEditClicked(mod);
        }} />
        <IonIcon slot="end" icon={trashOutline} onClick={() => {
          onDeleteClicked(mod.id)
        }} />

      </IonItem>)}
    </div>

    <div style={{ minHeight: '60px', paddingBottom: '4px' }}>
      <IonListHeader mode="md" color="light" style={{ fontSize: '100%' }}>
        Intake and Exhaust
      </IonListHeader>
      {mods.filter(m => m.type === 'Intake and Exhaust').length === 0 && <div className="ion-padding"><IonNote>None</IonNote></div>}
      {mods.filter(m => m.type === 'Intake and Exhaust').map((mod) => <IonItem key={mod.id} detail={isEditable === true ? false : true}  >
        {/* {mod.photoIds.length > 0 && <AppPhoto size='xs' photoId={mod.photoIds[0]} />} */}
        <IonLabel style={{ marginLeft: '8px' }} class="ion-text-wrap"><strong>{mod.brand}</strong> <em>{mod.partName}</em>
          <br />
          {!mod.isCurrent && <IonText color="medium"><em>No longer own</em></IonText>}
          <ScrollableTagsBar tags={mod.tags} />
        </IonLabel>
        <IonIcon slot="end" icon={createOutline} onClick={() => {
          onEditClicked(mod);
        }} />
        <IonIcon slot="end" icon={trashOutline} onClick={() => {
          onDeleteClicked(mod.id)
        }} />

      </IonItem>)}
    </div>

    <div style={{ minHeight: '60px', paddingBottom: '4px' }}>
      <IonListHeader mode="md" color="light" style={{ fontSize: '100%' }}>
        Suspension and Handling
      </IonListHeader>
      {mods.filter(m => m.type === 'Suspension and Handling').length === 0 && <div className="ion-padding"><IonNote>None</IonNote></div>}
      {mods.filter(m => m.type === 'Suspension and Handling').map((mod) => <IonItem key={mod.id} detail={isEditable === true ? false : true}  >
        {/* {mod.photoIds.length > 0 && <AppPhoto size='xs' photoId={mod.photoIds[0]} />} */}
        <IonLabel style={{ marginLeft: '8px' }} class="ion-text-wrap"><strong>{mod.brand}</strong> <em>{mod.partName}</em>
          <br />
          {!mod.isCurrent && <IonText color="medium"><em>No longer own</em></IonText>}
          <ScrollableTagsBar tags={mod.tags} />
        </IonLabel>
        <IonIcon slot="end" icon={createOutline} onClick={() => {
          onEditClicked(mod);
        }} />
        <IonIcon slot="end" icon={trashOutline} onClick={() => {
          onDeleteClicked(mod.id)
        }} />

      </IonItem>)}
    </div>

    <div style={{ minHeight: '60px', paddingBottom: '4px' }}>
      <IonListHeader mode="md" color="light" style={{ fontSize: '100%' }}>
        Engine
      </IonListHeader>
      {mods.filter(m => m.type === 'Engine').length === 0 && <div className="ion-padding"><IonNote>None</IonNote></div>}
      {mods.filter(m => m.type === 'Engine').map((mod) => <IonItem key={mod.id} detail={isEditable === true ? false : true}  >
        {/* {mod.photoIds.length > 0 && <AppPhoto size='xs' photoId={mod.photoIds[0]} />} */}
        <IonLabel style={{ marginLeft: '8px' }} class="ion-text-wrap"><strong>{mod.brand}</strong> <em>{mod.partName}</em>
          <br />
          {!mod.isCurrent && <IonText color="medium"><em>No longer own</em></IonText>}
          <ScrollableTagsBar tags={mod.tags} />
        </IonLabel>
        <IonIcon slot="end" icon={createOutline} onClick={() => {
          onEditClicked(mod);
        }} />
        <IonIcon slot="end" icon={trashOutline} onClick={() => {
          onDeleteClicked(mod.id)
        }} />

      </IonItem>)}
    </div>

    <div style={{ minHeight: '60px', paddingBottom: '4px' }}>
      <IonListHeader mode="md" color="light" style={{ fontSize: '100%' }}>
        Forced Induction
      </IonListHeader>

      {mods.filter(m => m.type === 'Forced Induction').length === 0 && <div className="ion-padding"><IonNote>None</IonNote></div>}

      {mods.filter(m => m.type === 'Forced Induction').map((mod) => <IonItem key={mod.id} detail={isEditable === true ? false : true}  >
        {/* {mod.photoIds.length > 0 && <AppPhoto size='xs' photoId={mod.photoIds[0]} />} */}
        <IonLabel style={{ marginLeft: '8px' }} class="ion-text-wrap"><strong>{mod.brand}</strong> <em>{mod.partName}</em>
          <br />
          {!mod.isCurrent && <IonText color="medium"><em>No longer own</em></IonText>}
          <ScrollableTagsBar tags={mod.tags} />
        </IonLabel>
        <IonIcon slot="end" icon={createOutline} onClick={() => {
          onEditClicked(mod);
        }} />
        <IonIcon slot="end" icon={trashOutline} onClick={() => {
          onDeleteClicked(mod.id)
        }} />

      </IonItem>)}
    </div>

    <div style={{ minHeight: '60px', paddingBottom: '4px' }}>
      <IonListHeader mode="md" color="light" style={{ fontSize: '100%' }}>
        Safety
      </IonListHeader>

      {mods.filter(m => m.type === 'Safety').length === 0 && <div className="ion-padding"><IonNote>None</IonNote></div>}

      {mods.filter(m => m.type === 'Safety').map((mod) => <IonItem key={mod.id} detail={isEditable === true ? false : true}  >
        {/* {mod.photoIds.length > 0 && <AppPhoto size='xs' photoId={mod.photoIds[0]} />} */}
        <IonLabel style={{ marginLeft: '8px' }} class="ion-text-wrap"><strong>{mod.brand}</strong> <em>{mod.partName}</em>
          <br />
          {!mod.isCurrent && <IonText color="medium"><em>No longer own</em></IonText>}
          <ScrollableTagsBar tags={mod.tags} />
        </IonLabel>
        <IonIcon slot="end" icon={createOutline} onClick={() => {
          onEditClicked(mod);
        }} />
        <IonIcon slot="end" icon={trashOutline} onClick={() => {
          onDeleteClicked(mod.id)
        }} />

      </IonItem>)}
    </div>

    <div style={{ minHeight: '60px', paddingBottom: '4px' }}>
      <IonListHeader mode="md" color="light" style={{ fontSize: '100%' }}>
        Other
      </IonListHeader>

      {mods.filter(m => m.type === 'Other').length === 0 && <div className="ion-padding"><IonNote>None</IonNote></div>}


      {mods.filter(m => m.type === 'Other').map((mod) => <IonItem key={mod.id} detail={isEditable === true ? false : true}  >
        {/* {mod.photoIds.length > 0 && <AppPhoto size='xs' photoId={mod.photoIds[0]} />} */}
        <IonLabel style={{ marginLeft: '8px' }} class="ion-text-wrap"><strong>{mod.brand}</strong> <em>{mod.partName}</em>
          <br />
          {!mod.isCurrent && <IonText color="medium"><em>No longer own</em></IonText>}
          <ScrollableTagsBar tags={mod.tags} />
        </IonLabel>
        <IonIcon slot="end" icon={createOutline} onClick={() => {
          onEditClicked(mod);
        }} />
        <IonIcon slot="end" icon={trashOutline} onClick={() => {
          onDeleteClicked(mod.id)
        }} />

      </IonItem>)}
    </div>
  </div>
};