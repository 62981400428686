import * as React from 'react';
import { IonToolbar, IonButton, IonIcon, IonList, IonListHeader, IonAlert, IonActionSheet, NavContext, ActionSheetButton } from '@ionic/react';
import { carSportOutline, documentTextOutline, shareOutline, trashOutline, close, add } from 'ionicons/icons';
import { handleError } from '../helpers';
import { Vehicle } from '../models/database/user-created/vehicle';
import { AppPhoto } from './app-photo';
import { AppUserToolbar } from './app-user-toolbar';
import { useAuth } from '../hooks/use-auth';

export const VehicleDetail: React.FC<{ vehicle: Vehicle, showRecentMods?: boolean }> = ({ vehicle, showRecentMods }) => {
  const auth = useAuth();
  const navContext = React.useContext(NavContext);

  const [actionSheetShowing, setActionSheetShowing] = React.useState(false);
  const [removeVehicleAlertShowing, setRemoveVehicleAlertShowing] = React.useState(false);

  function handleEllipsisClicked() {
    setActionSheetShowing(true);
  }

  function handleAddModClicked() {
    navContext.navigate(`/edit-vehicle/${vehicle.id}/add-mod`);

  }

  async function handleRemoveVehicleClicked() {
    try {
      // TODO prompt first

    } catch (error) {
      if (error instanceof Error)
        handleError(error);
    }
  }

  function getActionSheetButtons(): ActionSheetButton[] {
    if (auth.context?.user.id === vehicle.createdBy) {
      return [
        {
          text: ' Share ',
          icon: shareOutline,
          handler: () => {
            console.log('Share clicked');
            // share({ appState.currentUser: store.appState.currentUser, url: 'http://localhost:3000/garage' }).then(() => console.log('Shared')).catch(() => createToast('There was an error sharing.'));
          }
        },
        {
          text: ' Edit Vehicle ',
          icon: carSportOutline,
          handler: () => {
            setActionSheetShowing(false);
            setTimeout(() => {
              navContext.navigate(`/edit-vehicle/${vehicle.id}`, 'forward', 'push');
            }, 100);
          }
        },
        {
          text: 'Delete Vehicle',
          role: 'destructive',
          icon: trashOutline,
          handler: () => {
            console.log('Delete clicked');
            setRemoveVehicleAlertShowing(true);
          }
        },
        {
          text: 'Cancel',
          icon: close,
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ];
    } else {
      return [
        {
          text: 'Share',
          icon: shareOutline,
          handler: () => {
            console.log('Share clicked');
            //share({ appState.currentUser: appState.global.appState.currentUser, url: 'http://localhost:3000/garage' }).then(() => console.log('Shared')).catch(() => createToast('There was an error sharing.'));
          }
        },
        {
          text: 'Report',
          icon: documentTextOutline,
          handler: () => {
            console.log('Rpeort clicked');
          }
        },
        {
          text: 'Cancel',
          icon: close,
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ];
    }
  }

  function getExteriorColor(): string {
    if (vehicle) {
      if (vehicle.exteriorColorName) {
        return vehicle.exteriorColorName;
      } else if (vehicle.exteriorColorBasic) {
        return vehicle.exteriorColorBasic;
      }
    }
    return 'Exterior Color Not Specified';
  }

  function getInteriorColor(): string {
    if (vehicle) {
      if (vehicle.interiorColorName) {
        return vehicle.interiorColorName;
      } else if (vehicle.interiorColorBasic) {
        return vehicle.interiorColorBasic;
      }
    }
    return 'Interior Color Not Specified';
  }

  function openVehicleDetailView() {
    navContext.navigate(`/view-vehicle/${vehicle.id}`, 'forward', 'push');
  }
  return <>
    <AppUserToolbar userId={vehicle.createdBy} username={vehicle.creator.username} handleEllipsisClicked={() => handleEllipsisClicked()} />

    {/* {vehicle.photoIds.length > 0 &&
            <AppPhoto photoId={vehicle.photoIds[0]} />
        } */}

    <IonToolbar color="white" style={{ paddingLeft: '10px', paddingTop: '10px' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <h2 style={{ fontSize: '110%', fontWeight: 'bold', margin: 0 }}>{vehicle.year} {vehicle.make} {vehicle.model} {vehicle.trim}</h2>
        {auth.context?.user.id === vehicle.creator.id &&
          <IonButton style={{ height: '24px', fontSize: '90%' }} color="primary" fill="solid" size='small' onClick={() => handleAddModClicked()}>
            <IonIcon icon={add}></IonIcon> Add Mod
          </IonButton>
        }
      </div>
      {/* <IonCardSubtitle style={{ fontSize: '70%' }}>{getExteriorColor()} / {getInteriorColor()}</IonCardSubtitle> */}
      <h6 style={{ margin: 0, fontSize: '80%', fontWeight: 'bold' }}>{getExteriorColor()} / {getInteriorColor()}</h6>
    </IonToolbar>

    <div style={{ padding: '0 !important', margin: '0 !important' }} onClick={() => openVehicleDetailView()}>
      <div hidden={!vehicle.description} style={{ fontSize: '80%' }} className="ion-text-wrap ion-padding">
        {vehicle.description.slice(0, 200)}
      </div>
    </div>

    {/* {
            vehicle.photoIds.length >= 2 && <div onClick={() => openVehicleDetailView()} style={{ padding: '5px', display: 'flex', flexWrap: 'nowrap', justifyContent: 'flex-start', alignItems: 'center' }}>
                {vehicle.photoIds.slice(1, 5).map(photoId => <div key={photoId} style={{ width: '25%', minWidth: '25%', padding: '3px' }}><AppPhoto size='sm' photoId={photoId} /></div>)}
            </div>
        } */}

    {showRecentMods &&

      <IonList lines="inset" className="ion-padding-bottom">
        <IonListHeader mode="ios" style={{ fontSize: '12pt', minHeight: '30px', maxHeight: '30px' }}>Recent Mods</IonListHeader>
        {/* {vehicleMods.filter(m => m.isCurrent).slice(0, 3).map((mod, index) => <VehicleModListItem key={index} vehicleId={vehicle.id} lines='inset' mod={mod} />)} */}
      </IonList>

    }

    {removeVehicleAlertShowing === true &&
      <IonAlert
        isOpen={true}
        onWillDismiss={() => setRemoveVehicleAlertShowing(true)}
        message="Are you sure you want to delete this vehicle and all its mods?  This cannot be undone."
        buttons={['No', {
          text: 'Yes',
          handler: () => {
            handleRemoveVehicleClicked();
          }
        }]}
      />
    }

    <IonActionSheet
      isOpen={actionSheetShowing}
      onDidDismiss={() => {
        if (actionSheetShowing === true) {
          setActionSheetShowing(false);
        }
      }}
      buttons={[...getActionSheetButtons()]}
    >
    </IonActionSheet>
  </>
}