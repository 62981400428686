import * as React from 'react';
import { IonHeader, useIonViewWillEnter } from '@ionic/react';

export const ViewUserFavoritesPage: React.FC = () => {
  useIonViewWillEnter(() => {
    
  });

  return (
    <IonHeader>

    </IonHeader>
  );

};
