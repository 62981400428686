import { TypedEvent } from "../models/app/typed-event";
import {AuthContext} from '../models/app/auth-context';
import { HttpProvider } from "../providers/http-provider";
import { User } from "../models/database/system-created/user";

export class AuthService {

    private _pendingAuthToken: string | null = null;
    private _authContext: AuthContext | null;

    public constructor(private _http: HttpProvider) {
        const authContextStr = localStorage.getItem('auth-context');
        if (authContextStr) {
            this._authContext = JSON.parse(authContextStr);
        } else {
            this._authContext = null;
        }
        (window as any).AppleID.auth.init({
            clientId: 'social.modclub.auth',
            scope: 'name email',
            redirectURI: 'https://api.modclub.social/initiate-apple-auth',
            state: 'lastUrl:' + window.location.pathname,
            nonce: '',
            usePopup: false //or false defaults to false
        });
     }

    public async initialize() {
        this._authContextChanged.emit();
    }

    public get authContext(): Readonly<AuthContext> | null {
        return this._authContext;
    }

    private _authContextChanged = new TypedEvent<void>();

    public get authContextChanged(): Pick<TypedEvent<void>, 'off' | 'on' | 'once'> {
        return this._authContextChanged;
    }

    public async initiatePasswordlessEmailAuth(email: string) {
        const response = await this._http.post<{pendingAuthToken: string}>('/initiate-passwordless-email-auth', { email });
        this._pendingAuthToken = response.pendingAuthToken;
    }

    public async verifyPasswordlessEmailAuth(email: string, authCode: number): Promise<void> {
        if (!this._pendingAuthToken) {
            throw new Error();
        }
        const response = await this._http.post<{ authToken: string, user: User }>('/verify-passwordless-email-auth', { email, authCode }, { 'pending-auth-token': this._pendingAuthToken });
        this._authContext = {
            authToken: response.authToken,
            user: response.user
        };
        localStorage.setItem('auth-context', JSON.stringify(this._authContext));
        this._authContextChanged.emit();
    }

    public async  initiateAppleAuth() {
        await (window as any).AppleID.auth.signIn();
    }

    public async verifyAppleAuth(appleUserId: string) {
        if (!this._pendingAuthToken) {
            throw new Error();
        }
        const response = await this._http.post<{ authToken: string, user: User }>('/verify-apple-auth', { appleUserId }, { 'pending-auth-token': this._pendingAuthToken });
        this._authContext = {
            authToken: response.authToken,
            user: response.user
        };
        localStorage.setItem('auth-context', JSON.stringify(this._authContext));
        this._authContextChanged.emit();
    }

    public async logOut() {
        localStorage.removeItem('auth-context');
        this._authContext = null;
        this._authContextChanged.emit();
    }
}