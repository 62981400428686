import { v4 } from "uuid";
import { TypedEvent } from "../models/app/typed-event";
import { Post, PostDetails } from "../models/database/user-created/post";
import { ClientStore } from "../models/supporting/client-store";

export class PostsService {
    private _postsStore: ClientStore = new ClientStore('posts');
    private _posts: Post[] = [];
    private _postsLastRefreshed: Date | null;

    public get posts(): ReadonlyArray<Post> {
        return this._posts;
    }

    public postsChanged = new TypedEvent<void>();

    public constructor() {
        const lastRefreshedStr = localStorage.getItem('posts-last-refreshed');
        if (lastRefreshedStr) {
            this._postsLastRefreshed = new Date(lastRefreshedStr);
        } else {
            this._postsLastRefreshed = null;
        }
        setTimeout(() => {
            console.log('PostsService :: constructor setTimeout');
            this.add({
                content: 'Added in constructor',
                photos: [],
                privacy: 'public',
                tags: {}
            }).then(newPost => {
                setTimeout(() => {
                    this.update(newPost.id, { content: 'Updated in constructor' }).then(updatedPost => {
                        setTimeout(() => {
                            //this.remove(updatedPost.id);
                        }, 3000);
                    })
                }, 2000);
            });
            this.add({
                content: 'Added in constructor',
                photos: [],
                privacy: 'public',
                tags: {}
            });
            this.add({
                content: 'Added in constructor',
                photos: [],
                privacy: 'public',
                tags: {}
            });
            this.add({
                content: 'Added in constructor',
                photos: [],
                privacy: 'public',
                tags: {}
            });
            this.add({
                content: 'Added in constructor',
                photos: [],
                privacy: 'public',
                tags: {}
            });
            this.add({
                content: 'Added in constructor',
                photos: [],
                privacy: 'public',
                tags: {}
            });
            this.add({
                content: 'Added in constructor',
                photos: [],
                privacy: 'public',
                tags: {}
            });
            this.add({
                content: 'Added in constructor',
                photos: [],
                privacy: 'public',
                tags: {}
            });
            this.add({
                content: 'Added in constructor',
                photos: [],
                privacy: 'public',
                tags: {}
            });
            this.add({
                content: 'Added in constructor',
                photos: [],
                privacy: 'public',
                tags: {}
            });
        }, 4000);
    }

    public async initialize(): Promise<void> {
        await this._postsStore.initialize();
        let results = await this._postsStore.fetchArray<Post>(1, 3);
        this._posts = [...results];
        this.postsChanged.emit();
        results = [];
    }

    public async getById(postId: string): Promise<Post | null> {
        const local = this.posts.find(p => p.id === postId);
        if (local) {
            return local;
        }
        return null;
    }

    public async add(postDetails: PostDetails): Promise<Post> {
        const newPost: Post = {
            ...postDetails,
            id: v4(),
            created: new Date(),
            createdBy: 'abc',
            creator: {
                id: 'abc',
                username: 'abcman'
            },
            lastModified: new Date(),
            numComments: 0,
            numReactions: 0,
            numSaves: 0,
            numShares: 0,
            reactions: {},
            recentComments: [],
            saves: {}
        };
        this._posts.unshift(newPost);
        // console.log('UTC ' + newPost.created.toUTCString());
        // console.log('ISO ' + newPost.created.toISOString());
        // console.log('Date String ' + newPost.created.toDateString());
        // console.log('Time String ' + newPost.created.toTimeString());
        // console.log(newPost.created.getTime());
        // console.log(newPost.created.getDate());
        // this._postsStore.storeItem(Number(-newPost.created.getTime()).toString(), newPost).then(() => {
        //     console.log('Success storing locally')
        // }).catch(error => {
        //     console.log('Error storing locally')
        // });
        this.postsChanged.emit();
        return newPost;
    }

    public async update(postId: string, postUpdates: Partial<PostDetails>): Promise<Post> {
        const index = this._posts.findIndex(p => p.id === postId);
        if (index === -1) {
            throw new Error();
        }
        this._posts[index] = { ...this._posts[index], ...postUpdates };
        this.postsChanged.emit();
        return this._posts[index];
    }

    public async remove(postId: string): Promise<Post> {
        const index = this._posts.findIndex(p => p.id === postId);
        if (index === -1) {
            throw new Error();
        }
        const removedPost = { ...this._posts[index] };
        this._posts.splice(index, 1);
        this.postsChanged.emit();
        return removedPost;
    }
}