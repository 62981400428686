// import { Plugins, CameraResultType } from '@capacitor/core';
import imageCompression from 'browser-image-compression';
import { v4 } from 'uuid';
import { appConfig } from '../constants';
import { Photo } from '../models/database/supporting/photo';
import { AuthenticationException, NetworkException, ValidationException } from '../models/supporting/exceptions';
import { MutationsSyncService, mutationsSyncService } from './mutations-sync-service';
import { networkService, NetworkService } from './network-service';
import { photoFilesStore, PhotoFilesStore } from '../stores/photo-files-store';

// const { Camera } = Plugins;
/*
  choose 2 photos
  save to file system then
  create 2 Photo objs then
  if network upload to server and upldate Photo objs
  else do later

  vs.

  choose 2 photos
  upload to server and get back array of uris
  create Photo obj for each uri
*/

export class PhotosService {

  public constructor(
    private _photoFilesStore: PhotoFilesStore,
    private _networkService: NetworkService,
    private _mutationsSyncService: MutationsSyncService
  ) { }

  public async getPhotoLocalUri(photoId: string): Promise<string | null> {
    return this._photoFilesStore.getPhotoFileAsUrl(photoId);
  }

  public async choosePhotos(): Promise<(Photo & { file: File })[]> {

    // this.uploadsService.chooseImages();
    const filesList = await new Promise<FileList | null>((resolve, reject) => {
      const fileEl = document.createElement('input');
      fileEl.type = 'file';
      fileEl.multiple = true;
      fileEl.accept = 'image/jpeg,image/jpg,image/png';
      fileEl.click();
      fileEl.oninput = async (evt) => {
        console.log(evt);
        console.log(fileEl.files);
        let maxFiles = 10;
        if (fileEl.files && fileEl.files.length > maxFiles) {
          reject(new ValidationException(['There is a limit of ' + maxFiles + ' files']));
        }
        if (fileEl.files) {
          if (Array.from(fileEl.files).some(f => !['image/jpeg', 'image/jpg', 'image/png'].includes(f.type))) {
            reject(new ValidationException(['Only .jpg/jpeg and .png images are allowed']));
          }
        }
        resolve(fileEl.files);
      }
    });
    console.log('Chose photos');
    if (!filesList) {
      throw new Error();
    }

    const newPhotos: (Photo & { file: File })[] = [];

    for (let file of filesList) {
      console.log(file.size);
      console.log(file.type);
      //   await this.clientStorageService.storeItem<Blob>({ storeName: 'photo-storage', key: photoId, value: file.slice(0, file.size, file.type) });

      newPhotos.push({
        id: v4(),
        created: new Date(),
        createdBy: '',
        album: [],
        caption: '',
        localUri: URL.createObjectURL(file.slice(0, file.size, file.type)),
        numComments: 0,
        numFavorites: 0,
        numReactions: 0,
        file
      });
      console.log(newPhotos[newPhotos.length - 1].localUri);
      const imgEl = new Image();
      imgEl.src = newPhotos[newPhotos.length - 1].localUri!;
      document.body.append(imgEl);
    }
    return newPhotos;
  }

  uploadPhotos(photos: (Photo & { file: File })[]): void {
    console.log('uploadPhotos invoked');
    photos.forEach(p => {
      console.log('Adding it to store')
      this._photoFilesStore.addPhotoFile(p.id, p.file);
      const fd = new FormData();
      this.compressPhoto(p.file).then(original => {
        fd.append('photo', original);
        console.log('Adding mutation')
        this._mutationsSyncService.addMutation({
          mutation: 'add-photo-upload',
          type: 'http',
          method: 'POST',
          endpoint: `${appConfig.uploadBaseUrl}/photos/${p.id}`,
          body: fd,
          headers: { 'auth-token': '' }
        });
      }).catch((error) => {
        console.error('Error compressing photo.');
        console.log(error);
      });
    });

  }

  public deletePhoto(photoId: string) {
    this._photoFilesStore.removePhotoFile(photoId);
    this._mutationsSyncService.addMutation({
      mutation: 'delete-photo-upload',
      type: 'http',
      method: 'DELETE',
      endpoint: `${appConfig.uploadBaseUrl}/photos/${photoId}`,
      headers: { 'auth-token': '' }
    });
  }

  public deletePhotos(photos: Photo[]) {
    photos.forEach(p => {
      this._photoFilesStore.removePhotoFile(p.id);
      this._mutationsSyncService.addMutation({
        mutation: 'delete-photo-upload',
        type: 'http',
        method: 'DELETE',
        endpoint: `${appConfig.storageBaseUrl}/photos/${p.id}`,
        headers: { 'auth-token': '' }
      });
    });
  }

  private async compressPhoto(file: File, maxWidthOrHeightPx?: number): Promise<File> {
    if (maxWidthOrHeightPx) {
      let result = await imageCompression(file, {
        maxWidthOrHeight: maxWidthOrHeightPx,
        useWebWorker: true
      });
      return result;
    } else {
      let result = await imageCompression(file, {
        maxSizeMB: 0.25,
        useWebWorker: true
      });
      return result;
    }
  }

  private async fileToBlob(file: File): Promise<Blob> {
    return new Blob([await file.arrayBuffer()]);
  }

  private async blobToFile(blob: Blob, fileName: string, fileType: string): Promise<File> {
    return new File([blob], fileName, { type: fileType, lastModified: Date.now() });
  }
}

export const photosService = new PhotosService(photoFilesStore, networkService, mutationsSyncService);