import * as React from 'react';
import { Post } from '../models/database/user-created/post';
import { WebSocketProvider } from '../providers/web-socket-provider';
import { PostCard } from './post-card';

type PostsListProps = {
    posts: readonly Post[];
    ws: WebSocketProvider
}

export const PostsList: React.FC<PostsListProps> = ({ posts, ws }) => {
    return  <>
        { posts.map(post => <PostCard key={post.id} post={post} viewFullDetail={false} ws={ws} />)}
    </>
};