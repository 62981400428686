import { TypedEvent } from "./models/app/typed-event";
import { User } from "./models/database/system-created/user";
import { Post } from "./models/database/user-created/post";
import { PostComment } from "./models/database/user-created/post-comment";
import { PostReaction } from "./models/database/user-created/post-reaction";
import { PostSave } from "./models/database/user-created/post-save";
import { Vehicle } from "./models/database/user-created/vehicle";
import { VehicleMod } from "./models/database/user-created/vehicle-mod";

export type AppConfig = {
    authApiBaseUrl: string;
    postsApiBaseUrl: string;
    vehiclesApiBaseUrl: string;
    usersApiBaseUrl: string;
    webSocketBaseUrl: string;
    storageBaseUrl: string;
    uploadBaseUrl: string;
}

export const appConfig: AppConfig = {
    authApiBaseUrl: window.location.href.includes('localhost') ? 'http://localhost:5000' : 'https://api.modclub.social',
    postsApiBaseUrl: window.location.href.includes('localhost') ? 'http://localhost:5000' : 'https://api.modclub.social',
    vehiclesApiBaseUrl: window.location.href.includes('localhost') ? 'http://localhost:5000' : 'https://api.modclub.social',
    usersApiBaseUrl: window.location.href.includes('localhost') ? 'http://localhost:5000' : 'https://api.modclub.social',
    webSocketBaseUrl: window.location.href.includes('localhost') ? 'http://localhost:5000' : 'https://api.modclub.social',
    storageBaseUrl: 'https://storage.modclub.social',
    uploadBaseUrl: window.location.href.includes('localhost') ? 'http://localhost:5001' : 'https://api.modclub.social'
}

export type Mutation = {
    mutation: Mutations,
    type: 'http',
    endpoint: string,
    headers?: { [key: string]: any },
    method: 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTIONS',
    body?: { [key: string]: any } | FormData
};

export type HttpMutation = {

}

export type WebSocketMutation = {

}

export type Mutations = 'add-photo-upload' |
    'delete-photo-upload' |
    'add-post' |
    'update-post' |
    'remove-post' |
    'add-post-comment' |
    'update-post-comment' |
    'remove-post-comment' |
    'add-post-favorite' |
    'remove-post-favorite' |
    'add-post-reaction' |
    'update-post-reaction' |
    'remove-post-reaction' |
    'add-vehicle' |
    'update-vehicle' |
    'remove-vehicle' |
    'add-vehicle-mod' |
    'update-vehicle-mod' |
    'remove-vehicle-mod' |
    'add-photo' |
    'update-photo' |
    'remove-photo'
    ;
export const mutationFailedEvent = new TypedEvent<Mutation>();

export const unauthenticatedBaseUrl = window.location.href.includes('localhost') ? 'http://localhost:5000/api' : '';
export const authenticatedBaseUrl = window.location.href.includes('localhost') ? 'http://localhost:5000/api' : '';

export const keyCodes = {
    COMMA: 188,
    SPACE: 32,
    ENTER: 13
};

export const validParentTypes = ['post', 'vehicle', 'user', 'vehicle-mod', 'comment', 'chat', 'chat-message', 'item-for-sale'];

export type ReactionOptions = 'likes' | 'dislikes' | 'money' | 'haha' | 'wow' | 'confused' | 'idea';
export const validReactionOptions = ['likes', 'dislikes', 'money', 'haha', 'wow', 'confused', 'idea'];

export type ModTypes = 'Wheels and Tires' | 'Exterior' | 'Interior' | 'Engine' | 'Intake and Exhaust' | 'Suspension and Handling' | 'Forced Induction' | 'Other' | 'Safety';
export const validModTypes = [
    'Wheels and Tires', 'Exterior', 'Interior', 'Engine', 'Intake and Exhaust', 'Suspension and Handling', 'Forced Induction', 'Other', 'Safety'
];

export const AppActions = {
    'ADD_POST': 'ADD_POST',
    'UPDATE_POST': 'UPDATE_POST',
    'REMOVE_POST': 'REMOVE_POST',
    'ADD_ITEM_FOR_SALE': 'ADD_ITEM_FOR_SALE',
    'UPDATE_ITEM_FOR_SALE': 'UPDATE_ITEM_FOR_SALE',
    'REMOVE_ITEM_FOR_SALE': 'REMOVE_ITEM_FOR_SALE',
    'ADD_VEHICLE': 'ADD_VEHICLE',
    'UPDATE_VEHICLE': 'UPDATE_VEHICLE',
    'REMOVE_VEHICLE': 'REMOVE_VEHICLE',
    'ADD_REACTION': 'ADD_REACTION',
    'UPDATE_REACTION': 'UPDATE_REACTION',
    'REMOVE_REACTION': 'REMOVE_REACTION',
    'ADD_FAVORITE': 'ADD_FAVORITE',
    'REMOVE_FAVORITE': 'REMOVE_FAVORITE',
    'UPGRADE_MEMBERSHIP': 'UPGRADE_MEMBERSHIP',
    'DOWNGRADE_MEMBERSHIP': 'DOWNGRADE_MEMBERSHIP',
    'LOAD_POSTS': 'LOAD_POSTS',
    'LOAD_MORE_POSTS': 'LOAD_MORE_POSTS',
    'LOAD_USER': 'LOAD_USER',
    'LOAD_GARAGE': 'LOAD_GARAGE',
    'LOAD_ITEMS_FOR_SALE': 'LOAD_ITEMS_FOR_SALE',
    'LOAD_MORE_ITEMS_FOR_SALE': 'LOAD_MORE_ITEMS_FOR_SALE',
    'REFRESH_POSTS': 'REFRESH_POSTS',
    'JOIN': 'JOIN',
    'LOG_IN': 'LOG_IN',
    'SEARCH': 'SEARCH'
};

export type NumVehicleCylinderOptions = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 8 | 10 | 12 | 16;

export enum Emojis {
    CreditCard = 128179,
    OneHundred = 128175,
    Money = 129297,
    Likes = 128512,
    Loves = 128525,
    NoExpression = 128528,
    SideEye = 128530,
    Cry = 128557,
    Cringe = 128556,
    Shocked = 128562,
    Thinking = 129300,
    Laughing = 128518,
    Angry = 128544,
    RaisedEyebrow = 129320,
    CheckeredFlag = 127937,
    Trophy = 127942,
    BlackFlag = 127988,
    ThumbsUp = 128077,
    ThumbsDown = 128078,
    Clapping = 128079,
    Ok = 128076,
    Shit = 128169,
    ChatBubble = 128172,
    CardboardBox = 128230,
    Wrench = 128295,
    Beer = 127866,
    Beers = 127867
};

export enum ClientToServerWebSocketEvents {
    Authenticating = 'authenticating',
    SubscribeToComments = 'subscribe-to-comments',
    SubscribeToFavorites = 'subscribe-to-favorites',
    SubscribeToReactions = 'subscribe-to-reactions',
    RefreshingSelf = 'refreshing-self',
    LogIn = 'log-in',
    VerifyUsernameAvailable = 'verify-username-available',
    LogOut = 'log-out',
    GetPosts = 'get-posts',
    AddPost = 'add-post',
    UpdatePost = 'update-post',
    RemovePost = 'remove-post',
    AddVehicle = 'add-vehicle',
    UpdateVehicle = 'update-vehicle',
    RemoveVehicle = 'remove-vehicle',
    AddVehicleMod = 'add-vehicle-mod',
    UpdateVehicleMod = 'update-vehicle-mod',
    RemoveVehicleMod = 'remove-vehicle-mod',
    AddFavorite = 'add-favorite',
    RemoveFavorite = 'remove-favorite',
    GetFavoritesByEntityId = 'get-favorites-by-entity-id',
    AddReaction = 'add-reaction',
    UpdateReaction = 'update-reaction',
    RemoveReaction = 'remove-reaction',
    GetReactionsByEntityId = 'get-reactions-by-entity-id',
    AddComment = 'add-comment',
    UpdateComment = 'update-comment',
    RemoveComment = 'remove-comment',
    GetCommentsByEntityId = 'get-comments-by-entity-id',
    AddShare = 'add-share',
    AddUserReport = 'add-user-report',
    UpdateUserReport = 'update-user-report',
    RemoveUserReport = 'remove-user-report',
    AddUserSuggestion = 'add-user-suggestion',
    DeleteAccount = 'delete-account',
    Search = 'search',
    BlockUser = 'block-user'
};

export enum ServerToClientWebSocketEvents {
    AddedPost = 'added-post',
    UpdatedPost = 'updated-post',
    RemovedPost = 'removed-post',
    AddedVehicle = 'added-vehicle',
    UpdatedVehicle = 'updated-vehicle',
    RemovedVehicle = 'removed-vehicle',
    AddedVehicleMod = 'added-vehicle-mod',
    UpdatedVehicleMod = 'updated-vehicle-mod',
    RemovedVehicleMod = 'removed-vehicle-mod',
    AddedPostFavorite = 'added-post-favorite',
    RemovedPostFavorite = 'removed-post-favorite',
    AddedPostReaction = 'added-post-reaction',
    UpdatedPostReaction = 'updated-post-reaction',
    RemovedPostReaction = 'removed-post-reaction',
    AddedPostComment = 'added-post-comment',
    UpdatedPostComment = 'updated-post-comment',
    RemovedPostComment = 'removed-post-comment',
    NewNotification = 'new-notification',
    SearchResults = 'search-results',
    Hello = 'hello'
}

export const AppEvents = {
    UserAdded: new TypedEvent<User>(),
    PostAdded: new TypedEvent<Post>(),
    PostUpdated: new TypedEvent<Post>(),
    PostRemoved: new TypedEvent<Post>(),
    VehicleAdded: new TypedEvent<Vehicle>(),
    VehicleUpdated: new TypedEvent<Vehicle>(),
    VehicleRemoved: new TypedEvent<Vehicle>(),
    VehicleModAdded: new TypedEvent<VehicleMod>(),
    VehicleModUpdated: new TypedEvent<VehicleMod>(),
    VehicleModRemoved: new TypedEvent<VehicleMod>(),
    PostSaveAdded: new TypedEvent<PostSave>(),
    PostSaveRemoved: new TypedEvent<PostSave>(),
    PostReactionAdded: new TypedEvent<PostReaction>(),
    PostReactionUpdated: new TypedEvent<PostReaction>(),
    PostReactionRemoved: new TypedEvent<PostReaction>(),
    PostCommentAdded: new TypedEvent<PostComment>(),
    PostCommentUpdated: new TypedEvent<PostComment>(),
    PostCommentRemoved: new TypedEvent<PostComment>()
};