import { Browser } from '@capacitor/browser';

export interface IInAppBrowserService {
    open(url: string): Promise<void>;
    close(): Promise<void>;
}

class InAppBrowserService implements IInAppBrowserService {
    public async open(url: string) {
        await Browser.open({ url });
    }
    public async close() {
        await Browser.close();
    }
}

export const inAppBrowserService: IInAppBrowserService = new InAppBrowserService();