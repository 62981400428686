import localForage from 'localforage';

export class ClientStore {
    private _store: LocalForage;

    public constructor(storeName: string) {
        this._store = localForage.createInstance({ name: 'modclub', storeName });
    }

    public async initialize() {
        await this._store.ready();
    }

    public async storeItem<T>(key: string, value: T) {
        if (value instanceof Blob || value instanceof File) {
            await this._store.setItem<T>(key, value);
        } else {
            await this._store.setItem<T>(key, JSON.parse(JSON.stringify(value)));
        }
    }

    public async fetchItem<T>(key: string): Promise<T | null> {
        return await this._store.getItem<T>(key);
    }

    public async removeItem(key: string): Promise<void> {
        await this._store.removeItem(key);
    }

    public async storeArray<T>(uniqueKey: keyof T, values: T[]) {
        for (let value of values) {
            const key = value[uniqueKey];
            if (typeof key === 'string') {
                await this._store.setItem(key, value);
            }
        }
    }

    public async fetchArray<T>(startAt: number, limit: number, filter?: (value: T) => boolean): Promise<T[]> {
        return new Promise<T[]>(async (resolve, reject) => {
            let values: T[] = [];
            await this._store.iterate<T, void>((current, key, iterationNumber) => {
                console.log(iterationNumber);
                console.log(current);
                if (iterationNumber >= limit + startAt) {
                    return;
                } else {
                    if (iterationNumber >= startAt) {
                        if (!filter || filter(current)) {
                            values.push(current);
                        }
                    }
                }
            });
            resolve(values);
            values = [];
        });
    }

    public async removeStore() {
        await this._store.dropInstance();
    }

    public async addArrayItem<T extends { [key: string]: any }>(uniqueKey: string, value: T) {
        await this._store.setItem(uniqueKey, value);
    }

    public async updateArrayItem<T extends { [key: string]: any }>(uniqueKey: string, value: T) {
        await this._store.setItem(value[uniqueKey], value);
    }

    public async removeArrayItem(uniqueKey: string) {
        await this._store.removeItem(uniqueKey)
    }

    public async getArrayItem<T extends { [key: string]: any }>(uniqueKey: string): Promise<T | null> {
        return await this._store.getItem<T>(uniqueKey);
    }
}