import * as React from 'react';
import {TagsBar} from './tags-bar';

type ScrollableTagsBarProps = {
    tags: {[tag: string]: true};
}
export const ScrollableTagsBar: React.FC<ScrollableTagsBarProps> = ({tags}) => {
    if (!tags || Object.keys(tags).length === 0) {
        return null;
    }
    return <div style={{padding: '10px 5px 10px 20px', display: 'flex', overflow: 'auto'}}>
        <TagsBar tags={tags} />
    </div>
}