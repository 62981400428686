import { io, Socket } from 'socket.io-client';
import { ServerToClientWebSocketEvents, ClientToServerWebSocketEvents } from "../constants";
import { NetworkException } from '../models/supporting/exceptions';
import { AuthService } from '../services/auth-service';

export class WebSocketProvider {
    private _ws: Socket;
    private outgoingQueue: {
        eventName: string,
        payload?: any
    }[] = [];

    public constructor(private _authService: AuthService, private url: string, private query?: { [key: string]: any }, private path?: string) {
        this._ws = io(url, { autoConnect: false, path, query });
        if (this._authService.authContext) {
            this._ws.connect();
        }
        this._authService.authContextChanged.on(() => {
            if (this._authService.authContext) {
                this._ws.open();
                this._ws.connect();
            } else {
                console.log('Disconnecting web socket!!');
                this._ws.disconnect();
                console.log('Closing web socket!!');
                this._ws.close();
            }
        });
    }

    public get connected(): Readonly<boolean> {
        return this._ws.connected;
    }

    public on<T>(event: ServerToClientWebSocketEvents | string, cb: (data: T) => any) {
        console.log('Subscribing to ' + event);
        this._ws.on(event, (data: T) => {
            console.log('Got a ' + event + ' notification');
            cb(data);
        });
    }

    public off(event: ServerToClientWebSocketEvents | string): void {
        if (this._ws) {
            this._ws.off(event);
        }
    }

    public send<Payload extends { [key: string]: any }>(event: ClientToServerWebSocketEvents, payload: Payload | undefined) {
        console.log('Got request to send to ' + event);
        console.log('The payload is: ');
        console.log(payload);

        if (this._ws.connected && this._authService.authContext) {

            if (payload) {
                this._ws.emit(event, { ...payload, authToken: this._authService.authContext.authToken });
            } else {
                this._ws.emit(event, { authToken: this._authService.authContext.authToken });
            }
        }
    }

    public sendWithResponse<Payload extends { [key: string]: any }, Response extends { [key: string]: any }>(event: ClientToServerWebSocketEvents, payload: Payload | undefined): Promise<Response> {
        if (!this._authService.authContext) {
            throw new Error();
        }
        if (!this.connected) {
            throw new NetworkException();
        }
        return new Promise((resolve, reject) => {
            if (payload) {
                this._ws.emit(event, payload, (response: Response) => {
                    return resolve(response);
                });
            } else {
                this._ws.emit(event, (response: Response) => {
                    return resolve(response);
                });
            }
        });
    }

    public addToQueue(event: ClientToServerWebSocketEvents, payload?: any) {
        this.outgoingQueue.push({ eventName: event, payload });
    }
}