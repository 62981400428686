import { ClientStorageService, clientStorageService } from '../services/client-storage-service';

export class PhotoFilesStore {
    public constructor(private _clientStorageService: ClientStorageService) {

    }

    public async getPhotoFileAsUrl(photoId: string): Promise<string | null> {
        try {
            const result = await this._clientStorageService.fetchItem<{ data: Blob; type: string; } | null>({ storeName: 'photo-uploads', key: photoId });
            if (!result) {
                return null;
            }
            return URL.createObjectURL(result.data);
        } catch (e) {
            return null;
        }
    }

    public async getPhotoFileAsBlob(photoId: string): Promise<Blob | null> {
        try {
            const result = await this._clientStorageService.fetchItem<{ data: Blob; type: string; } | null>({ storeName: 'photo-uploads', key: photoId });
            if (!result) {
                return null;
            }
            return result.data;
        } catch (e) {
            return null;
        }
    }

    public async getPhotoFileAsFile(photoId: string): Promise<File | null> {
        try {
            const result = await this._clientStorageService.fetchItem<{ data: Blob; type: string; } | null>({ storeName: 'photo-uploads', key: photoId });
            if (!result) {
                return null;
            }
            let file = await this.blobToFile(result.data, photoId, result.type);
            return file;
        } catch (e) {
            return null;
        }
    }

    public addPhotoFile(photoId: string, file: File): void {
        this.fileToBlob(file).then(blob => {
            this._clientStorageService.storeItem({ storeName: 'photo-uploads', key: photoId, value: {data: blob, type: file.type} })
        }).catch(() => { console.log('Error converting file to blob') });
    }

    public removePhotoFile(photoId: string) {
        this._clientStorageService.removeItem({storeName: 'photo-uploads', key: photoId}).catch(() => {});
    }

    private async fileToBlob(file: File): Promise<Blob> {
        return new Blob([await file.arrayBuffer()]);
    }

    private async blobToFile(blob: Blob, fileName: string, fileType: string): Promise<File> {
        return new File([blob], fileName, { type: fileType, lastModified: Date.now() });
    }
}

export const photoFilesStore = new PhotoFilesStore(clientStorageService);