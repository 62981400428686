import * as React from 'react';
import { IonChip, IonLabel, IonIcon } from '@ionic/react';
import { closeCircle } from 'ionicons/icons';

type EditableTagsBarProps = {
    tags: {[tag: string]: true};
    onTagRemoved: (tag: string) => any;
}

export const EditableTagsBar: React.FC<EditableTagsBarProps> = ({tags, onTagRemoved}) => {
    return <div>{Object.keys(tags).map((tag, index) => <IonChip key={index}>
    <IonLabel color="dark">#{tag}</IonLabel>
    <IonIcon icon={closeCircle} color="dark" onClick={() => onTagRemoved(tag)}></IonIcon>
  </IonChip>)}</div>;
};