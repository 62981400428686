import * as React from 'react';
import { IonHeader, IonPage, IonToolbar, IonButtons, IonBackButton, IonTitle, IonContent, useIonViewWillEnter, NavContext } from '@ionic/react';
import { useParams } from 'react-router';
import { Post } from '../models/database/user-created/post';
import { createToast } from '../helpers';
import { PostCard } from '../components/post-card';
import { Loading } from '../components/loading';
import { useAuth } from '../hooks/use-auth';

export const ViewPostPage: React.FC = () => {
  const auth = useAuth();
  const navContext = React.useContext(NavContext);
  const { postId } = useParams<{ postId?: string }>();
  const [post, setPost] = React.useState<Post>();

  useIonViewWillEnter(() => {
    try {
      if (!postId) {
        throw new Error();
      }
      // getPostById({postId, authContext: auth.context}).then(postFromService => {
      //   if (!postFromService) {
      //     navContext.navigate('/dashboard', 'back');
      //     createToast('There was an error loading this post.');
      //   } else {
      //     setPost(postFromService);
      //   }
      // });
    } catch (error) {
      navContext.navigate('/dashboard', 'back');
      createToast('There was an error loading this post.');
    }
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="light">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/dashboard" />
          </IonButtons>
          <IonTitle>Post</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color='bg'>
        {!post && <div className='ion-padding-top'><Loading /></div>}
        {/* {post && <PostCard viewFullDetail={true} post={post} />} */}
      </IonContent>
    </IonPage>
  );
}
