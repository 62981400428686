import * as React from 'react';
import { IonButton } from '@ionic/react';
import { formatCount } from '../helpers';

interface InteractionsCountsProps {
    reactionsCount: number;
    savesCount: number;
    commentsCount: number;
    onReactionsClicked: () => any;
    onSavesClicked: () => any;
    onCommentsClicked: () => any;
}

export const InteractionsCountsBar: React.FC<InteractionsCountsProps> = ({ 
  reactionsCount, commentsCount, savesCount, onCommentsClicked, onSavesClicked, onReactionsClicked
}) => {

    return <div className="num-bar" style={{ borderTop: '1px solid #f4f5f8', borderBottom: '1px solid #f4f5f8' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <IonButton style={{width: '33%'}} color="dark" fill="clear"  onClick={() => onReactionsClicked()}>
          <div className="num-reactions">
            <div style={{ fontWeight: 'bold' }}>{formatCount(reactionsCount)}</div>
            {reactionsCount === 1 ? 'Reaction' : `Reactions`}
          </div>
        </IonButton>
        <IonButton
         style={{width: '33%'}} 
          fill="clear"
          color="dark"
          onClick={() => onCommentsClicked()}
        >
          {/* // href={`/view-post/${post.id}/comments`}> */}
          <div className="num-comments">
            <div style={{ fontWeight: 'bold', fontSize: '1.0em' }}>{formatCount(commentsCount)}</div>
            {commentsCount === 1 ? 'Comment' : `Comments`}
          </div>
        </IonButton>
        <IonButton style={{width: '33%'}}  fill="clear" color="dark" onClick={() => onSavesClicked()}>
          <div className="num-saves">
            <div style={{ fontWeight: 'bold', }}>{formatCount(savesCount)}</div>
            {savesCount === 1 ? 'Favorite' : `Favorites`}
          </div>
        </IonButton>
      </div>
    </div>
};