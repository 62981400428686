import * as ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { SplashScreen } from '@capacitor/splash-screen'
import { AppRoot } from './app-root';
import { deviceService } from './services/device-service';
import { networkService } from './services/network-service';
import { AuthService } from './services/auth-service';
import { PostsService } from './services/posts-service';
import { ClientStore } from './models/supporting/client-store';
import { UsersService } from './services/users-service';
import { WebSocketProvider } from './providers/web-socket-provider';
import { appConfig } from './constants';
import { HttpProvider } from './providers/http-provider';
import { IonApp } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

(async () => {
    try {
        console.log('Index');
        await SplashScreen.show({
            autoHide: false
        });
        try {
            await deviceService.initialize();
        } catch {
            console.log('Error initializing device service. ');
        }
        try {
            await networkService.initialize();
        } catch {
            console.log('error initializing network service.');
        }
        const appStore = new ClientStore('app');
        await appStore.initialize();
        const authService = new AuthService(new HttpProvider(appConfig.authApiBaseUrl));
        const usersService = new UsersService(new HttpProvider(appConfig.usersApiBaseUrl), authService);
        const postsService = new PostsService();
        await authService.initialize();
        await usersService.initialize();
        await postsService.initialize();
        await SplashScreen.hide();
        const ws = new WebSocketProvider(authService, appConfig.webSocketBaseUrl);
        console.log('Success initializing app and hid splash screen');
        ReactDOM.render(<IonApp style={{ width: '100%', maxWidth: '1600px', position: 'absolute', left: '50%', transform: 'translateX(-50%)', borderLeft: '1px solid lightgray', borderRight: '1px solid lightgray' }}>
            <IonReactRouter>
                <AppRoot authService={authService} postsService={postsService} ws={ws} />
            </IonReactRouter>
        </IonApp>
            , document.getElementById('root'));

    } catch (error) {
        // let user know something fucked up
        console.error(error);
        console.error('ERror initializing app.');
    }
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

