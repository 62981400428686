import * as React from 'react';
import { IonToolbar, IonTitle, IonButtons, IonContent, IonList, IonItem, IonLabel, IonBackButton, IonPage, IonAvatar, IonImg, IonThumbnail, IonIcon, useIonViewWillEnter } from '@ionic/react';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { heart } from 'ionicons/icons';

// import { NotificationsPageStore } from '../stores/page/notifications-page-store';

// reaction
export const NotificationsPage: React.FC = () => {
  useIonViewWillEnter(() => {
    //TODO load notifications
  });

  return <IonPage>
    <IonToolbar color="light">
      <IonTitle>Notifications</IonTitle>

      <IonButtons slot="start">
        <IonBackButton defaultHref="/" />
      </IonButtons>
    </IonToolbar>
    <IonContent>
      {/* <IonList lines="full">
        {
          currentUser.notifications.map(notification => <IonItem key={notification.id} style={{ position: 'relative' }}>
            <IonAvatar slot="start">
              {notification.avatarPhotoUrl && <IonImg src={notification.avatarPhotoUrl} />}
              {!notification.avatarPhotoUrl && <div style={{ backgroundColor: '#ECECEC', borderRadius: '50%', height: '100%' }}></div>}
            </IonAvatar>
            <IonLabel className="ion-text-wrap ion-padding-bottom">
              <span >{notification.who} </span><strong> {notification.what}</strong>.
              <br />
              {notification.icon && <IonIcon icon="heart" />}
              {notification.reactionType && <div></div>}
            </IonLabel>
            {notification.photoUrl && <IonThumbnail slot="end">
              <IonImg src={notification.photoUrl} />
            </IonThumbnail>}
            <div style={{ border: '1px solid red' }}><IonIcon icon={heart} color="danger" /></div>
            <div style={{ position: 'absolute', left: '0', bottom: '10px', color: '#868686', fontSize: '12px' }}>{formatDistanceToNow(notification.when, { addSuffix: true })}</div>
          </IonItem>)
        }
      </IonList> */}
    </IonContent>
  </IonPage>
};