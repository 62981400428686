import * as React from 'react';
import './tags-bar.css';

interface AppHeaderProps {
  tags: {[tag: string]: true};
  wrap?: boolean;
}

export const TagsBar: React.FC<AppHeaderProps> = ({ tags, wrap }) => {
  if (!tags || Object.keys(tags).length === 0) {
    return null;
  }
  return (<div className="tags-bar" style={{flexWrap: wrap === true ? 'wrap' : 'nowrap'}}>{Object.keys(tags).map((tag, index) => <div key={index} className="chip" onClick={() => {
   // routeTo(`/search/${tag}`, 'forward')
  }}>
  <strong>
    #{tag}
  </strong>
</div>)}</div>)
};
