import * as React from 'react';
import { IonToolbar, IonAvatar, IonRouterLink, IonButtons, IonButton, IonIcon } from '@ionic/react';
import { ellipsisVerticalOutline } from 'ionicons/icons';

export const AppUserToolbar: React.FC<{ username: string, userId: string, handleEllipsisClicked(): any}> = ({userId, username, handleEllipsisClicked}) => {
    return <IonToolbar style={{ borderRadius: '8px', paddingTop: '10px' }}>
    <IonAvatar slot="start" style={{ marginLeft: '8px', marginRight: '10px', backgroundColor: '#ECECEC', borderRadius: '50%', maxHeight: '40px', maxWidth: '40px' }}>
        <div style={{ height: '100%', width: '100%' }}></div>
    </IonAvatar>
    <IonRouterLink color="primary" routerLink={`/view-profile/${username}`}><strong style={{fontSize: '18px'}}>{username}</strong></IonRouterLink>
    <IonButtons slot="end">
        <IonButton fill="clear" color="dark" onClick={() => handleEllipsisClicked()}>
            <IonIcon icon={ellipsisVerticalOutline} />
        </IonButton>
    </IonButtons>
</IonToolbar>
}