import * as React from 'react';
import { IonPage, IonContent, IonToolbar, IonTitle, IonList, IonListHeader, IonItem, IonIcon, IonLabel, IonHeader, NavContext, useIonViewWillEnter, IonBadge } from '@ionic/react';
import { rocketOutline, settingsOutline, logOutOutline, peopleOutline, starOutline, thumbsUpOutline, newspaperOutline, eyeOutline, heartOutline, personOutline, trashOutline, notificationsOutline, logInOutline } from 'ionicons/icons';
import { TabsBar } from '../components/tabs-bar';
import { useAuth } from '../hooks/use-auth';
import { AuthContext } from '../models/app/auth-context';
import { MoreMenuLinks } from '../components/more-menu-links';
import { useLocation } from 'react-router-dom';

export const MoreTab: React.FC<{auth: Readonly<AuthContext> | null}> = ({auth}) => {
    const location = useLocation();
    if (location.pathname !== '/more') {
        return null;
    }
    console.log('RENDERING MORETAB');

    // async function handleOnDowngradeMembershipClicked() {
    //     try {
    //         const alert = await alertController.create({
    //             message: 'Are you sure you want to downgrade from Pro to Basic?  You will lose the ability to sell items.  Items you have for sale will be removed once your subscription date passes, and if you have more than 200MB of photos, may lose some of your oldest uploaded photos.',
    //             buttons: [
    //                 {
    //                     cssClass: 'delete-text',
    //                     text: 'Cancel'
    //                 },
    //                 {
    //                     text: 'Yes',
    //                     handler: () => {
    //                         // downgradeToBasic().then(() => {
    //                         //     createToast('We have downgraded you to a Basic member.  Thank you for being a Pro member!');
    //                         // }).catch(error => {
    //                         //     console.error(error);
    //                         //     // TODO handle this
    //                         // })
    //                     }
    //                 }
    //             ]
    //         });
    //         await alert.present();
    //     } catch (error) {
    //         console.error(error);
    //         // TODO how handle it?
    //         createToast('There was an error modifying your membership.')
    //     }
    // }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="light">
                    <IonTitle>More</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent color="bg">
                <IonList lines="full">
                    <MoreMenuLinks auth={auth} />
                    {/* <IonListHeader color="header-separator" mode="md">
                        Your Membership
                </IonListHeader>
                    {appState.appState.currentUser && <IonItem color="light">
                        <IonLabel>Membership Level</IonLabel>
                        {appState.appState.currentUser.membership === 'basic' && <span className="basic-badge">Basic</span>}
                        {appState.appState.currentUser.membership === 'pro' && <span className="pro-badge">
                            <IonIcon icon={trophy}></IonIcon>
                    Pro</span>}
                    </IonItem>}

                    {appState.appState.currentUser && appState.appState.currentUser.membership === 'basic' &&
                        <IonItem button={true} detail={true} routerLink="/upgrade-membership">
                            <IonIcon slot="start" icon={trophyOutline}></IonIcon>
                            <IonLabel>Upgrade Membership</IonLabel>
                        </IonItem>
                    }

                    {appState.appState.currentUser && appState.appState.currentUser.membership !== 'basic' &&
                        <IonItem button={true} detail={true} onClick={() => handleOnDowngradeMembershipClicked()}>
                            <IonIcon slot="start" icon={trendingDownOutline}></IonIcon>
                            <IonLabel>Downgrade Membership</IonLabel>
                        </IonItem>
                    } */}
  
                </IonList>
            </IonContent>
            {/* <TabsBar moreTabBadgeCount={0} selectedTab='more' /> */}

        </IonPage>
    );
};