import * as React from 'react';
import { IonPage, IonContent, IonToolbar, IonTitle, IonList, IonListHeader, IonItem, IonIcon, IonLabel, IonHeader, NavContext, useIonViewWillEnter, IonBadge } from '@ionic/react';
import { rocketOutline, settingsOutline, logOutOutline, peopleOutline, starOutline, thumbsUpOutline, newspaperOutline, eyeOutline, heartOutline, personOutline, trashOutline, notificationsOutline, logInOutline } from 'ionicons/icons';
import {AuthContext} from '../models/app/auth-context';

export const MoreMenuLinks: React.FC<{auth: Readonly<AuthContext> | null}> = ({auth}) => {
    return <>
                  <IonListHeader color="header-separator" mode="md">You</IonListHeader>
                    {(!auth || !auth.user) && <>
                        <IonItem button={true} detail={true} routerLink="/log-in">
                            <IonIcon color="primary" slot="start" icon={logInOutline}></IonIcon>
                            <IonLabel>Log In</IonLabel>
                        </IonItem>
                    </>}
                    {auth && auth.user &&
                        <>
                            <IonItem button={true} detail={true} routerLink='/notifications'>
                                <IonIcon color="primary" slot="start" icon={notificationsOutline} />
                                <IonLabel>Notifications</IonLabel>
                                <IonBadge slot="end">1K+</IonBadge>
                            </IonItem>

                            <IonItem button={true} detail={true} routerLink={`/profile/${auth.user.username}`}>
                                <IonIcon color="primary" slot="start" icon={personOutline} />
                                <IonLabel>Profile</IonLabel>
                            </IonItem>

                            <IonItem button={true} detail={true} routerLink='/user-favorites'>
                                <IonIcon color="primary" slot="start" icon={heartOutline} />
                                <IonLabel>Favorites</IonLabel>
                            </IonItem>


                            <IonItem button={true} detail={true} routerLink="/settings">
                                <IonIcon color="primary" slot="start" icon={settingsOutline}></IonIcon>
                                <IonLabel>Settings</IonLabel>
                            </IonItem>

                            <IonItem button={true} detail={true} onClick={() => {

                            }}>
                                <IonIcon color="primary" slot="start" icon={logOutOutline}></IonIcon>
                                <IonLabel>Log Out</IonLabel>
                            </IonItem>


                            <IonItem button={true} detail={true}>
                                <IonIcon color="primary" slot="start" icon={trashOutline}></IonIcon>
                                <IonLabel color="danger">Delete Account</IonLabel>
                            </IonItem>
                        </>
                    }

                    <IonListHeader color="header-separator" mode="md">Feedback and Ratings</IonListHeader>
                    <IonItem button={true} detail={true}>
                        <IonIcon color="primary" slot="start" icon={peopleOutline}></IonIcon>
                        <IonLabel>Share App With Your Friends</IonLabel>
                    </IonItem>
                    <IonItem button={true} detail={true}>
                        <IonIcon color="primary" slot="start" icon={starOutline}></IonIcon>
                        <IonLabel>Rate App On Store</IonLabel>
                    </IonItem>

                    <IonItem button={true} detail={true} routerLink="/add-suggestion">
                        <IonIcon color="primary" slot="start" icon={thumbsUpOutline}></IonIcon>
                        <IonLabel>Suggest Improvements or Features</IonLabel>
                    </IonItem>

                    <IonListHeader color="header-separator" className="ion-margin-top" mode="md">Advertising</IonListHeader>

                    <IonItem>
                        {/* <IonLabel className="ion-text-wrap">The app is currently membership-based and ad-free. If user memberships fail to provide necessary revenue, this could change in the future and will be highlighted here.</IonLabel> */}
                        <IonLabel className="ion-text-wrap">If you are interested in advertising, please email: <a href="mailto:kevin.clark.adstech@gmail.com">kevin.clark.adstech@gmail.com</a>.</IonLabel>
                    </IonItem>

                    <IonListHeader color="header-separator" className="ion-margin-top" mode="md">Legalese</IonListHeader>

                    <IonItem button={true} detail={true} routerLink="/terms-of-use">
                        <IonIcon color="primary" slot="start" icon={newspaperOutline}></IonIcon>
                        <IonLabel>Terms Of Use</IonLabel>
                    </IonItem>

                    <IonItem button={true} detail={true} routerLink="/privacy-policy">
                        <IonIcon color="primary" slot="start" icon={eyeOutline}></IonIcon>
                        <IonLabel>Privacy Policy</IonLabel>
                    </IonItem>
    </>
}