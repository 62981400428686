import { User, UserDetails } from '../models/database/system-created/user';
import { RedactedUser } from '../models/dto/redacted-user';
import { ClientStore } from '../models/supporting/client-store';
import { HttpProvider } from '../providers/http-provider';
import { AuthService } from './auth-service';

export class UsersService {
    private _usersStore = new ClientStore('users');
    private _lastRefreshed: Date | null;

    public constructor(private _http: HttpProvider, private _authService: AuthService) {
        const lastRefreshedStr = localStorage.getItem('users-last-refreshed');
        if (lastRefreshedStr) {
            this._lastRefreshed = new Date(lastRefreshedStr);
        } else {
            this._lastRefreshed = null;
        }
    }

    public get(): readonly RedactedUser[] {
        return [];
    }

    public async initialize(): Promise<void> {
        await this._usersStore.initialize();
        this.refreshLatestUsers().catch(error => {
            console.log('error refreshing latest users.');
        });
    }

    public async create(details: UserDetails): Promise<User> {
        if (!this._authService.authContext) {
            throw new Error();
        }
        const response = await this._http.post<{ newUser: User }>('/create-user', details, { 'auth-token': this._authService.authContext.authToken });
        return response.newUser;
    }

    public async getById(userId: string): Promise<Readonly<RedactedUser> | null> {
        const local = await this._usersStore.fetchItem<RedactedUser>(userId);
        if (local) {
            return local;
        }
        throw new Error();
    }

    public async refreshLatestUsers(): Promise<void> {

    }
}