import * as React from 'react';
import { IonButton, IonPopover, IonText } from '@ionic/react';
import { Emojis } from '../constants';
import './reactions-button.css';

export const ReactionsButton: React.FC<{ 
    width: string,
    height?: string,
    hideLabel?: boolean,
    onReactionClicked: (reaction: Emojis) => any, authenticatedUserReaction: Emojis | undefined 
}> = ({ hideLabel, height, width, onReactionClicked, authenticatedUserReaction }) => {

    const [popoverIsOpen, setPopoverIsOpen] = React.useState(false);

    function getHtml(): string {
        // backgroundColor: authenticatedUserReaction ? '#ff002f' : 'none', height: '100%', borderRadius: authenticatedUserReaction ? '5px' : 'none',
        if (authenticatedUserReaction) {
            return `<div style="font-size: 22px;margin-bottom:7px;margin-top:-2px;background-color:#bbf3f3;border-radius:5px">&#${authenticatedUserReaction || Emojis.ThumbsUp};</div>`;
        }
        return `<div style="font-size: 22px;">&#${authenticatedUserReaction || Emojis.ThumbsUp};</div>`;
    }

    function handleReactionClicked(reaction: Emojis) {
        setPopoverIsOpen(false);
        onReactionClicked(reaction);
    }

    return <>
        {/* <div style={{ width: width, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', fontSize: '13px', fontWeight: 400 }} onClick={() => setPopoverIsOpen(!popoverIsOpen)}>
        <div dangerouslySetInnerHTML={{
            __html: getHtml()
        }}></div>
        <IonText color="primary">React</IonText>
    </div> */}
        <IonButton fill="clear" size="large" style={{height: height || '58px', width: width}} onClick={() => setPopoverIsOpen(!popoverIsOpen)}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: '2px'}}>
                <div style={{paddingBottom: '4px'}} dangerouslySetInnerHTML={{
                    __html: getHtml()
                }}></div>
                {!hideLabel && <div style={{fontSize: '14px', paddingTop: '7px', marginBottom: '-2px'}}>React</div>}
            </div>

        </IonButton>
        <IonPopover isOpen={popoverIsOpen} onWillDismiss={() => setPopoverIsOpen(false)}>
            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-evenly' }}>
                <div onClick={() => handleReactionClicked(Emojis.ThumbsUp)} style={{ height: '60px', width: '45px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ backgroundColor: authenticatedUserReaction === Emojis.ThumbsUp ? '#bbf3f3' : 'none', borderRadius: authenticatedUserReaction === Emojis.ThumbsUp ? '5px' : 'none', fontSize: '24px' }} dangerouslySetInnerHTML={{ __html: `&#${Emojis.ThumbsUp};` }}></div>
                </div>

                <div onClick={() => handleReactionClicked(Emojis.ThumbsDown)} style={{ height: '60px', width: '45px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ backgroundColor: authenticatedUserReaction === Emojis.ThumbsDown ? '#bbf3f3' : 'none', borderRadius: authenticatedUserReaction === Emojis.ThumbsDown ? '5px' : 'none', fontSize: '24px' }} dangerouslySetInnerHTML={{ __html: `&#${Emojis.ThumbsDown};` }}></div>
                </div>

                <div onClick={() => handleReactionClicked(Emojis.Likes)} style={{ height: '60px', width: '45px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ backgroundColor: authenticatedUserReaction === Emojis.Likes ? '#bbf3f3' : 'none', borderRadius: authenticatedUserReaction === Emojis.Likes ? '5px' : 'none', fontSize: '24px' }} dangerouslySetInnerHTML={{ __html: `&#${Emojis.Likes};` }}></div>
                </div>

                <div onClick={() => handleReactionClicked(Emojis.Loves)} style={{ height: '60px', width: '45px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ backgroundColor: authenticatedUserReaction === Emojis.Loves ? '#bbf3f3' : 'none', borderRadius: authenticatedUserReaction === Emojis.Loves ? '5px' : 'none', fontSize: '24px' }} dangerouslySetInnerHTML={{ __html: `&#${Emojis.Loves};` }}></div>
                </div>

                <div onClick={() => handleReactionClicked(Emojis.Laughing)} style={{ height: '60px', width: '45px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ backgroundColor: authenticatedUserReaction === Emojis.Laughing ? '#bbf3f3' : 'none', borderRadius: authenticatedUserReaction === Emojis.Laughing ? '5px' : 'none', fontSize: '24px' }} dangerouslySetInnerHTML={{ __html: `&#${Emojis.Laughing};` }}></div>
                </div>

                <div onClick={() => handleReactionClicked(Emojis.Money)} style={{ height: '60px', width: '45px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ backgroundColor: authenticatedUserReaction === Emojis.Money ? '#bbf3f3' : 'none', borderRadius: authenticatedUserReaction === Emojis.Money ? '5px' : 'none', fontSize: '24px' }} dangerouslySetInnerHTML={{ __html: `&#${Emojis.Money};` }}></div>
                </div>

                <div onClick={() => handleReactionClicked(Emojis.Shocked)} style={{ height: '60px', width: '45px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ backgroundColor: authenticatedUserReaction === Emojis.Shocked ? '#bbf3f3' : 'none', borderRadius: authenticatedUserReaction === Emojis.Shocked ? '5px' : 'none', fontSize: '24px' }} dangerouslySetInnerHTML={{ __html: `&#${Emojis.Shocked};` }}></div>
                </div>

                <div onClick={() => handleReactionClicked(Emojis.SideEye)} style={{ height: '60px', width: '45px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ backgroundColor: authenticatedUserReaction === Emojis.SideEye ? '#bbf3f3' : 'none', borderRadius: authenticatedUserReaction === Emojis.SideEye ? '5px' : 'none', fontSize: '24px' }} dangerouslySetInnerHTML={{ __html: `&#${Emojis.SideEye};` }}></div>
                </div>

                <div onClick={() => handleReactionClicked(Emojis.RaisedEyebrow)} style={{ height: '60px', width: '45px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ backgroundColor: authenticatedUserReaction === Emojis.RaisedEyebrow ? '#bbf3f3' : 'none', borderRadius: authenticatedUserReaction === Emojis.RaisedEyebrow ? '5px' : 'none', fontSize: '24px' }} dangerouslySetInnerHTML={{ __html: `&#${Emojis.RaisedEyebrow};` }}></div>
                </div>

                <div onClick={() => handleReactionClicked(Emojis.NoExpression)} style={{ height: '60px', width: '45px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ backgroundColor: authenticatedUserReaction === Emojis.NoExpression ? '#bbf3f3' : 'none', borderRadius: authenticatedUserReaction === Emojis.NoExpression ? '5px' : 'none', fontSize: '24px' }} dangerouslySetInnerHTML={{ __html: `&#${Emojis.NoExpression};` }}></div>
                </div>

                <div onClick={() => handleReactionClicked(Emojis.Thinking)} style={{ height: '60px', width: '45px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ backgroundColor: authenticatedUserReaction === Emojis.Thinking ? '#bbf3f3' : 'none', borderRadius: authenticatedUserReaction === Emojis.Thinking ? '5px' : 'none', fontSize: '24px' }} dangerouslySetInnerHTML={{ __html: `&#${Emojis.Thinking};` }}></div>
                </div>

                <div onClick={() => handleReactionClicked(Emojis.Cringe)} style={{ height: '60px', width: '45px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ backgroundColor: authenticatedUserReaction === Emojis.Cringe ? '#bbf3f3' : 'none', borderRadius: authenticatedUserReaction === Emojis.Cringe ? '5px' : 'none', fontSize: '24px' }} dangerouslySetInnerHTML={{ __html: `&#${Emojis.Cringe};` }}></div>
                </div>

                <div onClick={() => handleReactionClicked(Emojis.Cry)} style={{ height: '60px', width: '45px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ backgroundColor: authenticatedUserReaction === Emojis.Cry ? '#bbf3f3' : 'none', borderRadius: authenticatedUserReaction === Emojis.Cry ? '5px' : 'none', fontSize: '24px' }} dangerouslySetInnerHTML={{ __html: `&#${Emojis.Cry};` }}></div>
                </div>

                <div onClick={() => handleReactionClicked(Emojis.Angry)} style={{ height: '60px', width: '45px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ backgroundColor: authenticatedUserReaction === Emojis.Angry ? '#bbf3f3' : 'none', borderRadius: authenticatedUserReaction === Emojis.Angry ? '5px' : 'none', fontSize: '24px' }} dangerouslySetInnerHTML={{ __html: `&#${Emojis.Angry};` }}></div>
                </div>

                <div onClick={() => handleReactionClicked(Emojis.CreditCard)} style={{ height: '60px', width: '45px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ backgroundColor: authenticatedUserReaction === Emojis.CreditCard ? '#bbf3f3' : 'none', borderRadius: authenticatedUserReaction === Emojis.CreditCard ? '5px' : 'none', fontSize: '24px' }} dangerouslySetInnerHTML={{ __html: `&#${Emojis.CreditCard};` }}></div>
                </div>

                <div onClick={() => handleReactionClicked(Emojis.Wrench)} style={{ height: '60px', width: '45px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ backgroundColor: authenticatedUserReaction === Emojis.Wrench ? '#bbf3f3' : 'none', borderRadius: authenticatedUserReaction === Emojis.Wrench ? '5px' : 'none', fontSize: '24px' }} dangerouslySetInnerHTML={{ __html: `&#${Emojis.Wrench};` }}></div>
                </div>

                <div onClick={() => handleReactionClicked(Emojis.Trophy)} style={{ height: '60px', width: '45px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ backgroundColor: authenticatedUserReaction === Emojis.Trophy ? '#bbf3f3' : 'none', borderRadius: authenticatedUserReaction === Emojis.Trophy ? '5px' : 'none', fontSize: '24px' }} dangerouslySetInnerHTML={{ __html: `&#${Emojis.Trophy};` }}></div>
                </div>

                <div onClick={() => handleReactionClicked(Emojis.Clapping)} style={{ height: '60px', width: '45px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ backgroundColor: authenticatedUserReaction === Emojis.Clapping ? '#bbf3f3' : 'none', borderRadius: authenticatedUserReaction === Emojis.Clapping ? '5px' : 'none', fontSize: '24px' }} dangerouslySetInnerHTML={{ __html: `&#${Emojis.Clapping};` }}></div>
                </div>

                <div onClick={() => handleReactionClicked(Emojis.OneHundred)} style={{ height: '60px', width: '45px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ backgroundColor: authenticatedUserReaction === Emojis.OneHundred ? '#bbf3f3' : 'none', borderRadius: authenticatedUserReaction === Emojis.OneHundred ? '5px' : 'none', fontSize: '24px' }} dangerouslySetInnerHTML={{ __html: `&#${Emojis.OneHundred};` }}></div>
                </div>

                <div onClick={() => handleReactionClicked(Emojis.BlackFlag)} style={{ height: '60px', width: '45px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ backgroundColor: authenticatedUserReaction === Emojis.BlackFlag ? '#bbf3f3' : 'none', borderRadius: authenticatedUserReaction === Emojis.BlackFlag ? '5px' : 'none', fontSize: '24px' }} dangerouslySetInnerHTML={{ __html: `&#${Emojis.BlackFlag};` }}></div>
                </div>

                <div onClick={() => handleReactionClicked(Emojis.CheckeredFlag)} style={{ height: '60px', width: '45px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ backgroundColor: authenticatedUserReaction === Emojis.CheckeredFlag ? '#bbf3f3' : 'none', borderRadius: authenticatedUserReaction === Emojis.CheckeredFlag ? '5px' : 'none', fontSize: '24px' }} dangerouslySetInnerHTML={{ __html: `&#${Emojis.CheckeredFlag};` }}></div>
                </div>

                <div onClick={() => handleReactionClicked(Emojis.Beer)} style={{ height: '60px', width: '45px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ backgroundColor: authenticatedUserReaction === Emojis.Beer ? '#bbf3f3' : 'none', borderRadius: authenticatedUserReaction === Emojis.Beer ? '5px' : 'none', fontSize: '24px' }} dangerouslySetInnerHTML={{ __html: `&#${Emojis.Beer};` }}></div>
                </div>

                <div onClick={() => handleReactionClicked(Emojis.Beers)} style={{ height: '60px', width: '45px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ backgroundColor: authenticatedUserReaction === Emojis.Beers ? '#bbf3f3' : 'none', borderRadius: authenticatedUserReaction === Emojis.Beers ? '5px' : 'none', fontSize: '24px' }} dangerouslySetInnerHTML={{ __html: `&#${Emojis.Beers};` }}></div>
                </div>

            </div>
        </IonPopover>
    </>
};