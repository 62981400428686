import * as React from 'react';
import { IonItem, IonInput } from '@ionic/react';

interface TagsFormInputProps {
  tags: { [tag: string]: true };
  onTagAdded: (tag: string) => any;
  maxNumberTags?: number;
}

// onKeyUp={evt => {
//   if (evt.keyCode === keyCodes.COMMA
//     || evt.keyCode === keyCodes.ENTER
//     || evt.keyCode === keyCodes.SPACE) {
//     const ionInput = (evt.target as HTMLIonInputElement);
//     let val = ionInput.value && typeof ionInput.value === 'string' ? ionInput.value.trim() : String(ionInput.value);
//     if (val.length > 0) {
//       val = val.replace(/[^a-z0-9]/gi, '').trim();
//       setCurrentTagEntry(val);
//       console.log(currentTagEntry);
//       console.log(val);
//       if (!tags[val]) {
//         onTagAdded(currentTagEntry);
//       }
//       setCurrentTagEntry('');
//     }
//   } else {
//     setCurrentTagEntry((evt.target as HTMLIonInputElement).value || '')
//   }
// }}

interface UserTagsFormInputState {
  currentTagEntry: string
}
export const TagsFormInput: React.FC<TagsFormInputProps> = ({
  tags,
  onTagAdded,
  maxNumberTags
}) => {
  const [state, setState] = React.useState<UserTagsFormInputState>({
    currentTagEntry: ''
  });
  return (
    <>
      <IonItem style={{ padding: '15px' }}>

        <IonInput
          disabled={maxNumberTags ? Object.keys(tags).length === maxNumberTags : false}
          type='text'
          maxlength={25}
          placeholder={maxNumberTags && Object.keys(tags).length === maxNumberTags ? "Max limit of " + maxNumberTags + " tags reached." : "Add # Tags"}
          value={state.currentTagEntry}
          onIonChange={evt => {
            let val = evt.detail.value;
            if (val) {
              const lastChar = val.charAt(val.length - 1);
              console.log(val);
              if (lastChar === ' ' || lastChar === ',') {
                const sanitizedVal = val.replace(/[^a-z0-9]/gi, '').toLowerCase().trim();

                if (sanitizedVal && !tags[sanitizedVal]) {
                  onTagAdded(sanitizedVal);
                  setState({ ...state, currentTagEntry: '' });
                }
              } else {
                setState({ ...state, currentTagEntry: val });
              }
            } else {
              setState({ ...state, currentTagEntry: '' });
            }
          }}
        >
        </IonInput>
      </IonItem>
      <div style={{ textAlign: 'center', fontSize: '0.6em' }}>{state.currentTagEntry.length}/25</div>
      <div style={{ textAlign: 'center', fontSize: '0.6em' }}>Letters and digits only. Seperate with space or comma</div>
    </>
  );
};
