import * as React from 'react';
import { IonHeader, IonPage, IonToolbar, IonTitle, IonButtons, IonBackButton, IonContent, useIonViewWillEnter } from '@ionic/react';
import { Redirect } from 'react-router';
import { useAuth } from '../hooks/use-auth';

export const ViewVehicleModPage: React.FC = () => {
  const auth = useAuth();
  useIonViewWillEnter(() => {

  });

  if (!auth.context) {
    return <Redirect to="/" />
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="light">
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>View Vehicle Mod</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>

      </IonContent>
    </IonPage>
  );

};
