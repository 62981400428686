import * as React from 'react';
import { IonFab, IonFabButton, IonIcon } from '@ionic/react';
import { createOutline } from 'ionicons/icons';
import { createToast } from '../helpers';
import { Post } from '../models/database/user-created/post';
// import { authService } from '../services/auth-service';
import { AddEditPostModal } from '../modals/add-edit-post-modal';
import {AuthModal} from '../modals/auth-modal';
import { useAuth } from '../hooks/use-auth';

export const AddPostFab: React.FC = () => {
    const auth = useAuth();
    const [isAddingPost, setIsAddingPost] = React.useState<boolean>(false);
    const [postIsEditing, setPostIsEditing] = React.useState<Post>();
    const [authModalIsOpen, setAuthModalIsOpen] = React.useState<boolean>(false);
    return <>
        <IonFab vertical="bottom" horizontal="end" slot="fixed" style={{marginBottom: '70px'}}>
            <IonFabButton color="secondary" onClick={() => {
                if (!auth.context) {
                    // navContext.navigate('/log-in');
                    setAuthModalIsOpen(true);
                } else {
                    setIsAddingPost(true);
                }
            }}>
                <IonIcon icon={createOutline}></IonIcon>+
          </IonFabButton>
        </IonFab>

        <AuthModal isOpen={authModalIsOpen} onCanceled={() => setAuthModalIsOpen(false)} onAuthenticated={() => setAuthModalIsOpen(false)} />

        <AddEditPostModal isOpen={isAddingPost} existingPost={postIsEditing} onSaved={() => {
            setIsAddingPost(false);
            if (postIsEditing) {
                createToast('Your post was updated.', undefined, undefined, ['Ok']).catch(() => { });
                setPostIsEditing(undefined);
            } else {
                createToast('Your post was created.', undefined, undefined, ['Ok', {
                    text: 'View', handler: () => {
                        const ionContentEl = document.getElementById('dashboard-tab-content') as HTMLIonContentElement | null;
                        if (ionContentEl) {
                            ionContentEl.scrollEvents = true;
                            ionContentEl.scrollToTop(200);
                            ionContentEl.scrollEvents = false;
                        } else {
                            createToast('Sorry, an error occurred.  Please scroll to the top to view your post.');
                        }
                    }
                }]).catch(() => { });
            }
        }} onCanceled={() => setIsAddingPost(false)} />
    </>
}