import * as React from 'react';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, useIonViewWillEnter } from '@ionic/react';
import { Redirect, useParams } from 'react-router';
import { alertController } from '@ionic/core';
import { createToast, handleError } from '../helpers';
import { VehicleModsList } from '../components/vehicle-mods-list';
import { VehicleDetail } from '../components/vehicle-detail';
import { Vehicle } from '../models/database/user-created/vehicle';
import { VehicleMod } from '../models/database/user-created/vehicle-mod';
import { useAuth } from '../hooks/use-auth';

export const ViewVehiclePage: React.FC = () => {
  const auth = useAuth();
  const { vehicleId } = useParams<{ vehicleId: string }>();

  const [state] = React.useState<{
    loading: boolean,
    initialize(): void,
    vehicle: Vehicle | undefined,
    vehicleMods: VehicleMod[]
  }>(() => {

    return {
      loading: false,
      vehicle: undefined,
      initialize() {
        try {
          // if (currentUser && currentUser.vehicles[vehicleId]) {
          //   console.log('Vehicle is on current user')
          //   this.vehicle = currentUser.vehicles[vehicleId];
          // } else {

          // }
        } catch (error: any) {
          handleError(error);
        }
      },
      vehicleMods: []
    };
  });

  useIonViewWillEnter(() => {
    state.initialize();
  });
  if (!vehicleId) {
    createToast('There was an unexpected error');
    return null;
  }

  if (!auth.context) {
    return <Redirect to="/" />
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="light">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/garage" />
          </IonButtons>
          <IonTitle>View Vehicle</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>

        {state.vehicle && <>
          <VehicleDetail vehicle={state.vehicle} showRecentMods={false} />

          <VehicleModsList isEditable={(auth.context && auth.context.user.id === state.vehicle.createdBy) || false} mods={state.vehicleMods} onEditClicked={(mod) => {
            // state.setModBeingAddedEdited(mod);
          }} onDeleteClicked={modId => {
            alertController.create({
              message: 'Are you sure you would like to delete this mod?', buttons: ['No', {
                text: 'Yes', handler: () => {

                }
              }]
            }).then(alert => alert.present()).catch(() => createToast('There was an error deleting your mod.'));
          }} />
        </>}
      </IonContent>
    </IonPage>
  );
};
