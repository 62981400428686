import * as React from 'react';
import { IonHeader, IonPage, IonContent, IonToolbar, IonTitle, IonSearchbar, IonButton, IonSegment, IonSegmentButton, IonButtons, IonBackButton, useIonViewWillEnter } from '@ionic/react';
import { AppHeader } from '../components/app-header';
import { getBaseServerUrl } from '../helpers';
import { Redirect, useParams } from 'react-router';
import { useAuth } from '../hooks/use-auth';

export const SearchPage: React.FC = () => {
  const auth = useAuth();
  const [visibleSection, setVisibleSection] = React.useState<'posts' | 'vehicles' | 'users'>('posts');
  const {searchTermFromUrl } = useParams<{searchTermFromUrl?: string}>();
  const [searchTerm, setSearchTerm] = React.useState<string>('');

  useIonViewWillEnter(() => {
    if (searchTermFromUrl) {
      setSearchTerm(searchTermFromUrl);
    }
  });

  if (!auth.context) {
    return <Redirect to="/dashboard" />
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="light">
          <IonButtons slot="start">
            <IonBackButton color="primary" defaultHref="/" />
          </IonButtons>
          <IonTitle>Search</IonTitle>
        </IonToolbar></IonHeader>
      <IonContent>
        <IonToolbar className="ion-padding-top">
          <IonSearchbar onIonInput={() => console.log('ion input')} onIonClear={() => {
            // searchPageStore.search('');
          }} onIonChange={evt => {
            if (evt.detail.value) {
              setSearchTerm(evt.detail.value);
              if (searchTerm.length > 2) {

              }
            }
          }} debounce={500} placeholder="Search users, posts, and vehicles/mods" show-cancel-button="never" cancelButtonIcon="close-outline">

          </IonSearchbar>
        </IonToolbar>
        {/* <IonButton onClick={() => {
          Http.delete({ url: `https://search.modclub.social/indexes/users/documents`, headers: { 'X-Meili-API-Key': 'MTYwNjBkZDZkMmEyYzBiZjZlNzkwZWZj' } }).then(() => {
            toastController.create({ message: 'Users deleted', duration: 1000 }).then(toast => toast.present())
          });
          Http.delete({ url: `https://search.modclub.social/indexes/vehicles/documents`, headers: { 'X-Meili-API-Key': 'MTYwNjBkZDZkMmEyYzBiZjZlNzkwZWZj' } }).then(() => {
            toastController.create({ message: 'Vehicles deleted', duration: 2000 }).then(toast => toast.present())
          });
          Http.delete({ url: `https://search.modclub.social/indexes/posts/documents`, headers: { 'X-Meili-API-Key': 'MTYwNjBkZDZkMmEyYzBiZjZlNzkwZWZj' } }).then(() => {
            toastController.create({ message: 'Posts deleted', duration: 3000 }).then(toast => toast.present())
          });
        }}>Delete All Documents</IonButton> */}
        <p>

        </p>

        <p>
          The backend url I am connecting to is: {getBaseServerUrl()}
        </p>

        <IonSegment value={visibleSection}>
          <IonSegmentButton onClick={() => setVisibleSection('posts')} value="posts">Posts</IonSegmentButton>
          <IonSegmentButton onClick={() => setVisibleSection('users')} value="users">Users</IonSegmentButton>
          <IonSegmentButton onClick={() => setVisibleSection('vehicles')} value="vehicles">Vehicles</IonSegmentButton>
        </IonSegment>
        {/* {searchPageStore.searchResults &&
          <>
            <div hidden={visibleSection !== 'posts'}>
              <PostsList currentUser={searchPageStore.currentUser} posts={searchPageStore.searchResults.posts} reactionsStore={searchPageStore.reactionsStore} />
            </div>

            <div hidden={visibleSection !== 'users'}>

            </div>

            <div hidden={visibleSection !== 'vehicles'}>
              <VehiclesList currentUser={searchPageStore.currentUser} view='list-item' vehicles={searchPageStore.searchResults.vehicles} />
            </div>

          </>} */}
      </IonContent>
    </IonPage>
  );

};
