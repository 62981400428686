import axios from 'axios';
import { AuthenticationException, AuthorizationException } from '../models/supporting/exceptions';

export interface IHttpProvider {
  get<ResponseSchema>(url: string, headers: { [key: string]: any }): Promise<ResponseSchema>;
  post<ResponseSchema>(url: string, requestBody: { [key: string]: any } | FormData, headers: { [key: string]: any }): Promise<ResponseSchema>;
  put<ResponseSchema>(url: string, requestBody: { [key: string]: any } | FormData, headers: { [key: string]: any }): Promise<ResponseSchema>;
  patch<ResponseSchema>(url: string, requestBody: { [key: string]: any } | FormData, headers: { [key: string]: any }): Promise<ResponseSchema>;
  delete<ResponseSchema>(url: string, headers: { [key: string]: any }): Promise<ResponseSchema>;
}

export class HttpProvider implements IHttpProvider {

  public constructor(private baseEndpoint: string) { }

  public async get<ResponseSchema>(url: string, headers: { [key: string]: any } = {}): Promise<ResponseSchema> {
    try {
      const response = await axios.get<{ data: ResponseSchema }>(`${this.baseEndpoint}${url}`, {
        headers
      });
      return response.data.data;
    } catch (error: any) {
      console.log('error in httpprovider');
      console.log(error);
      if (error?.response) {
        let statusCode = error.response.status || 500;
        switch (statusCode) {
          case 401: {
            throw new AuthenticationException();
          }
          case 403: {
            throw new AuthorizationException();
          }
        }
      }
      throw error;
    }
  }

  public async post<ResponseSchema>(url: string, requestBody: { [key: string]: any } | FormData = {}, headers: { [key: string]: any } = {}): Promise<ResponseSchema> {
    try {
      const response = await axios.post<{ data: ResponseSchema }>(`${this.baseEndpoint}${url}`, requestBody, {
        headers
      });
      return response.data.data;
    } catch (error: any) {
      if (error?.response) {
        let statusCode = error.response.status || 500;
        switch (statusCode) {
          case 401: {
            throw new AuthenticationException();
          }
          case 403: {
            throw new AuthorizationException();
          }
        }
      }
      throw error;
    }
  }

  public async put<ResponseSchema>(url: string, requestBody: { [key: string]: any } = {}, headers: { [key: string]: any } = {}): Promise<ResponseSchema> {
    try {
      const response = await axios.put<{ data: ResponseSchema }>(`${this.baseEndpoint}${url}`, requestBody, {
        headers
      });
      return response.data.data;
    } catch (error: any) {
      if (error?.response) {
        let statusCode = error.response.status || 500;
        switch (statusCode) {
          case 401: {
            throw new AuthenticationException();
          }
          case 403: {
            throw new AuthorizationException();
          }
        }
      }
      throw error;
    }
  }

  public async patch<ResponseSchema>(url: string, requestBody: { [key: string]: any } = {}, headers: { [key: string]: any } = {}): Promise<ResponseSchema> {
    try {
      const response = await axios.patch<{ data: ResponseSchema }>(`${this.baseEndpoint}${url}`, requestBody, {
        headers
      });
      return response.data.data;
    } catch (error: any) {
      if (error?.response) {
        let statusCode = error.response.status || 500;
        switch (statusCode) {
          case 401: {
            throw new AuthenticationException();
          }
          case 403: {
            throw new AuthorizationException();
          }
        }
      }
      throw error;
    }
  }

  public async delete<ResponseSchema>(url: string, headers: { [key: string]: any } = {}): Promise<ResponseSchema> {
    try {
      const response = await axios.delete<{ data: ResponseSchema }>(`${this.baseEndpoint}${url}`, {
        headers
      });
      return response.data.data;
    } catch (error: any) {
      if (error?.response) {
        let statusCode = error.response.status || 500;
        switch (statusCode) {
          case 401: {
            throw new AuthenticationException();
          }
          case 403: {
            throw new AuthorizationException();
          }
        }
      }
      throw error;
    }
  }


  public async send() {

  }

  public async sendWithResponse() {

  }

  // private loadQueue(): QueueRecord[] {
  //   const existingLs = localStorage.getItem('http-queue');
  //   if (existingLs) {
  //     return JSON.parse(existingLs) as QueueRecord[];
  //   } else {
  //     return [];
  //   }
  // }

  // private saveQueue() {
  //   localStorage.setItem('http-queue', JSON.stringify(this.queue));
  // }

  // public addToQueue({ additionalHeaders, body, method, url }: Pick<QueueRecord, 'additionalHeaders' | 'body' | 'method' | 'url'>) {
  //   this.queue.push({
  //     additionalHeaders,
  //     body,
  //     method,
  //     url,
  //     status: 'Pending'
  //   });
  //   this.saveQueue();
  //   if (!this.queueIsProcessing) {
  //     this.processQueue().catch(() => { });
  //   }
  // }

  // public async processQueue() {
  //   if (this.networkService.status.connected === true) {
  //     console.log('Going to process the queue, we have ' + this.queue.length + ' queue records.');
  //     this.queueIsProcessing = true;
  //     for (let record of this.queue) {
  //       try {
  //         switch (record.method) {
  //           case 'POST': {
  //             await this.post(record.url, record.body || {}, { ...record.additionalHeaders });
  //             record.status = 'Successful';
  //             break;
  //           }
  //           case 'PUT': {
  //             await this.put(record.url, record.body || {}, { ...record.additionalHeaders });
  //             record.status = 'Successful';
  //             break;
  //           }
  //           case 'PATCH': {
  //             await this.patch(record.url, record.body || {}, { ...record.additionalHeaders });
  //             record.status = 'Successful';
  //             break;
  //           }
  //           case 'DELETE': {
  //             await this.delete(record.url, { ...record.additionalHeaders });
  //             record.status = 'Successful';
  //             break;
  //           }
  //         }
  //       } catch (error) {
  //         console.log('ERror with one request, remove from queue?');
  //         console.log(error);
  //       }
  //     }
  //     this.queue = this.queue.filter(record => record.status !== 'Successful');
  //     console.log('Processed queue, there are ' + this.queue.length + ' records left to process');
  //     this.saveQueue();
  //     this.queueIsProcessing = false;
  //   } else {
  //     console.log('Cant process queue right now because offlien');
  //   }
  // }
}