import * as React from 'react';
import { actionSheetController, alertController } from '@ionic/core';
import { IonAvatar, IonButton, IonButtons, IonIcon, IonRouterLink, IonText, IonToolbar } from '@ionic/react';
import { arrowUndoOutline, ellipsisVerticalOutline, heartOutline } from 'ionicons/icons';
import { createToast, DateHelpers, handleError } from '../helpers';
import { AppPhoto } from './app-photo';
import { Container } from './container';
import { PresentationalPhotosGrid } from './presentational-photos-grid';
import { ReactionsButton } from './reactions-button';
import { PostComment } from '../models/database/user-created/post-comment';
import { useAuth } from '../hooks/use-auth';

export const PostCommentCard: React.FC<{
  comment: PostComment,
  onReplyToCommentClicked: () => any,
  onEditCommentClicked: () => any,
  onDeleteCommentClicked: () => any,
  onAvatarClicked: () => any;
}> = React.memo(({ comment, onReplyToCommentClicked, onEditCommentClicked, onAvatarClicked, onDeleteCommentClicked }) => {
  const auth = useAuth();
  return <div style={{ display: 'flex', margin: '8px 5px 6px 5px' }} key={comment.id}
    id={comment.id}>
    <div style={{ width: '30px', height: '30px', marginTop: '10px' }}>
      <IonAvatar style={{ backgroundColor: '#ECECEC', borderRadius: '50%' }}>
        <img src="https://cimg3.ibsrv.net/cimg/www.s2ki.com/1600x900_85-1/343/1-Make-Improvements-408343.jpg" />
      </IonAvatar>
    </div>

    <div style={{ width: '100%', maxWidth: '90vw' }}>
      <div className="ion-text-center" style={{ color: 'gray', fontSize: '12px', marginBottom: '5px' }}>{DateHelpers.formatFriendlyDateTime(comment.created)}</div>
      <Container
        position="relative"
        margin='0 10px 10px 10px'
        padding={{ bottom: 6 }}
        roundedCorners={true}
        dropShadow={true} color='white'>
        {/* <AppUserToolbar
      userId={comment.createdBy}
      username={comment.creator.username}
      handleEllipsisClicked={() => {

      }}
    /> */}
        <IonToolbar color='white' style={{ borderRadius: '8px', paddingTop: '10px', paddingLeft: '6px' }}>

          <IonRouterLink color="primary" routerLink={`/view-profile/${comment.createdBy}`}><strong style={{ fontSize: '16px' }}>{comment.creator.username}abc</strong></IonRouterLink>
          <IonButtons slot="end">


            <IonButton fill="clear" color="dark" onClick={() => {
              onReplyToCommentClicked();
            }}>
              <IonIcon icon={arrowUndoOutline} />
            </IonButton>

            <IonButton fill="clear" color="dark" onClick={() => {

            }}>
              <IonIcon icon={heartOutline} />
            </IonButton>

            <ReactionsButton hideLabel width='34px' height='40px' authenticatedUserReaction={undefined} onReactionClicked={(reaction) => {

            }} />

            <IonButton fill="clear" color="dark" onClick={() => {
              if (comment.createdBy === auth.context?.user.id) {
                actionSheetController.create({
                  buttons: [
                    {
                      text: 'Edit Comment',
                      handler: () => {
                        onEditCommentClicked();
                      }
                    }, {
                      text: 'Delete Comment',
                      role: 'destructive',
                      handler: () => {
                        alertController.create({
                          message: 'Are you sure you want to delete this comment, and all favorites and reactions?',
                          buttons: ['No', {
                            text: 'Yes', handler: () => {
                              try {
                                onDeleteCommentClicked();
                              } catch (error) {
                                if (error instanceof Error)
                                  handleError(error);
                              }
                            }
                          }]
                        }).then(alert => alert.present());
                      }
                    },
                    {
                      text: 'Cancel',
                      role: 'cancel'
                    }
                  ]
                }).then(as => as.present()).catch(() => createToast('There was an unexpected error.'));
              } else {
                actionSheetController.create({
                  buttons: [
                    {
                      text: 'Cancel',
                      role: 'cancel'
                    }
                  ]
                }).then(as => as.present()).catch(() => createToast('There was an unexpected error.'));
              }
            }}>
              <IonIcon icon={ellipsisVerticalOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <Container padding='10px'>
          {comment.replyingToComment && <div style={{ display: 'flex', margin: '0px 5px' }}>
            <div style={{ width: '24px', height: '24px', marginRight: '10px' }}>
              <IonAvatar slot="start" style={{ width: '48px', height: '48px' }} onClick={() => onAvatarClicked()}>
                <img src="https://storage.modclub.social/images/879950a2-bfbe-4879-9aa9-9fb8881c2807/055d4d93-9bcb-4e84-a59a-6a3d034bc076-sm" />
              </IonAvatar>
            </div>
            <Container dropShadow color="light" padding={10} margin='10px 0' width={{ units: '%', value: 100 }} roundedCorners onClick={() => {

            }}>

              <IonText color="medium" style={{ fontSize: '0.8em', fontWeight: 'bold' }}>{comment.replyingToComment.creator.username}</IonText>
              <br />
              <IonText color="medium" style={{ fontSize: '0.7em', fontStyle: 'italic' }}>{comment.replyingToComment.content}</IonText>
              {comment.replyingToComment.photos.length > 0 &&
                <div style={{ display: 'flex', flexWrap: 'nowrap', overflow: 'scroll' }}>
                  {comment.replyingToComment.photos.map((photo) => <AppPhoto maxWidth={100} key={photo.id} photo={photo} />)}
                </div>
              }
            </Container></div>}

          <div>
            {/* {comment.photos && comment.photos.length > 0 && <PresentationalPhotosGrid photos={comment.photos} />} */}
          </div>

          <div style={{ whiteSpace: 'pre-wrap' }} className="max-lines-6">
            {comment.content}
          </div>

        </Container>


        {/* <IonActionSheet onDidDismiss={() => setActionSheetShowing(false)} isOpen={actionSheetShowing} buttons={getActionSheetButtons(comment)} /> */}
      </Container>
    </div>

  </div>
}, (prevProps, nextProps) => {
  if (prevProps.comment.id === nextProps.comment.id) {
    return true;
  }
  return false;
});