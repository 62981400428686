import { alertController } from '@ionic/core';
import { IonBackButton, IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonPage, IonSpinner, IonTextarea, IonTitle, IonToolbar } from '@ionic/react';
import * as React from 'react';
import './view-post-comments-page.css';
import { handleError } from '../helpers';
import { UnexpectedException } from '../models/supporting/exceptions';
import { AppPhoto } from '../components/app-photo';
import { Container } from '../components/container';
import { photosService } from '../services/photos-service';
import { imageOutline, sendOutline, close } from 'ionicons/icons';
import { PostCommentsList } from '../components/post-comments-list';
import { useParams } from 'react-router';
import { uploadsService } from '../services/uploads-service';
import { PostComment } from '../models/database/user-created/post-comment'

export const ViewPostCommentsPage: React.FC = () => {
  const { postId } = useParams<{ postId: string }>();
  const ionContentEl = React.useRef<HTMLIonContentElement>(null);
  const [comments, setComments] = React.useState<PostComment[]>([]);
  const [comment, setComment] = React.useState<Pick<PostComment, 'content' | 'photos' | 'replyingToComment'> | PostComment>({
    content: '',
    photos: [],
    replyingToComment: null
  });
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const [isUploadingPhotos, setIsUploadingPhotos] = React.useState<boolean>(false);
  const [hasMoreComments, setHasMoreComments] = React.useState<boolean>(false);
  React.useEffect(() => {
    setTimeout(() => {
      if (ionContentEl.current) {
        ionContentEl.current.scrollToBottom();
      }
    }, 50);
  }, []);

  React.useEffect(() => {
    // commentsService.findForParent(parentId).then(commentsFromService => {
    //   setComments(commentsFromService);
    //   setIsLoading(false);
    // }).catch(error => {
    //   setIsLoading(false);
    //   handleError(error);
    // })
  }, []);

  async function loadMoreComments() {
    // commentsService.findForParent(parentId, comments[0]?.created).then(commentsFromService => {
    //   setComments(current => [...commentsFromService, ...current]);
    //   setIsLoading(false);
    // }).catch(error => {
    //   setIsLoading(false);
    //   handleError(error);
    // })
  }

  return <IonPage id="view-comments-page">
    <IonHeader>
      <IonToolbar color="light">
        <IonButtons slot="start">
          <IonBackButton defaultHref="/dashboard" />
        </IonButtons>
        <IonTitle>Comments</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent ref={ionContentEl} id="post-comments-ion-content" color="bg" scrollEvents={true}>
      {/* <PostCommentsList postId={postId}
        onEditIsClickedForComment={comment => {
          setPostCommentIsEditing(comment);
        }} onReplyIsClickedForComment={replyingToComment => {
          setPostCommentIsReplyingTo(replyingToComment || undefined);
        }} /> */}
      {hasMoreComments && <IonButton
        expand='block'
        onClick={async () => {
          try {
            await loadMoreComments();
          } catch (error: any) {
            handleError(error);
          }
        }}
      >Load More</IonButton>}
      <PostCommentsList
        comments={comments}
        onDeleteClicked={comment => {

        }}
        onEditClicked={comment => {

        }}
        onReplyClicked={comment => {

        }}
      />

    </IonContent>

    <IonFooter style={{ border: 'none' }}>

      {/* <AddEditPostCommentForm
        commentIsReplyingTo={commentIsReplyingTo}
        commentIsEditing={commentIsEditing} postId={postId}
      /> */}

      <IonToolbar style={{ border: 'none', borderWidth: '0 !important' }}>
        <IonButtons slot="start">
          <IonButton disabled={isUploadingPhotos} slot="icon-only" color="primary" fill="clear" onClick={async () => {
            try {
              // const {newPhotoIds} = await uploadsService.choosePhotos();
              // await uploadsService.uploadPhotos(newPhotoIds);
              // newPhotoIds.forEach(newPhotoId => photosService.addPhoto(newPhotoId));
              // setComment({ ...comment, photoIds: [...comment.photoIds, ...newPhotoIds] });
            } catch (error: any) {
              handleError(error || new UnexpectedException('There was an error trying to select photos.'));
            }
          }}>
            <IonIcon hidden={isUploadingPhotos} icon={imageOutline} />
            <IonSpinner color='primary' name='lines-small' hidden={!isUploadingPhotos} />
          </IonButton>
          {/* <PhotosAdder buttonColor='primary' buttonType='icon-only' authenticatedUser={authenticatedUser} existingPhotos={comment?.photos || []} onPhotosChanged={photos => {

      }} /> */}
        </IonButtons>
        <Container position="relative" color="inputs" roundedCorners margin={{ top: 10 }} padding={{ top: 6 }}>
          {
            comment.replyingToComment && <Container color="medium" margin='0 5px' padding={5} roundedCorners>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <IonIcon style={{ fontSize: '24px', marginRight: '5px' }} icon={close} onClick={() => {
                  setComment({ ...comment, replyingToComment: null });
                }} />
                <strong style={{ position: 'relative', fontSize: '0.9em' }}>
                  Replying to {comment.replyingToComment.creator.username}
                </strong>
              </div>
              <div style={{ marginLeft: '30px', fontSize: '0.8em' }}>{comment.replyingToComment.content.slice(0, 40)}...</div>
              <div style={{ position: 'relative', whiteSpace: 'nowrap', overflowX: 'auto' }}>
                {comment.replyingToComment.photos.map((photo) => <div key={photo.id} style={{ display: 'inline-block', margin: '8px' }}>
                  <AppPhoto photo={photo} width={50} height={50} />
                </div>)}
              </div>
            </Container>
          }
          {comment.photos.length > 0 && <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', overflow: 'scroll', margin: '0 5px' }}>
            {comment.photos.map((photo) => <div
              key={photo.id}
              style={{ margin: `0 2px`, minWidth: '84px' }}
              onClick={() => {
                setComment({ ...comment, photos: comment.photos.filter(p => p.id !== photo.id) });
              }}>
              <div style={{ position: 'relative', maxWidth: '84px' }}>
                <IonIcon hidden={isUploadingPhotos} name="close" style={{ position: 'absolute', top: '0', right: '0', backgroundColor: 'lightgray', color: 'red' }} />
                {/* <AppPhoto size='xs' photoId={photoId} /> */}
              </div>
            </div>)}
          </div>}
          <IonTextarea
            style={{ fontSize: '0.8em' }}
            value={comment?.content}
            onIonChange={evt => {
              setComment({
                ...comment,
                content: evt.detail.value || ''
              });
            }}
            autoGrow={true}
            color="light"
            placeholder="Type your comment here" maxlength={1000}>

            {/* <div style={{ position: 'relative', whiteSpace: 'nowrap', overflowX: 'auto' }}>
      {photos.map(photo => <IonThumbnail onClick={() => alert('Clicked IonThumbail')} key={photo.id} style={{ display: 'inline-block', margin: '8px' }}>
        <img src={photo.localUri || photo.path} />
      </IonThumbnail>)}
    </div> */}
          </IonTextarea>
        </Container>

        <div style={{ textAlign: 'center', fontSize: '0.6em' }}>{comment ? comment.content.length : 0}/1000</div>
        <IonButtons slot="end">
          <IonButton
            disabled={(!comment.content && comment.photos.length === 0) || isUploadingPhotos}
            slot="icon-only"
            fill="clear"
            color="primary"
            onClick={async () => {
              try {
                if ('id' in comment) {
                  //   commentsService.update(parentId, comment.id, comment);
                } else {
                  //   commentsService.add(comment);
                }
                setComment({ content: '', photos: [], replyingToComment: null });
                setIsEditing(false);
                if (ionContentEl && ionContentEl.current) {
                  ionContentEl.current.scrollToPoint(null, 100000, 500);
                  // ionContentEl.current.scrollToBottom(300).then(() => {
                  //   ionContentEl.current!.scrollToPoint(null, 10000);
                  // });
                }
              } catch (error: any) {
                handleError(error);
              }
            }}>
            <IonIcon icon={sendOutline} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
      {isEditing && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <IonButton style={{ width: '100px' }} size='small' color='danger' onClick={() => {
          alertController.create({
            message: 'Are you sure you want to cancel your edit?', buttons: [
              'No',
              {
                text: 'Yes',
                handler: () => {
                  setComment({
                    photos: [],
                    content: '',
                    replyingToComment: null
                  });
                  setIsEditing(false);
                }
              }
            ]
          }).then(alert => alert.present()).catch(() => { });
        }}>Cancel Edit</IonButton>
      </div>}
    </IonFooter>
  </IonPage>;
}