import * as React from 'react';
import { IonApp, IonContent, IonIcon, IonItem, IonLabel, IonMenu, IonMenuToggle, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import '@ionic/pwa-elements';
// import { defineCustomElements } from '@ionic/pwa-elements/loader';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import './app.css';
import { AppHeader } from './components/app-header';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { DashboardTab } from './pages/dashboard-tab';
import { GarageTab } from './pages/garage-tab';
import { MoreTab } from './pages/more-tab';
import { NotificationsPage } from './pages/notifications-page';
import { PrivacyPolicyPage } from './pages/privacy-policy-page';
import { ProfilePage } from './pages/profile-page';
import { SearchPage } from './pages/search-page';
import { TermsOfUsePage } from './pages/terms-of-use-page';
import { UpgradeMembershipPage } from './pages/upgrade-membership-page';
import { ViewPostCommentsPage } from './pages/view-post-comments-page';
import { ViewPostPage } from './pages/view-post-page';
import { ViewUserFavoritesPage } from './pages/view-user-favorites-page';
import { ViewVehicleModPage } from './pages/view-vehicle-mod-page';
import { ViewVehiclePage } from './pages/view-vehicle-page';
import { ViewPhotoPage } from './pages/view-photo-page';
import { PostsService } from './services/posts-service';
import { AuthService } from './services/auth-service';
import { WebSocketProvider } from './providers/web-socket-provider';
import { User } from './models/database/system-created/user';
import { AuthContext } from './models/app/auth-context';
import { cashOutline, cashSharp } from 'ionicons/icons';
import { SideMenu } from './components/side-menu';
import { TabsBar } from './components/tabs-bar';

export const AppRoot: React.FC<{ authService: AuthService, postsService: PostsService, ws: WebSocketProvider }> = ({ authService, postsService, ws }) => {
  const [authContext, setAuthContext] = React.useState<Readonly<AuthContext> | null>(authService.authContext);
  // const [sideMenuIsVisible, setSideMenuIsVisible] = React.useState(false);
  const [sideMenuMargin, setSideMenuMargin] = React.useState<0 | 50 | 300>(0);
  const location = useLocation();
  // const [path, setPath] = React.useState(window.location.pathname);
  // let ourRef = React.useRef<IonReactRouter | null>(null);

  // React.useEffect(() => {
  //   console.log(ourRef.current);
  //   if (ourRef.current) {
  //     ourRef.current.registerHistoryListener((location, action) => {
  //       console.log(location.pathname);
  //       console.log(action);
  //     })
  //   }
  // }, [])
  React.useEffect(() => {
    authService.authContextChanged.on(() => {
      setAuthContext(authService.authContext);
    });
  }, []);
  React.useEffect(() => {
    if (window.innerWidth > 768) {
      // setSideMenuIsVisible(true);
      setSideMenuMargin(300);
    } else {
      // setSideMenuIsVisible(false);
      setSideMenuMargin(0);
    }
    const resizeListener: (this: Window, ev: UIEvent) => any = evt => {
      console.log(window.innerWidth);
      console.log(evt.detail);
      console.log((evt.target as Window).innerWidth);
      console.log(this);
      if (window.innerWidth > 768) {
        // setSideMenuIsVisible(true);
        setSideMenuMargin(300);
      } else {
        // setSideMenuIsVisible(false);
        setSideMenuMargin(0);
      }
    }
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);
  React.useEffect(() => {
    console.log('Effect fired because of inner width change');
  }, [window.innerWidth]);
  // React.useEffect(() => {
  //   let mql = window.matchMedia('(min-width: 769px)');
  //   if (mql.matches) {
  //     // setSideMenuIsVisible(true);
  //     setSideMenuMargin(300);
  //   } else {
  //     // setSideMenuIsVisible(false);
  //     setSideMenuMargin(0);
  //   }
  //   mql.addEventListener('change', (e) => {
  //     if (e.matches) {
  //       setSideMenuMargin(300);
  //     } else {
  //       setSideMenuMargin(0);
  //     }
  //   });
  // }, []);
  // React.useEffect(() => {
  //   let mql = window.matchMedia('(max-width: 769px)');
  //   if (mql.matches) {
  //     // setSideMenuIsVisible(true);
  //     setSideMenuMargin(300);
  //   } else {
  //     // setSideMenuIsVisible(false);
  //     setSideMenuMargin(0);
  //   }
  //   mql.addEventListener('change', (e) => {
  //     if (e.matches) {
  //       setSideMenuMargin(300);
  //     } else {
  //       setSideMenuMargin(0);
  //     }
  //   });
  // }, []);
  return <>
    <AppHeader currentUser={undefined} />
    {/* <SideMenu auth={authContext} width={sideMenuMargin} onCollapse={() => setSideMenuMargin(50)} onUncollapse={() => setSideMenuMargin(300)} /> */}
    {/* <IonSplitPane contentId="main"> */}
    <IonSplitPane contentId="main">
      <SideMenu auth={authContext} />
      <IonRouterOutlet id="main" basePath="/" style={{}}>
        <Route path="/dashboard" render={() => <DashboardTab postsService={postsService} authContext={authContext} ws={ws} />} />
        <Route path="/garage" >
          <GarageTab auth={authContext} />
        </Route>
        {/* <Route path="/for-sale" exact={true}>
                    <ForSaleTab />
                </Route> */}
        {/* <Route path="/community" component={CommunityTab} exact={true} /> */}
        {/* <Route path="/tech" component={TechTab} exact={true} /> */}
        <Route path="/more">
          <MoreTab auth={authContext} />
        </Route>

        <Route path="/upgrade-membership" component={UpgradeMembershipPage} />

        <Route path="/user-favorites" component={ViewUserFavoritesPage} />

        <Route path="/search">
          <SearchPage />
        </Route>

        <Route path="/search/:searchTermFromUrl" component={SearchPage}>
          <SearchPage />
        </Route>

        <Route path="/profile/:username">
          <ProfilePage />
        </Route>

        <Route path="/post/:postId" render={() => <ViewPostPage />} />

        <Route path="/post/:postId/comments">
          <ViewPostCommentsPage />
        </Route>

        <Route path="/post/:postId/comments/:commentId">

        </Route>

        <Route path="/photo/:photoId">
          <ViewPhotoPage />
        </Route>

        <Route path="/vehicle/:vehicleId">
          <ViewVehiclePage />
        </Route>
        <Route path="/vehicle/:vehicleId/mods/:modId">
          <ViewVehicleModPage />
        </Route>

        <Route path="/notifications">
          <NotificationsPage />
        </Route>
        <Route path="/privacy-policy" component={PrivacyPolicyPage} />
        <Route path="/terms-of-use" component={TermsOfUsePage} />
        <Redirect to="/dashboard" />
      </IonRouterOutlet>
    </IonSplitPane>

    {/* </IonSplitPane> */}
    {/* <IonModal isOpen={viewingPhoto !== undefined} onDidDismiss={() => setViewingPhoto(undefined)}>
        <IonToolbar color='light'>
          <IonButtons slot='start'>
            <IonButton onClick={() => setViewingPhoto(undefined)}>Close</IonButton>
          </IonButtons>
          <IonButtons slot='end'>
            <IonButton slot='icon-only'>
              <IonIcon icon={shareOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonContent fullscreen>
          {viewingPhoto &&
            <AppPhoto photo={viewingPhoto} size='original' />
          }
        </IonContent>
      </IonModal> */}

  </>;
}