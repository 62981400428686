import * as React from 'react';
import { IonPage, IonContent, IonToolbar, IonTitle, IonButtons, IonBackButton, IonHeader, useIonViewWillEnter } from '@ionic/react';
import { AppHeader } from '../components/app-header';

export const PrivacyPolicyPage: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="light">
          <IonButtons slot="start">
            <IonBackButton color="primary" defaultHref="/more" />
          </IonButtons>
          <IonTitle>Privacy Policy</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        If you join and create a user account, your data you provide is subject to these privacy terms:

<p>The following are only used for account verification and are never public for people viewing the app or website, whether they have an account or not:</p>
        <ul>
          <li>Your phone number</li>
          <li>Your email address</li>
        </ul>

        <p>The following are always public for people viewing the app or website, whether they have an account or not:</p>
        <ul>
          <li>Posts you create</li>
          <li>Posts you react to</li>
          <li>Posts you favorite</li>
          <li>Posts you comment on</li>
          <li>Post comments you create</li>
          <li>Post comments you react to</li>
          <li>Post comments you favorite</li>
          <li>Vehicles you add</li>
          <li>Vehicles you favorite</li>
          <li>Vehicle mods you create</li>
          <li>Vehicle mods you favorite</li>
        </ul>

        <p>The following are always public for members who have an account and are logged in:</p>
        <ul>
          <li>Items for sale you create</li>
          <li>Items for sale you react to</li>
          <li>Items for sale you favorite</li>
          <li>Items for sale you comment on</li>
          <li>Item for sale comments you create</li>
          <li>Item for sale comments you react to</li>
          <li>Item for sale comments you favorite</li>
        </ul>
      </IonContent>
    </IonPage>
  );
};