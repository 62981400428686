import * as React from 'react';
import { IonAvatar, IonButton, IonRouterLink, IonText } from '@ionic/react';
import { User } from '../models/database/system-created/user';
import './user-avatar.css';

interface UserAvatarProps {
  user: Pick<User, 'id' | 'username'>;
  showDefaultBackground?: boolean;
  slot?: string;
  maxSize?: number;
}

export const UserAvatar: React.FC<UserAvatarProps> = ({ user, maxSize, slot }) => {
  return <IonButton mode="ios" size="large" fill="clear" color="primary" routerLink={`/profile/${user.username}`}>
      <IonAvatar slot="start" style={{ marginLeft: '8px', marginRight: '10px', backgroundColor: '#ECECEC', borderRadius: '50%', maxHeight: '40px', maxWidth: '40px' }}>
        <div style={{ height: '100%', width: '100%' }}></div>
    </IonAvatar>
    <IonText color="primary"><strong style={{fontSize: '18px'}}>{user.username}</strong></IonText>
  </IonButton>;

};
