import * as React from 'react';
import { IonPage, IonContent, IonToolbar, IonTitle, IonFab, IonFabButton, IonIcon, NavContext, IonHeader, useIonViewWillEnter, IonList, IonItem, IonButtons, IonMenuButton } from '@ionic/react';
import { carSportOutline } from 'ionicons/icons';
import { VehiclesList } from '../components/vehicles-list';
import { TabsBar } from '../components/tabs-bar';
import { useGarageTabState } from '../hooks/use-garage-tab-state';
import { AddEditVehicleModal } from '../modals/add-edit-vehicle-modal';
import { createToast } from '../helpers';
import { useAuth } from '../hooks/use-auth';
import { SideMenu } from '../components/side-menu';
import { AuthContext } from '../models/app/auth-context';
import { useLocation } from 'react-router-dom';

export const GarageTab: React.FC<{ auth: Readonly<AuthContext> | null }> = ({ auth }) => {
  // const location = useLocation();

  const navContext = React.useContext(NavContext);
  // const auth = useAuth();
  // const state = useGarageTabState();
  useIonViewWillEnter(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth > 400 && window.innerWidth < 768) {
        // TODO hide segment and/or other things
        console.log('Lets hide ')
      }
    });
  });

  // if (location.pathname !== '/garage') {
  //   return null;
  // }
  console.log('RENDERING GARAGE TAB');
  // let presentingEl: HTMLIonButtonElement | undefined;
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="light">
        <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Garage</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {/* <SideMenu auth={auth} /> */}
        {/* <IonToolbar>
          <IonSegment value={visibleSection} onIonChange={evt => {
            if (evt.detail.value) {
              setVisibleSection(evt.detail.value as 'mine' | 'others');
            }
          }}>
            <IonSegmentButton value='mine'>Mine</IonSegmentButton>
            <IonSegmentButton value='others'>Others</IonSegmentButton>
          </IonSegment>
        </IonToolbar> */}
        {/* <span hidden={visibleSection !== 'mine'}> */}

        {!auth && <div className="ion-padding">Log in to add vehicles.</div>}

        {/* {auth.context && <VehiclesList view='card' vehicles={state.userVehicles} />} */}

        {/* </span> */}

        {/* <span hidden={visibleSection !== 'others'}>
          <IonToolbar color="light">
            <IonButtons slot="end">
              <IonButton color='primary' fill='clear' onClick={() => setIsFilteringOthers(true)} ref={el => {
                if (el) {
                  presentingEl = el;
                  console.log(presentingEl);
                }
              }}><IonIcon slot="start" icon={filterOutline} /> Filter</IonButton>
            </IonButtons>
          </IonToolbar>
          <IonModal isOpen={isFilteringOthers} swipeToClose={true} onDidDismiss={() => setIsFilteringOthers(false)}>
            <IonList>
              <IonItem detail={true}>
                <IonLabel>Make</IonLabel>
              </IonItem>
              <IonItem detail={true}>
                <IonLabel>Model</IonLabel>
              </IonItem>
            </IonList>
          </IonModal>

          <VehiclesList view='card' isCurrentUsersList={false} vehicles={[]} onLoadMore={() => { }} />
        </span> */}

        {/* <div style={{ height: '50px', paddingBottom: '50px !important' }} ></div> */}

        <IonFab vertical="bottom" horizontal="end" slot="fixed" style={{marginBottom: '70px'}}>
          <IonFabButton color="secondary" onClick={() => {
            if (!auth) {
              navContext.navigate('/log-in');
            } else {
              // state.openAddVehicleModal();
            }
          }}>
            <IonIcon icon={carSportOutline}></IonIcon>+
          </IonFabButton>
        </IonFab>

        <AddEditVehicleModal isOpen={false} onSaved={() => {
          // state.closeAddVehicleModal();
          createToast('Your vehicle was created.', undefined, undefined, undefined).catch(() => { });
        }} onCanceled={() => {
          // state.closeAddVehicleModal();
        }} />
      <TabsBar selectedTab='garage' />
      </IonContent>

    </IonPage>
  );
};
