import { Network, ConnectionStatus, ConnectionType } from '@capacitor/network';

export class NetworkService {

    private subscribers: ((status?: Readonly<ConnectionStatus>) => any)[] = [];

    public status: Readonly<ConnectionStatus> | undefined;

    public get hasConnection(): boolean {
        if (!this.status) {
            return false;
        }
        return this.status.connected;
    }

    public async initialize() {
        this.status = await Network.getStatus();
        console.log(this.status);
        this.publish();
        Network.addListener('networkStatusChange', status => {
            this.status = status;
            this.publish();
        });
    }

    public subscribe(subscription: (status?: Readonly<ConnectionStatus>) => any, getCurrentValue?: boolean) {
        if (getCurrentValue === true) {
            subscription(this.status);
        }
        this.subscribers.push(subscription);
    }

    private publish() {
        this.subscribers.forEach(subscription => subscription(this.status));
    }

}

export const networkService = new NetworkService();