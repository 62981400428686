import * as React from 'react';
import { IonPage, IonContent, IonToolbar, IonTitle, IonText, IonButton, IonIcon, IonHeader, IonButtons, IonBackButton, useIonViewWillEnter } from '@ionic/react';
import { AppHeader } from '../components/app-header';
import { MembershipComparison } from '../components/membership-comparison';
import { trophyOutline } from 'ionicons/icons';
import { createToast, wait } from '../helpers';
import { Redirect } from 'react-router';
import { useAuth } from '../hooks/use-auth';

export const UpgradeMembershipPage: React.FC = () => {
  const auth = useAuth();
  async function handleUpgradeToProClicked() {
    try {
      //  await upgradeToPro();

      createToast('Thank you for upgrading to Pro!');
      await wait(500);
      // await routeTo('/more', 'root');

    } catch (error) {
      console.error(error);
      // TODO solution for the user?
      createToast('We apologize, there was an error upgrading your membership.');
    }
  }

  if (!auth.context) {
    return <Redirect to="/dashboard" />
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="light">
          <IonButtons slot="start">
            <IonBackButton color="primary" defaultHref="/more" />
          </IonButtons>
          <IonTitle>Upgrade Membership</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonText color="medium">
          <div className="ion-padding-start ion-padding-end ion-padding-top ion-text-center" style={{ fontSize: '85%' }}>
            Subscribing as a member helps to keep the app ad-free from slow and invasive 3rd party ads, as your contributions will to help fund server and maintenance costs.
                        </div></IonText>
        <MembershipComparison />
        <div className="ion-padding-top ion-padding-start ion-padding-end">
          <IonButton fill="solid" expand="block" onClick={() => handleUpgradeToProClicked()}>
            <IonIcon slot="start" icon={trophyOutline}></IonIcon>
                Upgrade To Pro
            </IonButton>
          {/* <IonButton fill="solid" expand="block" >
                    <IonIcon slot="start" name="cart-outline"></IonIcon>
            Upgrade To Vendor
        </IonButton> */}

          <IonText>
            <div className="ion-padding-start ion-padding-end ion-padding-bottom ion-text-center">
              <strong>Cancel anytime.</strong>
            </div>
          </IonText></div>
      </IonContent>
    </IonPage>
  );
};