import { NetworkService, networkService } from './network-service';
import { HttpProvider } from '../providers/http-provider';
import { Mutation, mutationFailedEvent } from '../constants';
import { ClientStorageService, clientStorageService } from './client-storage-service';

export class MutationsSyncService {
    private _http: HttpProvider = new HttpProvider('');
    private _mutations: (Mutation)[] = [];

    public constructor(
        private networkService: NetworkService,
        private clientStorageService: ClientStorageService
    ) {

    }

    public async initialize() {
        await this.fetchLocally();
        this.networkService.subscribe(status => {
            if (status?.connected) {
                this.sync().catch(() => {});
            }
        });
        mutationFailedEvent.on(failedMutation => {
            console.log(failedMutation);
        });
    }

    public addMutation(mutation: Mutation) {
        console.log('addMutation invoked');
        // If got here, either have connection with offline isEnabled false or no connection but offline isEnabled true
        this._mutations.push(mutation);
        this.persistLocally().finally(() => {
            if (this.networkService.hasConnection) {
                // Sync immediately if have connection
                this.sync().catch(() => {
                    console.log('Error syncing.');
                });
            }
        });
    }

    private async persistLocally() {
        console.log('Going to persist ' + this._mutations.length + ' mutations.');
        await this.clientStorageService.storeItem({ storeName: 'app', key: 'mutations-to-sync', value: this._mutations || [] });
    }

    private async fetchLocally() {
        let mutations = await this.clientStorageService.fetchItem<Mutation[] | null>({ storeName: 'app', key: 'mutations-to-sync' });
        if (mutations) {
            this._mutations = [...mutations];
        }
        mutations = null;
    }

    private async sync() {
        let copy: Mutation[] | null = null;
        try {
            copy = [...this._mutations];
            this._mutations = [];
            console.log('Going to sync these mutations');
            console.log(copy);
            for (let mutation of copy) {
                switch (mutation.method) {
                    case 'DELETE': {
                        try {
                            await this._http.delete(mutation.endpoint, mutation.headers)
                        } catch {
                            try {
                                await this._http.delete(mutation.endpoint, mutation.headers)
                            } catch {
                                mutationFailedEvent.emit(mutation);
                            }
                        }
                        break;
                    }
                    case 'PATCH': {
                        try {
                            await this._http.patch(mutation.endpoint, mutation.body, mutation.headers)
                        } catch {
                            try {
                                await this._http.patch(mutation.endpoint, mutation.body, mutation.headers)
                            } catch {
                                mutationFailedEvent.emit(mutation);
                            }
                        }
                        break;
                    }
                    case 'POST': {
                        try {
                            await this._http.post(mutation.endpoint, mutation.body, mutation.headers)
                        } catch {
                            try {
                                await this._http.post(mutation.endpoint, mutation.body, mutation.headers)
                            } catch {
                                mutationFailedEvent.emit(mutation);
                            }
                        }
                        break;
                    }
                    case 'PUT': {
                        try {
                            await this._http.put(mutation.endpoint, mutation.body, mutation.headers)
                        } catch {
                            try {
                                await this._http.put(mutation.endpoint, mutation.body, mutation.headers)
                            } catch {
                                mutationFailedEvent.emit(mutation);
                            }
                        }
                        break;
                    }

                }
            }
            await this.persistLocally();
        } catch {
            console.log('Some error overall in sync')
        }
        finally {
            copy = null;
        }
    }
}

export const mutationsSyncService = new MutationsSyncService(networkService, clientStorageService);