import * as React from 'react';
import { IonToolbar, IonTitle, IonPage, IonContent, IonHeader, useIonViewWillEnter, useIonViewDidEnter, useIonViewWillLeave, useIonViewDidLeave, IonButtons, IonMenuButton, IonBadge, IonButton } from '@ionic/react';
import { handleError } from '../helpers';
import { TabsBar } from '../components/tabs-bar';
import { AddPostFab } from '../components/add-post-fab';
import { Loading } from '../components/loading';
import { PostsList } from '../components/posts-list';
import {AuthenticateUserForm} from '../components/authenticate-user-form';
import { Post } from '../models/database/user-created/post';
import { PostsService } from '../services/posts-service';
import { WebSocketProvider } from '../providers/web-socket-provider';
import { ClientToServerWebSocketEvents, ServerToClientWebSocketEvents } from '../constants';
import { AuthContext } from '../models/app/auth-context';
import { useLocation } from 'react-router-dom';

export const DashboardTab: React.FC<{ postsService: PostsService, authContext: Readonly<AuthContext> | null, ws: WebSocketProvider }> = ({ postsService, authContext, ws }) => {

  const [posts, setPosts] = React.useState<readonly Post[]>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    console.log('setting posts from the service');
    setPosts(postsService.posts);
    postsService.postsChanged.on(() => {
      console.log('Posts changed so hope didnt rerender til now when i set posts');
      console.log(postsService.posts);
      setPosts([...postsService.posts]);
    });

    return () => {

    }
  }, []);

  React.useEffect(() => {
    console.log('current user changed');
    if (authContext) {
      ws.send(ClientToServerWebSocketEvents.VerifyUsernameAvailable, { username: 'hello' });
      ws.on<Post>(ServerToClientWebSocketEvents.Hello, () => {
        console.log('Got a hello event from server');
      });
    }

    return () => {
      console.log('current user effect cleanup');
    }
  }, [authContext]);

  React.useEffect(() => {
    console.log('DASHBOARD TAB USE EFFECT watch Resize');
    window.addEventListener('resize', () => {
      if (window.innerWidth > 400 && window.innerWidth < 768) {
        // TODO hide segment and/or other things
        console.log('Lets hide ');
      }
    });
  }, []);

  useIonViewDidEnter(() => {
    console.log('DASHBOARD TAB USEIONVIEWDIDENTER');
  });

  // el.classList.add('animate__animated', 'animate__slideOutRight', 'animate__fastest');

  useIonViewWillEnter(() => {
    console.log('DASHBOARD TAB USEIONVIEWWILLENTER');
  });

  useIonViewWillLeave(() => {
    console.log('DASHBOARD TAB USEIONVIEWWILLLEAVE');
  });

  useIonViewDidLeave(() => {
    console.log('DASHBOARD TAB USEIONVIEWDIDLEAVE');
  });

  // const location = useLocation();
  // if (location.pathname !== '/dashboard') {
  //     return null;
  // }
  console.log('RENDERING DASHBOARD TAB');

  async function loadMorePosts() {
    try {
      setIsLoading(true);
      // let postsFromServer = await postsService.getPosts({ createdAfter: posts[posts.length - 1]?.created });
      // setPosts(current => [...postsFromServer, ...current]);
      // postsFromServer = [];
    } catch (error) {
      if (error instanceof Error)
        handleError(error);
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="light">
        <IonButtons slot="start">
          {/* <IonBadge mode='ios' color='primary' style={{display: 'block', position: 'fixed', top: '12px', right: '12px', zIndex: '1000000', minHeight: '10px', padding: '0'}} /> */}
            <IonMenuButton  />
          </IonButtons>
          <IonTitle>Dashboard {authContext?.user.username} {postsService.posts.length}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>

        {/* <div hidden={state.newPosts.length === 0} style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} slot='fixed'>
          <IonButton onClick={() => {
            state.mergeAndClearNewPosts();
            if (ionContentEl.current) {
              ionContentEl.current.scrollToTop(400).catch(() => { });
            }
          }} size='small' className='ion-padding'>See New Posts</IonButton>
        </div> */}
        {/* {postsService.posts.map(p => <div key={p.id}>{p.content}</div>)} */}
        {posts && <PostsList posts={posts} ws={ws} />}

        {isLoading && <Loading />}
        <IonButton href='/garage'>Garage</IonButton>
        {/* <div ref={el => postsPlaceholderEl.current = el}></div> */}
        <AddPostFab />
      <TabsBar selectedTab='dashboard' />
      </IonContent>


    </IonPage>
  );

};