import * as React from 'react';
import { IonButton, IonButtons, IonContent, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonList, IonModal, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { Container } from '../components/container';
import { logoApple } from 'ionicons/icons';

export const AuthModal: React.FC<{ isOpen: boolean, onAuthenticated: () => any, onCanceled: () => any }> = ({ isOpen, onAuthenticated, onCanceled }) => {
    const [enterAuthCodeIsShowing, setEnterAuthCodeIsShowing] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [authCode, setAuthCode] = React.useState<number>();

    return <IonModal isOpen={isOpen} backdropDismiss={false}>
        <IonToolbar color="light">
            <IonButtons slot="start">
                <IonButton onClick={() => {
                    setEmail('');
                    setAuthCode(undefined);
                    setEnterAuthCodeIsShowing(false);
                    onCanceled();
                }} color='primary' fill='clear'>Cancel</IonButton>
            </IonButtons>
            {/* <IonButtons slot="end">
          <IonButton color="primary">
            Log In
          </IonButton>
        </IonButtons> */}
        </IonToolbar>
        <IonContent color='white'>
            <IonList className='log-in-bo authenticate-background ion-padding' lines='none'>
                {!enterAuthCodeIsShowing && <>
                    <IonItem>
                        <IonText style={{ fontSize: '0.8em' }}>
                            Enter an email, and you will be sent a unique one-time auth code to verify your email.
                        </IonText>
                    </IonItem>
                    <IonItem lines='inset' style={{ marginBottom: '12px' }}>
                        <IonLabel slot='start'>Email</IonLabel>
                        <IonInput slot='end' type='email' onIonChange={evt => setEmail(evt.detail.value || '')} />
                    </IonItem>
                    <br />
                    <IonButton
                        // disabled={!isValidEmail(email)}
                        style={{ margin: 0 }}
                        fill="solid"
                        expand="block"
                        onClick={() => setEnterAuthCodeIsShowing(true)}
                    >Get Auth Code</IonButton>
                    <IonText color='medium'>
                        <div className='ion-text-center ion-padding'> or you may </div>
                    </IonText>
                    <IonButton expand='block' color='dark' onClick={async () => {
                        console.log((window as any).AppleID);
                        try {
                            const data = await (window as any).AppleID.auth.signIn();
                            console.log(data);
                        } catch (error) {
                            console.error(error);
                        }
                    }}>
                        <IonIcon slot='start' icon={logoApple} />
                        Sign In With Apple
                    </IonButton>
                    <div style={{ paddingBottom: '2px' }}></div>
                    <IonButton fill='outline' expand='block' color='medium' onClick={() => {
                        console.log((window as any).AppleID);
                    }}>
                        <IonImg style={{ width: '20px', height: '20px', marginRight: '7px' }} src="/assets/img/google.png" />
                        Sign In With Google
                    </IonButton>

                </>}

                {enterAuthCodeIsShowing && <>

                    <IonItem>
                        <IonText style={{ fontSize: '0.8em' }}>
                            You should have received an email to {email} with a one-time auth code.  Enter it below.
                        </IonText>
                    </IonItem>

                    <IonItem lines='inset'>
                        <IonLabel slot='start'>Auth Code</IonLabel>
                        <IonInput slot='end' type='number' />
                    </IonItem>
                    <br />
                    <IonButton
                        style={{ margin: 0 }}
                        fill="solid"
                        expand="block"
                        onClick={() => {
                            console.log('Verify')
                        }}
                    >Verify Auth Code</IonButton>
                </>}

            </IonList>
        </IonContent>
    </IonModal>
}